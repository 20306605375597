import request from '.'
import { queryStringify } from '../utils'

// 管理员登录 12300000000
export function adminLogin(mobile: string) {
  return request.post<R<UserVO>>('/api-user/user/login', {
    mobile,
  })
}

// 管理员退出登录
export function adminLogout() {
  return request.get<R<boolean>>('/api-user/user/logout')
}

export function getCurrentRoles() {
  return request.get('/api-user/user/userRoles')
}

// 获取详情
export function getScriptBaseInfo(scriptId: number) {
  return request.get<R<ICommon.ScriptBaseVO>>(`/api-user/script/get?id=${scriptId}`)
}

// 获取详情
export function getScriptInfo(scriptId: number) {
  return request.get<R<ICommon.ScriptInfoVO>>(`/api-user/script/getInfo?id=${scriptId}`)
}

// 获取详情
export function getScriptVoteInfo(voteId: number) {
  return request.get<R<ICommon.ScriptVoteVO>>(`/api-user/script/vote/get?voteId=${voteId}`)
}

// 获取设置列表
export function getSettingList() {
  return request.get<R<IADMIN.SettingVO[]>>('/api-user/setting/list')
}

// 保存设置
export function saveSetting(data: IADMIN.SettingParams) {
  return request.post<R<string>>('/api-user/setting/save', data)
}

// 获取成员列表
export function getUserListByType(type: 0 | 1 | 2) {
  return request.get<R<UserVO[]>>(`/api-user/user/list?type=${type}`)
}

// 创建用户
export function createUser(data: IADMIN.UserParams) {
  return request.post<R<number>>('/api-user/user/create', data)
}

// 删除用户
export function deleteUser(id: number) {
  return request.post('/api-user/user/delete', { id })
}

// 获取角色下用户列表
export function getUserRoleList(classify: string) {
  return request.get<R<IADMIN.UserRoleVO[]>>(`/api-user/userRole/list?classify=${classify}`)
}

// 新增角色下用户
export function addRoleUser(data: IADMIN.UserRoleParams) {
  return request.post('/api-user/userRole/add', data)
}

// 删除角色下用户
export function deleteRoleUser(classify: string, roleCode: string, userId: number) {
  return request.post('/api-user/userRole/delete', {
    classify,
    roleCode,
    userId,
  })
}

// 待审列表
export function getVoteTaskPage(data: IADMIN.ScriptVotePageSearchParams) {
  const url = `/api-user/script/vote/task?${queryStringify(data)}`
  return request.get<R<PageData<ICommon.ScriptVoteVO>>>(url)
}

// 已审列表
export function getVoteAuditedPage(data: IADMIN.ScriptVotePageSearchParams) {
  const url = `/api-user/script/vote/audited?${queryStringify(data)}`
  return request.get<R<PageData<ICommon.ScriptVoteVO>>>(url)
}

// 获取剧本类型列表
export function getScriptTypeList() {
  return request.get<R<ICommon.ScriptType[]>>('/api-user/script/type/list')
}

// 据本统计
export function getScriptReport(
  params: IADMIN.ScriptScorePageSearchParams = {
    pageNo: 1,
    pageSize: 10,
  }
) {
  return request.get<R<PageData<ICommon.ScriptInfoVO>>>(
    `/api-user/script/scores?${queryStringify(params)}`
  )
}

/**
 * 将剧本推送给下一审核环节
 * @param scriptIds
 * @param voteUserIds
 */
export function sendScriptToNextRound(scriptIds: number[], voteUserIds: number[] = []) {
  return request.post('/api-user/script/assign', {
    scriptIds,
    voteUserIds,
  })
}

// 初审直接提交
export function doVoteScript(data: ICommon.ScriptVoteAuditParams) {
  return request.post<R<string>>('/api-user/script/vote/audit', data)
}

// 初审暂存
export function saveVoteScript(data: ICommon.ScriptVoteAuditParams) {
  return request.post<R<number>>('/api-user/script/vote/saveItems', data)
}

// 初审单项暂存
// export function saveVoteScriptItem(data: ICommon.ScriptFirstAuditItemParams) {
//   return request.post<R<number>>(
//     '/api-user/script/vote/saveItem',
//     data
//   )
// }

// 初审单项分数暂存
export function saveVoteScriptItemScore(data: ICommon.ScriptFirstAuditItemParams) {
  return request.post<R<number>>('/api-user/script/vote/saveItemScore', data)
}

// 初审单项评语暂存
export function saveVoteScriptItemReason(data: ICommon.ScriptFirstAuditItemParams) {
  return request.post<R<number>>('/api-user/script/vote/saveItemReason', data)
}

// 保存初审是否推荐
export function saveVoteScriptResult(data: { voteId: number; result: number }) {
  return request.post<R<string>>('/api-user/script/vote/saveResult', data)
}

// 预审提交
export function doPrevVoteScript(data: ICommon.ScriptAuditParams) {
  return request.post<R<string>>('/api-user/script/vote/preAudit', data)
}

// 终审直接提交
export function doRoundBVoteScript(data: ICommon.ScriptFinalAuditParams) {
  return request.post<R<string>>('/api-user/script/vote/finalAudit', data)
}

// 终审暂存
export function saveRoundBVoteScript(data: ICommon.ScriptFinalAuditParams) {
  return request.post<R<string>>('/api-user/script/vote/finalSave', data)
}

export function getScriptVoteResult(scriptId: number) {
  return request.get<R<IADMIN.ScriptVoteBaseVO[]>>(
    `/api-user/script/vote/list?scriptId=${scriptId}`
  )
}

export function getMyMessage({
  pageNo = 1,
  pageSize = 10,
  unread,
}: {
  pageNo?: number
  pageSize?: number
  unread?: boolean
} = {}) {
  return request.get<R<PageData<IADMIN.Message>>>(
    `/api-user/message/my?pageNo=${pageNo}&pageSize=${pageSize}&readFlag=${unread ? 0 : ''}`
  )
}

export function deleteMessage(ids: number[]) {
  return request.post<R<string>>('/api-user/message/del', {
    ids,
  })
}

export function markMessageRead(ids: number[]) {
  return request.post<R<string>>('/api-user/message/read', {
    ids,
  })
}

export function markAllRead() {
  return request.post<R<string>>('/api-user/message/readAll')
}

// 获取未读消息数量
export function getUnReadCount() {
  return request.get<R<number>>('/api-user/message/unReadCount')
}

// 获取作者列表
export function getAuthorPage(data: IADMIN.AuthorPageSearchParams = {}) {
  return request.get<R<PageData<IADMIN.AuthorInfoVO>>>(
    `/api-user/author/page?${queryStringify(data)}`
  )
}

// 获取作者详情
export function getAuthorDetail(authorId: string) {
  return request.get<R<AuthorDetailVO>>(`/api-user/author/get?authorId=${authorId}`)
}

// 获取作者作品列表
export function getScriptListByAuthorId(authorId: string) {
  return request.get<R<ICommon.ScriptInfoVO[]>>(`/api-user/author/scripts?authorId=${authorId}`)
}

export function saveAgreement(content: string, type: number) {
  return request.post<R<string>>('/api-user/agreement/save', {
    content,
    type,
  })
}

export function getAgreement(type: number) {
  return request.get<R<AgreementVO>>(`/api-user/agreement/get?type=${type}`)
}

// 保存打分维度
export function saveVoteItem(data: IADMIN.VoteItemParams) {
  return request.post<R<string>>('/api-user/voteItem/save', data)
}

// 获取打分维度列表
export function getVoteItemList(classify: string) {
  return request.get<R<IADMIN.VoteItemDO[]>>(`/api-user/voteItem/list?classify=${classify}`)
}

// 删除打分维度
export function deleteVoteItem(id: number) {
  return request.post<R<string>>('/api-user/voteItem/delete', { id })
}

// 打分维度排序
export function sortVoteItem(ids: number[]) {
  return request.post<R<string>>('/api-user/voteItem/sort', { ids })
}

export function getScriptVoteItems(voteId: number) {
  return request.get<R<ICommon.ScriptVoteItemVO[]>>(`/api-user/script/vote/items?voteId=${voteId}`)
}

export function getScriptVoteBaseInfo(voteId: number) {
  return request.get<R<IADMIN.ScriptVoteBaseVO>>(`/api-user/script/vote/getBase?voteId=${voteId}`)
}

// 管理员审核
export function doRoundBAudit(data: ICommon.ScriptAdminAuditParams) {
  return request.post<R<string>>('/api-user/script/adminAudit', data)
}

// 初审未通过重推
export function doScriptRestart(data: ICommon.ScriptVoteRestartParams) {
  return request.post<R<string>>('/api-user/script/restart', data)
}

// 获取剧本某一轮评审人投票维度得分信息
export function getRadarUser(scriptId: number, voteId: number) {
  return request.get<R<ICommon.VoteItemScoreVO[]>>(
    `/api-user/script/radar/user?id=${scriptId}&voteId=${voteId}`
  )
}

// 获取剧本某一轮投票维度平均得分信息
export function getRadarAvg(scriptId: number, round: string) {
  return request.get<R<ICommon.VoteItemScoreVO[]>>(
    `/api-user/script/radar/avg?id=${scriptId}&round=${round}`
  )
}

// 获取投票审核人员
export function getScriptVoteUsers(scriptId: number) {
  // 人员不存在，id也会给前端
  return request.get<R<number[]>>(`/api-user/script/vote/users?scriptId=${scriptId}`)
}

// 终止评审
export function terminateScript(scriptId: number) {
  return request.post<R<string>>('/api-user/script/terminate', {
    scriptIds: [scriptId],
  })
}

export function passScript(scriptId: number) {
  return request.post<R<string>>('/api-user/script/pass', { id: scriptId })
}

export function getScriptVotes(data: ICommon.ScriptUserVoteSearch = {}) {
  return request.get<R<PageData<ICommon.ScriptUserVoteVO>>>(
    `/api-user/script/votes?${queryStringify(data)}`
  )
}

export function saveScriptTopic(data: { name: string; sortNo: number }) {
  return request.post<R<number>>('/api-user/script/topic/add', data)
}

export function getScriptTopicList() {
  return request.get<R<ICommon.Topic[]>>('/api-user/script/topic/list')
}

export function deleteScriptTopic(id: number) {
  return request.post<R<string>>('/api-user/script/topic/delete', {
    id,
  })
}

export function getScriptPage(data: Partial<ICommon.ScriptPageSearch> & PageQueryParams) {
  return request.get<R<PageData<ICommon.ScriptBaseVO>>>(
    `/api-user/script/page?${queryStringify(data)}`
  )
}

export function getScriptRecommendPage(
  params: IADMIN.ScriptScorePageSearchParams = { pageNo: 1, pageSize: 10 }
) {
  return request.get<R<PageData<ICommon.ScriptInfoVO>>>(
    `/api-user/script/recommends?${queryStringify(params)}`
  )
}

export function getUserVoteList(data: ICommon.UserVoteParams) {
  return request.get<R<ICommon.UserVoteVO[]>>(
    `/api-user/script/vote/userVotes?${queryStringify(data)}`
  )
}

export function getUserVoteExcelUrl(data: ICommon.UserVoteParams) {
  return `/api-user/script/vote/userVotes/export?${queryStringify(data)}`
}

export function userSign(data: {
  userId: number
  idType: string
  idNum: string
  realName: string
}) {
  return request.post<R<string>>('/api-user/user/sign', data)
}

export function getUserSignStatus(userId: number) {
  return request.get<R<boolean>>(`/api-user/user/sign/status?userId=${userId}`)
}

export function getUserSignUrl(userId: number) {
  return request.get<R<string>>(`/api-user/user/sign/url?userId=${userId}`)
}

export function getUserSignDoc(userId: number) {
  return request.get<R<string>>(`/api-user/user/sign/doc?userId=${userId}`)
}
