// lib
import React, { PropsWithChildren } from 'react'

// components
import Header from './Header'
import Container from '../Container'
import { useRegisterAgreementConfirm } from '../../hooks/useAgreement'
import AgreementDialog from '../../businessMisc/AgreementDialog'
import { AgreementType, HEADER_THEME_TYPE } from '../../constants'
import useCurrentAuthor from '../../hooks/useCurrentAuthor'
import { CustomerEnvContext, useEnvContext } from '../../context'
import AuthorMenu from './AuthorMenu'
import { useQuery } from 'react-query'
import { parseRequest } from '../../api'
import { getSettingSubmitSwitch } from '../../api/author'
import Loading from '../../components/Loading'

// styles
// import styles from './CustomerLayout.module.less'

type Props = PropsWithChildren<{}>

function RegisterAgreementConfirm() {
  const { needConfirm, isSubmitting, agree } = useRegisterAgreementConfirm()

  if (!needConfirm) {
    return null
  }

  return <AgreementDialog type={AgreementType.Register} onAgree={agree} loading={isSubmitting} />
}

export default function CustomerLayout({ children }: Props) {
  const [currentUser, updateCurrentUser] = useCurrentAuthor()
  const [headerTheme, setHeaderTheme] = React.useState<ValueOf<typeof HEADER_THEME_TYPE>>('default')
  const { isMobile } = useEnvContext()

  const { data: submitSwitch, isFetched } = useQuery(['setting-submit-switch'], () =>
    parseRequest(getSettingSubmitSwitch())
  )

  if (!isFetched) {
    return (
      <div className="flex items-center justify-center">
        <Loading.Ellipsis />
      </div>
    )
  }

  return (
    <CustomerEnvContext.Provider
      value={{
        currentUser,
        updateCurrentUser,
        submitSwitch,
        headerTheme,
        setHeaderTheme,
      }}
    >
      {isMobile ? null : <Header />}
      {isMobile ? children : <Container>{children}</Container>}
      {isMobile ? <AuthorMenu /> : null}
      <RegisterAgreementConfirm />
    </CustomerEnvContext.Provider>
  )
}
