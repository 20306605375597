import AsyncComponent from './AsyncComponent'

const routes = [
  {
    path: '/',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: "home" */ '../$$home/Home')),
  },
  {
    path: '/login',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: "login" */ '../$$home/login')),
  },
  {
    path: '/agreement',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "agreement" */ '../$$home/agreement')
    ),
  },
]

export default routes
