import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconSetting(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
        <path d="M54.943 5a8.372 8.372 0 0 1 8.372 8.372v9.394a30.31 30.31 0 0 1 3.194 1.825l8.12-4.687a8.372 8.372 0 0 1 11.436 3.064l5.233 9.064a8.372 8.372 0 0 1-3.065 11.436l-8.035 4.64a30.815 30.815 0 0 1 0 3.786l8.035 4.638a8.372 8.372 0 0 1 3.065 11.436l-5.233 9.064a8.372 8.372 0 0 1-11.436 3.064l-8.118-4.688a30.31 30.31 0 0 1-3.197 1.826l.001 9.394A8.372 8.372 0 0 1 54.943 95H44.478a8.372 8.372 0 0 1-8.372-8.372l-.002-9.592a30.325 30.325 0 0 1-2.985-1.75l-8.327 4.81a8.372 8.372 0 0 1-11.436-3.064l-5.233-9.064a8.372 8.372 0 0 1 3.064-11.436l8.416-4.86a30.837 30.837 0 0 1 0-3.343l-8.416-4.861a8.372 8.372 0 0 1-3.064-11.436l5.233-9.064a8.372 8.372 0 0 1 11.436-3.064l8.329 4.808a30.324 30.324 0 0 1 2.983-1.748l.002-9.592A8.372 8.372 0 0 1 44.478 5h10.465zm0 6.28H44.478a2.093 2.093 0 0 0-2.079 1.849l-.014.244-.002 13.433-3.42 1.748a24.02 24.02 0 0 0-1.594.894l-.77.492-3.244 2.157-11.702-6.755c-.93-.537-2.1-.28-2.725.562l-.135.204-5.232 9.063c-.537.93-.28 2.1.563 2.725l.204.135 11.754 6.79-.209 3.849c-.024.441-.036.885-.036 1.33l.01.667.026.665.21 3.85-11.756 6.788a2.093 2.093 0 0 0-.876 2.64l.11.219 5.232 9.063a2.093 2.093 0 0 0 2.64.876l.219-.11 11.7-6.758 3.245 2.159c.508.338 1.028.656 1.56.954l.807.433 3.419 1.748.002 13.434c0 1.073.808 1.958 1.849 2.079l.244.014h10.465a2.093 2.093 0 0 0 2.079-1.849l.014-.244-.001-13.298 3.502-1.727a24.003 24.003 0 0 0 1.71-.931l.824-.517 3.234-2.117 11.463 6.62c.93.537 2.1.28 2.725-.562l.134-.204 5.233-9.063c.537-.93.28-2.1-.561-2.725l-.204-.134-11.404-6.58.24-3.882c.03-.5.046-1.003.046-1.508l-.012-.755-.034-.752-.24-3.88 11.403-6.583a2.093 2.093 0 0 0 .876-2.64l-.11-.219-5.233-9.063a2.093 2.093 0 0 0-2.64-.876l-.22.11-11.465 6.618-3.232-2.116c-.543-.355-1.1-.689-1.67-.999l-.863-.448-3.502-1.727.001-13.298a2.093 2.093 0 0 0-1.849-2.079l-.244-.014z" />
        <path d="M49.907 34.302c8.67 0 15.698 7.028 15.698 15.698 0 8.67-7.028 15.698-15.698 15.698-8.67 0-15.698-7.028-15.698-15.698 0-8.67 7.028-15.698 15.698-15.698zm0 6.28a9.419 9.419 0 1 0 0 18.837 9.419 9.419 0 0 0 0-18.838z" />
      </svg>
    </BaseIcon>
  )
}
