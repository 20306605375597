import React from 'react'
import { useHistory } from 'react-router-dom'
import { getAgreement, getCurrentAuthor, submitAgree } from '../api/author'
import { useCustomerEnvContext } from '../context'
import { AgreementType } from '../constants'

const isDevelopment = process.env.NODE_ENV === 'development'

function empty(type: AgreementType) {
  return type === AgreementType.Register ? '未找到用户注册协议' : '未找到承诺函'
}

export default function useAgreement(type: AgreementType) {
  const [agreement, setAgreement] = React.useState(empty(type))

  React.useEffect(() => {
    getAgreement(type).then(resp => {
      const { data } = resp.data
      if (data) {
        try {
          const config = JSON.parse(data.content)
          setAgreement(config.html || empty(type))
        } catch {}
      }
    })
  }, [type])

  return agreement
}

const Counts = isDevelopment ? 3 : 5

export function usePromiseAgreeAction(visible?: boolean) {
  const [seconds, setSeconds] = React.useState(Counts)
  const timerRef = React.useRef(0)

  React.useEffect(() => {
    setSeconds(Counts)
  }, [visible])

  React.useEffect(() => {
    function count() {
      if (seconds > 0) {
        timerRef.current = window.setTimeout(() => {
          setSeconds(prev => prev - 1)
        }, 1000)
      }
    }

    if (visible) {
      count()
    }

    return () => {
      window.clearTimeout(timerRef.current)
    }
  }, [seconds, visible])

  return seconds
}

export function useRegisterAgreementConfirm() {
  const history = useHistory()
  const { currentUser, updateCurrentUser } = useCustomerEnvContext()
  const [pending, setPending] = React.useState(false)

  React.useEffect(() => {
    async function checkRegisterConfirmed() {
      const resp = await getCurrentAuthor()

      const { data } = resp.data
      updateCurrentUser(data)
    }

    checkRegisterConfirmed().then()

    return history.listen(checkRegisterConfirmed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function agree() {
    setPending(true)
    await submitAgree()
    setPending(false)
    updateCurrentUser(prev => ({
      ...prev,
      agree: 1,
    }))
  }

  return {
    needConfirm: currentUser && 'agree' in currentUser && currentUser.agree !== 1,
    isSubmitting: pending,
    agree,
  }
}
