// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconBell.module.less'

export default function IconBell(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M117.54040501 657.9394747v-258.84365092C117.54040501 180.13625299 284.83196331 1.71542425 492.36011236 1.71542425c207.52814904 0 374.81970734 178.42082764 374.81970733 397.38039953v255.97320568a9.06456386 9.06456386 0 0 0 1.81291235 5.3883799l47.6896772 63.95553222a58.71822907 58.71822907 0 0 1-47.08537236 93.81823413H128.16608699a58.66787087 58.66787087 0 0 1-50.86227381-88.02698433l39.02798325-67.73243474a9.06456386 9.06456386 0 0 0 1.20860858-4.53228194z m725.06437521 88.07734361l-31.57489676-42.35165548a81.27892125 81.27892125 0 0 1-16.11477936-48.59613337V399.09582378c0-180.08266432-135.96845467-325.11568394-302.55499174-325.11568392-166.5361778 0-302.55499176 145.03301854-302.55499176 325.11568392v258.84365092c0 14.30186702-3.72654323 28.30158161-10.82711801 40.63946064l-27.39512543 47.43788297h691.02190306z m-482.33550473 227.52054838c46.9846549 23.66858329 98.85410234 35.90574375 151.47893137 35.75466809a335.53993205 335.53993205 0 0 0 152.08323403-36.10717869 36.1071787 36.1071787 0 1 0-32.73314607-64.35840214 263.27521537 263.27521537 0 0 1-119.35008796 28.25122344 263.27521537 263.27521537 0 0 1-118.94721915-27.99943029 36.1071787 36.1071787 0 0 0-32.53171222 64.45911959z"></path>
      </svg>
    </BaseIcon>
  )
}
