import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconAuthorUserActive(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg
        width="49"
        height="55"
        viewBox="0 0 49 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 34c7.732 0 14-6.268 14-14S32.732 6 25 6s-14 6.268-14 14 6.268 14 14 14z"
          fill="#F7CA0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49 53.5C49 41.626 39.374 32 27.5 32S6 41.626 6 53.5c19.918.422 26 0 43 0z"
          fill="#F7CA0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 3C15.044 3 9 9.044 9 16.5S15.044 30 22.5 30 36 23.956 36 16.5 29.956 3 22.5 3zM6 16.5C6 7.387 13.387 0 22.5 0S39 7.387 39 16.5 31.613 33 22.5 33 6 25.613 6 16.5z"
          fill="#424242"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.5 52.903C.5 41.075 10.133 31.5 22 31.5c1.63 0 3.22.18 4.748.524a1.5 1.5 0 1 1-.657 2.927A18.663 18.663 0 0 0 22 34.5c-9.722 0-17.683 7.456-18.44 16.921 11.39.123 28.83.086 36.88 0-.505-6.321-4.223-11.747-9.54-14.655a1.5 1.5 0 1 1 1.44-2.632c6.646 3.635 11.16 10.674 11.16 18.769a1.5 1.5 0 0 1-1.48 1.5c-7.466.102-27.963.155-40.04 0a1.5 1.5 0 0 1-1.48-1.5zM13.5 14.5A1.5 1.5 0 0 1 15 13h9a1.5 1.5 0 0 1 0 3h-9a1.5 1.5 0 0 1-1.5-1.5zM28.5 14.5A1.5 1.5 0 0 1 30 13h6a1.5 1.5 0 0 1 0 3h-6a1.5 1.5 0 0 1-1.5-1.5z"
          fill="#424242"
        />
      </svg>
    </BaseIcon>
  )
}
