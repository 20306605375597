// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconUser.module.less'

export default function IconUser(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M495.91724227 10.66861659c146.85982087 0 265.92215291 119.05042297 265.92215289 265.91024385 0 97.74377928-52.73662195 183.17281931-131.30561355 229.38286779a442.66367797 442.66367797 0 0 1 68.24318964 27.82130696 33.39509627 33.39509627 0 1 1-30.25090744 59.54903187 370.94282454 370.94282454 0 0 0-168.76195451-40.56480035c-217.16340662 0-394.64333929 188.88952629-398.16864157 424.46549368l-0.05954866 7.15779392a33.39509627 33.39509627 0 0 1-66.80210382 0c0-224.29738019 138.39194865-414.58035491 329.29423255-476.86864182v0.01191018c-80.06962722-45.8170249-134.03295957-132.0797506-134.03295956-230.95496223 0-146.84791069 119.06233316-265.91024385 265.92215403-265.91024385zM645.05183576 872.62893272a33.39509627 33.39509627 0 0 1 0.7860472 66.80210272H570.54408766a33.39509627 33.39509627 0 0 1-0.7860472-66.79019366h75.2937953z m312.03692223 0a33.39509627 33.39509627 0 0 1 0.79795737 66.80210272H754.12184073a33.39509627 33.39509627 0 0 1-0.7860472-66.79019366H957.10066816z m-312.03692223-181.12433335a33.39509627 33.39509627 0 0 1 0.7860472 66.79019365l-0.7860472 0.01190906H570.54408766a33.39509627 33.39509627 0 0 1-0.7860472-66.79019255h75.2937953z m312.03692223 0a33.39509627 33.39509627 0 0 1 0.79795737 66.79019365l-0.7860472 0.01190906H754.12184073a33.39509627 33.39509627 0 0 1-0.7860472-66.79019255H957.10066816zM495.92915131 77.47072043c-109.96324045 0-199.12005019 89.14489956-199.12005017 199.10814001 0 109.97515064 89.14489956 199.12005019 199.12005017 199.1200502 109.96324045 0 199.12005019-89.14489956 199.1200502-199.1200502 0-109.96324045-89.14489956-199.10814001-199.1200502-199.10814001z"></path>
      </svg>
    </BaseIcon>
  )
}
