import AsyncComponent from './AsyncComponent'

import Prefix from '../constants/prefix'

const customerPlayletRoutes = [
  {
    path: '/script/:id(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-playlet-script" */ '../$$customerPlaylet/Script')
    ),
  },
  {
    path: '/upload/:scriptId',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-playlet-upload" */ '../$$customerPlaylet/Upload')
    ),
  },
]

export default [
  ...customerPlayletRoutes.map(item => ({
    ...item,
    path: `${Prefix.customerPlayletPrefix}${item.path}`,
  })),
]
