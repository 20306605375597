// lib
import React from 'react'
import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function LinkOutlined(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.499 6.529a2.992 2.992 0 0 0-.306-.264.607.607 0 0 0-.886.827.61.61 0 0 0 .15.13c.057.047.113.093.166.146l.05.05c.63.628.441 1.711-.188 2.34l-2.686 2.685a1.616 1.616 0 0 1-2.282 0l-.05-.05a1.614 1.614 0 0 1 0-2.281l1.186-1.186a.658.658 0 0 0-.41-1.177.656.656 0 0 0-.37.115V7.86l-.014.012a.663.663 0 0 0-.125.118L2.501 9.144a2.99 2.99 0 0 0 0 4.215l.05.05a2.99 2.99 0 0 0 4.214 0l2.685-2.685c1.158-1.16 1.256-2.987.098-4.146L9.5 6.53zm3.87-3.938l-.05-.05a2.988 2.988 0 0 0-4.214 0L6.42 5.226C5.261 6.386 5.197 8.06 6.356 9.22l.05.048c.052.053.107.103.162.15.04.045.088.084.141.114l.001.001a.573.573 0 0 0 .802-.747c-.076-.17-.218-.273-.316-.371l-.05-.049c-.628-.63-.39-1.543.24-2.173l2.686-2.685a1.613 1.613 0 0 1 2.281 0l.05.05c.63.629.63 1.653 0 2.282l-1.182 1.183a.653.653 0 0 0 .731 1.083l.003.004.018-.017a.658.658 0 0 0 .149-.137l1.246-1.15a2.988 2.988 0 0 0 .001-4.214z"
          fillRule="nonzero"
        />
      </svg>
    </BaseIcon>
  )
}
