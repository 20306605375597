import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconFilter(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
        <path d="M84.244 5H15.83C10.962 5 7 8.972 7 13.853c0 2.329.894 4.508 2.534 6.176.05.087.112.174.186.25l27.25 29.758v16.424c0 2.03 1.64 3.673 3.665 3.673a3.667 3.667 0 0 0 3.664-3.673V47.447c0-.286-.112-.56-.31-.76l-28.94-31.44a.961.961 0 0 0-.212-.236c-.21-.174-.559-.573-.559-1.158 0-.86.696-1.556 1.553-1.556h68.4c.857 0 1.553.697 1.553 1.556 0 .585-.348.984-.56 1.158a.806.806 0 0 0-.21.25L56.11 46.687c-.199.2-.31.473-.31.76 0 .286.012 14.73.012 38.686 0 .86-.708 1.557-1.565 1.557-.596 0-.994-.349-1.168-.56a.979.979 0 0 0-.248-.212l-4.484-4.395c-.211-.212-3.105-1.657-4.956.186-1.975 1.98-.323 4.919-.223 5.018l4.645 4.558c.075.074.161.137.248.186A8.813 8.813 0 0 0 54.248 95c4.894 0 8.868-3.972 8.868-8.853V50.05l27.25-29.76c.075-.074.137-.161.187-.248 1.627-1.669 2.534-3.848 2.534-6.176C93.075 8.972 89.112 5 84.244 5z" />
      </svg>
    </BaseIcon>
  )
}
