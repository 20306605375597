// lib
import React from 'react'
import classnames from 'classnames'
import { Button, Input, message } from '../components'
import BaseModal from '../components/Modal/components/BaseModal'
import { verify } from 'idcard-basic'

// components
import SignWatingImg from '../assets/sign-wating.png'
import SignSuccessImg from '../assets/sign-success.png'
import { useMutation, useQuery } from 'react-query'
import { getUserSignStatus, getUserSignUrl, userSign } from '../api/admin'
import { useAdminEnvContext } from '../context'
import { parseRequest } from '../api'
import { IDCARD_TYPE, IDCARD_TYPE_LABEL, IDCARD_TYPE_LIST, YesOrNo } from '../constants'
import { isEmpty } from '../utils'

// styles
// import styles from './NDAModal.module.scss'

interface Props {
  visible: boolean
  onSuccess(): void
}

interface UserInfoVO {
  name: string
  idcardType: keyof typeof IDCARD_TYPE
  idcard: string
}

const INIT_USER_INFO = {
  name: '',
  idcardType: IDCARD_TYPE.CRED_PSN_CH_IDCARD,
  idcard: '',
}

export default function NDAModal({ visible, onSuccess }: Props) {
  const [step, setStep] = React.useState<'first' | 'second' | 'thirdly'>('first')
  const { currentUser, updateCurrentUser } = useAdminEnvContext()
  const [userInfo, setUserInfo] = React.useState<UserInfoVO>(INIT_USER_INFO)

  const loadStatus = !!(
    visible &&
    currentUser.id &&
    currentUser.flowId &&
    currentUser.flowStatus !== YesOrNo.Yes
  )

  const {
    data: signStatus,
    isFetching: statusLoading,
    refetch: statusRefetch,
  } = useQuery(
    ['user-sign-status', currentUser.id],
    () => parseRequest(getUserSignStatus(currentUser.id)),
    {
      enabled: loadStatus,
      onSuccess(data) {
        if (data) {
          updateCurrentUser(p => ({ ...p, flowStatus: 1 }))
          onSuccess()
        }
      },
    }
  )

  const { data: signUrl } = useQuery(
    ['user-sign-url', currentUser.id],
    () => parseRequest(getUserSignUrl(currentUser.id)),
    { enabled: loadStatus }
  )

  React.useEffect(() => {
    if (visible && currentUser.flowId) {
      setStep('thirdly')
    }
  }, [currentUser.flowId, visible])

  const signMutation = useMutation(userSign, {
    onSuccess(resp) {
      message.success('发送成功')
      setStep('thirdly')
      const { data } = resp.data
      updateCurrentUser(p => ({ ...p, flowId: data, flowStatus: 0 }))
    },
  })

  function renderFirst() {
    return (
      <>
        <div className="text-sm text-black/50 px-7 text-center py-5">
          使用本平台需要签署保密协议
        </div>
        <Button size="large" block type="primary" onClick={() => setStep('second')}>
          下一步
        </Button>
      </>
    )
  }

  function renderSecond() {
    return (
      <form
        onSubmit={e => {
          e.preventDefault()
          const { idcardType, idcard, name } = userInfo
          if (isEmpty(idcard) || isEmpty(name)) {
            return message.warn('请将信息填写完整')
          }
          if (idcardType === IDCARD_TYPE.CRED_PSN_CH_IDCARD && !verify(idcard)) {
            return message.warn(`${IDCARD_TYPE_LABEL[idcardType]}不正确`)
          }
          const data = {
            userId: currentUser.id,
            idType: idcardType,
            idNum: idcard,
            realName: name,
          }
          signMutation.mutate(data)
        }}
        className="space-y-5 pt-5"
      >
        <Input
          placeholder="请输入真实姓名"
          size="large"
          className="rounded px-4"
          value={userInfo.name}
          onChange={e => setUserInfo(p => ({ ...p, name: e.target.value.trim() }))}
        />
        <select
          value={userInfo.idcardType}
          className="w-full h-10 px-4 rounded bg-black/5"
          onChange={e =>
            setUserInfo(p => ({
              ...p,
              idcardType: e.target.value as keyof typeof IDCARD_TYPE,
            }))
          }
        >
          {IDCARD_TYPE_LIST.map(type => (
            <option value={type.value} key={type.value}>
              {type.label}
            </option>
          ))}
        </select>
        <Input
          placeholder={`请输入${IDCARD_TYPE_LABEL[userInfo.idcardType]}`}
          size="large"
          className="rounded px-4"
          value={userInfo.idcard}
          onChange={e => setUserInfo(p => ({ ...p, idcard: e.target.value.trim() }))}
        />
        <Button
          size="large"
          block
          type="primary"
          onClick={() => setStep('second')}
          htmlType="submit"
          loading={signMutation.isLoading}
        >
          下一步
        </Button>
      </form>
    )
  }

  function renderThirdly() {
    return (
      <div>
        <div className="flex flex-col items-center px-7 py-5 space-y-5">
          <img src={signStatus ? SignSuccessImg : SignWatingImg} className="w-32" alt="" />
          <div className="text-center text-black/60">
            保密协议已通过短信发送至您的手机
            {signUrl ? (
              <span>
                （或点击
                <a href={signUrl} className="text-brand-default" target="_blank" rel="noreferrer">
                  链接
                </a>
                签署）
              </span>
            ) : (
              ''
            )}
            ，签署完成后返回系统！
          </div>
        </div>
        {!signStatus ? (
          <Button
            type="primary"
            loading={statusLoading}
            block
            onClick={() => statusRefetch()}
            size="large"
          >
            刷新状态
          </Button>
        ) : null}
      </div>
    )
  }

  return (
    <BaseModal visible={visible} contentClassName="!rounded-xl">
      <div className="p-5">
        <div className="text-center text-base font-medium mb-5">
          {step === 'first' ? '您有一份待确认的协议' : ''}
          {step === 'second' ? '填写身份信息' : ''}
          {step === 'thirdly' ? '签署保密协议' : ''}
        </div>
        <div className="flex items-center bg-blue-default/20 rounded py-3">
          <Step step={1} isActive={step === 'first' || step === 'thirdly' || step === 'second'}>
            确认签署协议
          </Step>
          <Step step={2} isActive={step === 'thirdly' || step === 'second'}>
            填写身份信息
          </Step>
          <Step step={3} isActive={step === 'thirdly'} last>
            签署保密协议
          </Step>
        </div>
        {step === 'first' ? renderFirst() : null}
        {step === 'second' ? renderSecond() : null}
        {step === 'thirdly' ? renderThirdly() : null}
      </div>
    </BaseModal>
  )
}

function Step({
  step,
  children,
  isActive,
  last,
}: {
  step: number
  children: string
  isActive?: boolean
  last?: boolean
}) {
  return (
    <div className="relative flex flex-col items-center justify-center flex-1">
      {!last ? (
        <div className="absolute right-0 top-4 translate-x-1/2 w-1/2 h-[1px] bg-blue-default" />
      ) : null}
      <div
        className={classnames(
          'flex items-center justify-center rounded-full border border-blue-default w-8 h-8',
          {
            'text-blue-default bg-white/50': !isActive,
            'text-white bg-blue-default': isActive,
          }
        )}
      >
        {step}
      </div>
      <div className="text-blue-default mt-3">{children}</div>
    </div>
  )
}
