// lib
import React from 'react'
import classnames from 'classnames'

// components

// styles
import styles from './Highlight.module.scss'

type Props = React.PropsWithChildren<{
  className?: string
  style?: React.CSSProperties
  lineClassName?: string
}>

export default function Highlight({ className, children, style, lineClassName }: Props) {
  return (
    <span className={classnames('relative', className)}>
      <span className={classnames(styles.children, 'relative text-sm md:text-base z-[1]')}>
        {children}
      </span>
      <span
        className={classnames(
          'absolute z-0 left-0 right-0 bottom-0 bg-brand-default h-2',
          lineClassName
        )}
        style={style}
      />
    </span>
  )
}
