// lib
import React from 'react'
import classnames from 'classnames'

// components
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  IconArrowLeftFine,
  IconArrowRightFine,
} from '../../components/Icons'

// styles
import styles from './Pagination.module.scss'
import useBasePage from './useBasePage'
import MobilePagination from '../MobilePagination'
import { useEnvContext } from '../../context'

export interface PaginationType {
  className?: string
  pageNo: number
  pageSize: number
  total: number
  onChange?: (pageNo: number, pageSize: number) => void
}

export interface BasePaginationProps {
  pageNo: number
  totalPage: number
  onChangeItem: (num: number) => void
}

function BasePagination({ pageNo, totalPage, onChangeItem }: BasePaginationProps) {
  const data = useBasePage(pageNo, totalPage)
  const { hasLeft, centerStartNo, centerEndNo, hasRight } = data

  function handleLeftMore() {
    let newPageNo = pageNo - 5
    if (newPageNo < 1) {
      newPageNo = 1
    }
    onChangeItem(newPageNo)
  }

  function handleRightMore() {
    let newPageNo = pageNo + 5
    if (newPageNo > totalPage) {
      newPageNo = totalPage
    }
    onChangeItem(newPageNo)
  }

  function renderItem(num: number) {
    return (
      <span
        key={num}
        className={classnames(styles.item, {
          [styles.active]: pageNo === num,
        })}
        onClick={() => onChangeItem(num)}
      >
        {num}
      </span>
    )
  }

  return (
    <>
      {hasLeft ? renderItem(1) : null}
      {hasLeft ? (
        <span onClick={handleLeftMore} className={styles['item-jump']}>
          <DoubleLeftOutlined className={styles['item-jump-icon']} />
          <span className={styles['item-more']}>···</span>
        </span>
      ) : null}

      {new Array(centerEndNo - centerStartNo + 1).fill(undefined).map((_, i) => {
        const num = centerStartNo + i
        return renderItem(num)
      })}

      {hasRight ? (
        <span onClick={handleRightMore} className={styles['item-jump']}>
          <DoubleRightOutlined className={styles['item-jump-icon']} />
          <span className={styles['item-more']}>···</span>
        </span>
      ) : null}
      {hasRight ? renderItem(totalPage) : null}
    </>
  )
}

export default function Pagination({
  className,
  pageNo,
  total,
  pageSize,
  onChange,
}: PaginationType) {
  const totalPage = Math.ceil(total / pageSize)
  const { isMobile } = useEnvContext()

  if (totalPage <= 1) {
    return null
  }

  if (isMobile) {
    return (
      <MobilePagination
        pageNo={pageNo}
        totalPages={totalPage}
        onChange={pageNo => {
          onChange?.(pageNo, pageSize)
        }}
      />
    )
  }

  return (
    <div className={classnames(styles.pagination, className)}>
      <span className={styles.total}>
        共{totalPage}页 共{total}条
      </span>
      <span
        className={styles.item}
        onClick={() => {
          if (pageNo > 1) {
            if (typeof onChange === 'function') {
              onChange(pageNo - 1, pageSize)
            }
          }
        }}
      >
        <IconArrowLeftFine className={styles['arrow-icon']} />
      </span>
      <BasePagination
        totalPage={totalPage}
        pageNo={pageNo}
        onChangeItem={num => {
          if (typeof onChange === 'function') {
            onChange(num, pageSize)
          }
        }}
      />
      <span
        className={styles.item}
        onClick={() => {
          if (pageNo < totalPage) {
            if (typeof onChange === 'function') {
              onChange(pageNo + 1, pageSize)
            }
          }
        }}
      >
        <IconArrowRightFine className={styles['arrow-icon']} />
      </span>
    </div>
  )
}
