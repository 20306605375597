import Prefix from '../../constants/prefix'
// import TopicJl from '../../assets/topic/topic-jl.png'
// import TopicZy from '../../assets/topic/topic-zy.png'
// import TopicYs from '../../assets/topic/topic-ys.png'
// import {
//   TOPIC_UPLOAD_TYPE_LABEL_MAP,
//   TOPIC_UPLOAD_TYPE_MAP,
// } from '../../constants/topic'

import HandWithStar1 from '../../assets/banner/handWithStar-1.png'
import HandWithStar2 from '../../assets/banner/handWithStar-2.png'
import HandWithStar3 from '../../assets/banner/handWithStar-3.png'

export function getUseableUploadTypeNavList(
  submitSwicth: IAUTHOR.AuthorSubmitVO | undefined
): { name: string; path: string; img: string }[] {
  return [
    {
      name: '项目孵化投稿',
      path: `${Prefix.customerPrefix}/upload/edit`,
      show: submitSwicth?.qgwSubmit,
      img: HandWithStar1,
    },
    {
      name: '青春中国梦投稿',
      path: `${Prefix.customerTopic2023Prefix}`,
      show: true,
      img: HandWithStar2,
    },
    {
      name: '微短剧投稿',
      path: `${Prefix.customerPlayletPrefix}/upload/edit`,
      show: submitSwicth?.playletSubmit,
      img: HandWithStar3,
    },
  ].filter(item => item.show)
}
