import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

import routes from './routes'
import Layout from './layout'
import { QueryClient, QueryClientProvider } from 'react-query'

dayjs.locale('zh-cn')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Layout>{renderRoutes(routes)}</Layout>
      </Router>
    </QueryClientProvider>
  )
}
