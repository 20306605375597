// lib
import React from 'react'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'

// components

// styles
import styles from './Nav.module.scss'
import { Dropdown } from '../../components'

type NameType = React.ReactNode | ((isActive: boolean) => React.ReactNode)

export interface RouteItem {
  path?: string | string[]
  name: NameType
  hide?: boolean
  isActive?: (route: RouteItem) => boolean
  className?: string
  children?: Omit<RouteItem, 'className' | 'children'>[]
}

interface NavProps {
  routes: RouteItem[]
}

export default function Nav({ routes }: NavProps) {
  const location = useLocation()

  const list = routes.filter(route => !route.hide)

  const overflow = list.length > 4

  const isActive = React.useCallback(
    (route: RouteItem) => {
      if (typeof route.isActive === 'function') {
        return route.isActive(route)
      }
      if (!route.path) {
        return false
      }
      if (typeof route.path === 'string') {
        return location.pathname === route.path
      }
      return route.path.some(path => path === location.pathname)
    },
    [location.pathname]
  )

  const renderName = (name: NameType, active: boolean) => {
    if (typeof name === 'function') {
      return name(active)
    }
    return name
  }

  const renderItem = (route: RouteItem, index: number) => {
    const active = isActive(route)
    const props = {
      className: classNames(
        styles['nav-item'],
        { [styles.active]: active, 'px-4': overflow, 'px-5': !overflow },
        route.className
      ),
    }
    const childrenList = route.children?.filter(route => !route.hide)
    if (route.path) {
      const path = typeof route.path === 'string' ? route.path : route.path[0]
      return (
        <Link key={index} to={path} {...props}>
          {renderName(route.name, active)}
        </Link>
      )
    }
    if (childrenList?.length) {
      return (
        <Dropdown
          overlayClassName="!bg-transparent rounded relative after:absolute after:block after:w-full after:h-4 after:left-0 after:-top-4"
          key={index}
          placement="bottomCenter"
          overlay={
            <div className="bg-white rounded shadow text-sm py-1.5">
              {childrenList.map((item, itemIndex) => {
                return item.path ? (
                  <Link
                    key={itemIndex}
                    className="h-8 flex justify-center items-center px-4 transition-all hover:bg-brand-default/30"
                    to={Array.isArray(item.path) ? item.path[0] : item.path}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <div
                    key={itemIndex}
                    className="h-8 flex justify-center items-center px-4 transition-all hover:bg-brand-default/30"
                  >
                    item.name
                  </div>
                )
              })}
            </div>
          }
        >
          <div {...props}>{renderName(route.name, active)}</div>
        </Dropdown>
      )
    }
    return (
      <div key={index} {...props}>
        {renderName(route.name, active)}
      </div>
    )
  }

  return (
    <div className={styles.nav} style={{ fontSize: overflow ? '16px' : '18px' }}>
      {list.map(renderItem)}
    </div>
  )
}
