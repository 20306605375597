// lib
import React from 'react'
import classNames from 'classnames'

// components

// styles
import styles from './Menu.module.scss'

interface Props {
  className?: string
  children: React.ReactElement[] | React.ReactElement
  defaultSelectedKeys?: string[]
  onClick?: ({ item, key }: { item: React.ReactElement; key: string }) => void
}

export default function Menu({ children, defaultSelectedKeys = [], onClick, className }: Props) {
  return (
    <div className={classNames('bg-white py-1.5', className)}>
      {React.Children.map(children, item => {
        const itemKey = item.key as string
        return (
          <>
            {React.cloneElement(item, {
              onClick: () => {
                if (typeof onClick === 'function') {
                  onClick({ item, key: itemKey })
                }
              },
              active: defaultSelectedKeys?.includes(itemKey),
            })}
          </>
        )
      })}
    </div>
  )
}

type MenuItemProps = React.PropsWithChildren<{
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  key: React.ReactText
  active?: boolean
  className?: string
}>

function MenuItem({ children, onClick, active, className }: MenuItemProps) {
  return (
    <div
      onClick={onClick}
      className={classNames(styles['menu-item'], className, {
        [styles.active]: active,
      })}
    >
      {children}
    </div>
  )
}

Menu.Item = MenuItem
