// lib
import React from 'react'
import classNames from 'classnames'

// components

// styles
import styles from './Container.module.scss'

type Props = React.PropsWithChildren<{
  className?: string
}>

export default function Container({ children, className }: Props) {
  return <div className={classNames(styles.container, className)}>{children}</div>
}
