// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconPen.module.less'

export default function IconPen(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M991.43663173 203.47242116L820.54084231 32.57663173a83.98231538 83.98231538 0 0 0-118.73178942 0L41.22421058 693.17473653c-0.31831538 0.30505289-0.41115769 0.72947405-0.70294711 1.06105289-2.14863173 2.28126347-3.89936827 4.90736827-5.17263174 7.77221058-0.07957884 0.18568462-0.21221058 0.34484231-0.29178942 0.53052595-1.02126347 2.52-1.67115769 5.17263173-1.92315768 7.89157885L8.13263173 985.48147405A27.99852595 27.99852595 0 0 0 35.98526347 1016c0.84884231 0 1.68442116 0 2.52-0.1061048l275.07789422-25.01431636c2.70568463-0.252 5.35831538-0.88863173 7.87831636-1.90989422 0.19894711-0.07957884 0.37136827-0.23873653 0.5570519-0.31831635 2.86484231-1.27326347 5.47768462-3.01073653 7.7589481-5.1461048 0.31831538-0.29178942 0.74273653-0.3978952 1.0610519-0.7029481L855.91368463 457.74042116l0.07957884-0.05305289s0-0.06631537 0.05305191-0.07957885L991.43663173 322.20421058a84.0618952 84.0618952 0 0 0 0-118.74505289zM586.14105289 227.39915769l210.48631538 210.48631636-485.56421058 485.56421058-210.48631538-210.48631636 485.56421058-485.56421058zM66.90168463 957.11157884L83.46736827 775.00842116 249.03136827 940.53263173 66.88842116 957.12484231z m884.9709471-674.47136826l-115.668 115.65473653-210.49957884-210.47305191 115.668-115.668a27.95873653 27.95873653 0 0 1 39.59052595 0L951.87263173 243.02315769c10.90231537 10.9421048 10.90231537 28.64842116 0 39.60378942z"></path>
        <path d="M336.06421058 715.93431538c7.42736827 0 14.54968463-2.94442116 19.78863173-8.19663075l250.07684231-250.06357983a27.98526347 27.98526347 0 1 0-39.57726346-39.57726249L316.27557884 668.16042116a27.98526347 27.98526347 0 0 0 19.78863174 47.77389422z"></path>
      </svg>
    </BaseIcon>
  )
}
