// lib
import React from 'react'
import classnames from 'classnames'

// components
import Spin from '../Spin'
import Checkbox from '../Checkbox'
import Highlight from '../../components/Highlight'
import { PaginationType } from '../Pagination'

// request
// styles
import style from './style.module.scss'

// utils

// context
import TableContext, { useTable, ColumnType as ColType } from './context'

// 格式化 columns
const formatColumns = (columns: ColType<any>[]) => {
  return columns.filter(item => !item.hide)
}

export type ColumnType<T> = ColType<T>

const SortableItem = ({ item, itemIndex }: { item: any; itemIndex: number }) => {
  const {
    showNo,
    columns,
    onRow = () => {},
    getCount,
    countRender,
    showCheckbox,
    isChecked,
    isDisabled,
    onChangeCheckbox,
  } = useTable()

  const count = getCount(itemIndex)

  return (
    <tr {...onRow(item)}>
      {showCheckbox && (
        <td>
          <Checkbox
            checked={isChecked ? isChecked(item) : false}
            disabled={isDisabled ? isDisabled(item) : false}
            onChange={onChangeCheckbox ? onChangeCheckbox(item) : undefined}
          />
        </td>
      )}
      {showNo && <td style={{ whiteSpace: 'nowrap' }}>{countRender(count, item)}</td>}
      {columns.map((col, i) => {
        let content = col.dataIndex && item[col.dataIndex]
        content = col.render ? col.render(content, item, itemIndex) : content
        return (
          <td
            key={col.key || (col.dataIndex as string) || i}
            style={{ textAlign: col.align || 'left' }}
          >
            {content}
          </td>
        )
      })}
    </tr>
  )
}

const SortableList = ({ dataSource, rowKey }: { dataSource: any[]; rowKey?: string }) => (
  <tbody>
    {dataSource.map((item, index) => {
      const key = rowKey ? item[rowKey] : index
      return <SortableItem key={key} itemIndex={index} item={item} />
    })}
  </tbody>
)

interface TableProps<T = any> {
  columns: ColType<T>[] // 表格列的配置描述
  dataSource: T[] // 数据数组
  rowKey: string // 表格行 key 的取值，字符串
  showNo?: boolean // 是否显示序号
  loading?: boolean // 页面是否加载中
  pagination?: PaginationType // 分页
  emptyText?: boolean // 空文案
  onRow?: (item: T) => any
  className?: string
  countRender?: (count: number, item: T) => React.ReactNode
  batch?: boolean // 批量操作
  checkedList?: T[] // 已选list
  onChangeCheckbox?: (list: T[]) => void
  disabledList?: Array<number | string>
}

function Table<T extends Record<string, any>>(props: TableProps<T>) {
  // 切换分页，要从外部重置checkedList
  const [checkedList, setCheckedList] = React.useState<T[]>([])

  const {
    dataSource,
    rowKey,
    showNo,
    pagination,
    loading,
    emptyText,
    onRow,
    className,
    countRender = count => count,
    checkedList: checks,
    batch,
    disabledList,
  } = props

  let { columns } = props

  columns = formatColumns(columns)

  React.useEffect(() => {
    if (typeof checks !== 'undefined' && checks.length !== checkedList.length) {
      setCheckedList(checks)
    }
  }, [checkedList.length, checks])

  const pageNo = pagination?.pageNo || 1

  const getCount = React.useCallback(
    (index: number) => {
      const count =
        pageNo && pagination?.pageSize ? (pageNo - 1) * pagination?.pageSize + index + 1 : index + 1
      return count
    },
    [pageNo, pagination]
  )

  const showCheckbox = React.useMemo(() => {
    return batch && !!rowKey
  }, [batch, rowKey])

  const countColWidth = React.useMemo(() => {
    const paddingLeft = !showCheckbox ? 18 : 8
    const paddingRight = 8
    const maxCount = getCount(dataSource.length - 1)
    let num = String(maxCount).length * 10 + paddingLeft + paddingRight
    if (num < 50) {
      num = 50
    }
    return num
  }, [dataSource.length, getCount, showCheckbox])

  // 全选是否禁用
  const isDisabledAll = React.useMemo(() => {
    return dataSource.length - (disabledList?.length || 0) === 0
  }, [dataSource.length, disabledList?.length])

  // 全选是否选中
  const isCheckedAll = React.useMemo(() => {
    if (isDisabledAll) {
      return false
    }
    return checkedList.length === dataSource.length - (disabledList?.length || 0)
  }, [checkedList.length, dataSource.length, disabledList?.length, isDisabledAll])

  // 复选框是否选中
  const isChecked = React.useCallback(
    (item: T) => {
      if (!rowKey) {
        return false
      }
      return !!checkedList.find(checked => checked[rowKey] === item[rowKey])
    },
    [checkedList, rowKey]
  )

  // 复选框是否禁用
  const isDisabled = React.useCallback(
    (item: T) => {
      if (!rowKey || !disabledList) {
        return false
      }
      return !!disabledList.find(key => String(key) === String(item[rowKey]))
    },
    [disabledList, rowKey]
  )

  // 复选框选中
  const onChangeCheckbox =
    (item: T) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      let list = checkedList.filter(checked => checked[rowKey] !== item[rowKey])
      if (target.checked) {
        list = [...list, item]
      }
      setCheckedList(list)
      if (props.onChangeCheckbox) {
        props.onChangeCheckbox(list)
      }
    }

  // 全选
  const onChangeCheckboxAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    let list: T[] = []
    if (target.checked) {
      if (disabledList && rowKey) {
        list = dataSource.filter(item => !disabledList.map(String).includes(String(item[rowKey])))
      } else {
        list = dataSource
      }
    }
    setCheckedList(list)
    if (props.onChangeCheckbox) {
      props.onChangeCheckbox(list)
    }
  }

  const renderTable = () => {
    const narrowTable = showNo

    return (
      <TableContext.Provider
        value={{
          countRender,
          getCount,
          showNo,
          columns,
          onRow,
          showCheckbox,
          isChecked,
          isDisabled,
          onChangeCheckbox,
        }}
      >
        <table className={classnames('text-[15px]', { [style.narrow]: narrowTable })}>
          <colgroup>
            {showCheckbox && <col width={50} />}
            {showNo && <col width={countColWidth} />}
            {columns.map((item, index) => (
              <col key={item.key || (item.dataIndex as string) || index} width={item.width} />
            ))}
          </colgroup>
          <thead>
            <tr>
              {showCheckbox && (
                <th>
                  <Checkbox
                    disabled={isDisabledAll}
                    checked={isCheckedAll}
                    onChange={onChangeCheckboxAll}
                  />
                </th>
              )}
              {showNo && <th />}
              {columns.map((item, index) => (
                <th
                  key={item.key || (item.dataIndex as string) || index}
                  style={{ textAlign: item.align || 'left' }}
                >
                  {item.title ? (
                    <Highlight style={{ bottom: -2 }} className={style.title}>
                      {item.title}
                    </Highlight>
                  ) : null}
                </th>
              ))}
            </tr>
          </thead>
          <SortableList dataSource={dataSource} rowKey={rowKey} />
        </table>
      </TableContext.Provider>
    )
  }

  return (
    <Spin
      loading={loading}
      empty={!dataSource.length}
      emptyText={emptyText}
      pagination={pagination}
    >
      <div className={classnames(style['table-container'], className)}>{renderTable()}</div>
    </Spin>
  )
}

export default Table
