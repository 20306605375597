import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconAuthorUser(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg
        width="44"
        height="55"
        viewBox="0 0 44 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 3C15.044 3 9 9.044 9 16.5S15.044 30 22.5 30 36 23.956 36 16.5 29.956 3 22.5 3zM6 16.5C6 7.387 13.387 0 22.5 0S39 7.387 39 16.5 31.613 33 22.5 33 6 25.613 6 16.5z"
        />
        <path d="M22 31.5c1.612 0 3.201.177 4.748.524a1.5 1.5 0 1 1-.657 2.927A18.662 18.662 0 0 0 22 34.5c-10.219 0-18.5 8.24-18.5 18.403a1.5 1.5 0 0 1-3 0C.5 41.08 10.127 31.5 22 31.5zm10.34 2.634c6.826 3.733 11.16 10.872 11.16 18.769a1.5 1.5 0 0 1-3 0c0-6.788-3.726-12.924-9.6-16.137a1.5 1.5 0 1 1 1.44-2.632zM29 13a1.5 1.5 0 0 1 .145 2.993L29 16h-9a1.5 1.5 0 0 1-.145-2.993L20 13h9zM14 13a1.5 1.5 0 0 1 .145 2.993L14 16H8a1.5 1.5 0 0 1-.144-2.993L8 13h6zM18.5 19a1.5 1.5 0 0 1 .145 2.993L18.5 22H16a1.5 1.5 0 0 1-.145-2.993L16 19h2.5z" />
      </svg>
    </BaseIcon>
  )
}
