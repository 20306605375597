// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconSearch.module.less'

export default function IconSearch(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M1003.86592063 956.16374001l-212.069824-210.83281445-0.92775717-0.85044406a444.00911383 444.00911383 0 1 0-47.62486751 47.93411992 34.0177625 34.0177625 0 0 0 0.77313096 0.85044406l212.1471371 210.91012755a33.70851009 33.70851009 0 0 0 47.85680681-0.15462621 33.86313633 33.86313633 0 0 0-0.15462619-47.9341199z m-404.27018216-153.15724437c-46.38785797 19.56021345-95.63630052 29.5336029-146.431005 29.5336029a373.96344829 373.96344829 0 0 1-266.18899163-110.24847577 375.20045785 375.20045785 0 0 1-110.17116265-266.11167851 373.96344829 373.96344829 0 0 1 110.17116265-266.11167854A375.20045785 375.20045785 0 0 1 453.24204657 79.81978996a373.96344829 373.96344829 0 0 1 266.11167854 110.24847576 375.20045785 375.20045785 0 0 1 110.24847577 266.11167854 373.96344829 373.96344829 0 0 1-110.24847577 266.11167851 375.20045785 375.20045785 0 0 1-119.68067356 80.71487287z"></path>
      </svg>
    </BaseIcon>
  )
}
