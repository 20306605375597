// lib
import React from 'react'
import classNames from 'classnames'

// components

// styles
import styles from './Input.module.scss'

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  inputClassName?: string
  addonAfter?: React.ReactNode
  addonBefore?: React.ReactNode
  size?: 'small' | 'medium' | 'large'
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, addonAfter, addonBefore, inputClassName, size = 'medium', ...props }, ref) => {
    return (
      <div
        className={classNames(
          'flex items-center bg-black/5 focus-within:bg-brand-default/30',
          { [styles[`input-size-${size}`]]: size },
          className
        )}
      >
        {addonBefore}
        <input
          ref={ref}
          className={classNames(
            styles.input,
            'flex-1 border-none outline-none h-full w-full bg-transparent p-0',
            inputClassName
          )}
          type="text"
          {...props}
        />
        {addonAfter}
      </div>
    )
  }
)

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  inputClassName?: string
  addonAfter?: React.ReactNode
  addonBefore?: React.ReactNode
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, addonAfter, addonBefore, inputClassName, ...props }, ref) => {
    return (
      <div
        className={classNames(
          'flex items-center bg-black/5 focus-within:bg-brand-default/30',
          className
        )}
      >
        {addonBefore}
        <textarea
          ref={ref}
          className={classNames(
            styles.textarea,
            'w-full h-full border-none outline-none bg-transparent py-2.5 resize-none',
            inputClassName
          )}
          {...props}
        />
        {addonAfter}
      </div>
    )
  }
)

export default Input
