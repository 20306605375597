import { RouteConfig } from 'react-router-config'
import AsyncComponent from './AsyncComponent'

const routes: RouteConfig[] = [
  {
    path: '/403',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "403" */ '../components/ErrorPage/Forbidden')
    ),
  },
  {
    path: '/500',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "500" */ '../components/ErrorPage/ServerError')
    ),
  },
  {
    path: '*',
    component: AsyncComponent(
      () => import(/* webpackChunkName: "404" */ '../components/ErrorPage/NotFound')
    ),
  },
]

export default routes
