import styles from './Message.module.scss'
import {
  IconCheckCircleFilled,
  IconCloseCircleFilled,
  IconExclamationCircleFilled,
} from '../../components/Icons'

export enum MessageType {
  Success = 'success',
  Warn = 'warn',
  Error = 'error',
}

export const MESSAGE_TYPE_CLASSNAME = {
  [MessageType.Success]: styles.success,
  [MessageType.Warn]: styles.warn,
  [MessageType.Error]: styles.error,
}

export const MESSAGE_TYPE_ICON = {
  [MessageType.Success]: IconCheckCircleFilled,
  [MessageType.Warn]: IconExclamationCircleFilled,
  [MessageType.Error]: IconCloseCircleFilled,
}

export const DURATION = 3000
