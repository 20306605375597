import AsyncComponent from './AsyncComponent'

import Prefix from '../constants/prefix'

const customerTopicRoutes = [
  {
    path: '/',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-topic-home" */ '../$$customerTopic/Home')
    ),
  },
  {
    path: '/script/:id(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-topic-script" */ '../$$customerTopic/Script')
    ),
  },
  {
    path: '/upload/:scriptId',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-topic-upload" */ '../$$customerTopic/Upload')
    ),
  },
]

export default [
  ...customerTopicRoutes.map(item => ({
    ...item,
    path: `${Prefix.customerTopicPrefix}${item.path}`,
  })),
]
