import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function LikeOutlined(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M988.83052969 420.1157229C967.27854436 385.47247858 929.89128066 363.74899202 891.58934386 362.60565062l-237.92934475-1.14334137a432.46888347 432.46888347 0 0 0 17.26445509-216.94903013L666.35108861 125.36231073a119.76501134 119.76501134 0 0 0-117.24966027-93.35382509 120.6225174 120.6225174 0 0 0-119.93651256 131.31275945C425.96355986 281.14257608 284.13205956 402.73693367 235.88305259 402.73693367h-0.05716708l-176.70341294 0.11433414a42.01779635 42.01779635 0 0 0-41.90346218 41.96062928l-0.45733657 494.49515428c0 23.43849864 20.29430981 40.47428546 45.73365589 41.16029029l153.20774723-0.22866829 5.88820818 1.02900725 5.14503628-0.68600482 583.10411256 0.22866828a122.68053191 122.68053191 0 0 0 57.4529052-18.75079893c18.57929772-11.9479176 33.55707-28.69786907 40.35995132-43.50414015l7.14588375-11.83358347c40.18845011-95.64050787 89.18062897-388.56457383 91.18147641-400.91266092l0.80033897-11.83358344a121.19418809 121.19418809 0 0 0-17.95045992-73.85985427zM88.6778477 474.65310755l95.29750546-0.11433414v434.64123212l-95.64050789 0.28583537 0.34300243-434.81273335z m762.43721068 400.05515486l-4.45903143 7.14588374a50.36418854 50.36418854 0 0 1-17.89329286 20.00847444 48.76351058 48.76351058 0 0 1-21.32331705 7.43171909l-551.9480595-0.17150121V470.70857973C349.64552161 452.3579503 496.56489114 308.29693426 500.56658604 159.77688676l-0.40016951-7.26021787c0-27.04002406 21.89498775-49.04934594 48.93501181-49.04934593a48.59200938 48.59200938 0 0 1 47.67733627 37.95893438l4.00169488 17.03578682a359.58086941 359.58086941 0 0 1-15.03493939 180.07627005l-32.87106515 94.21133113 325.45212871 0.11433415 7.71755442 0.80033898c17.26445509 0.57167069 32.9853993 9.71840188 42.07496341 24.29600468 5.65953991 9.03239704 8.23205807 19.60830497 7.37455202 36.12958814-0.51450364 2.85835349-49.6781837 296.92576084-84.37859513 380.61835112z"></path>
      </svg>
    </BaseIcon>
  )
}
