// lib
import React from 'react'
import classnames from 'classnames'
import Dropdown from '../Dropdown'

// components
import { IconArrowBottom } from '../Icons'

// styles
import styles from './TagSelect.module.scss'

interface Props<T> {
  style?: React.CSSProperties
  className?: string
  list: T[]
  renderItem?: (item: T) => React.ReactNode
  value?: React.ReactText[]
  onChange?: (value: React.ReactText[]) => void
  size?: 'small' | 'medium' | 'large'
  getPopupContainer?(): HTMLElement
}

export default function TagSelect<T extends { label: React.ReactText; value: React.ReactText }>({
  style,
  className,
  list,
  renderItem,
  value,
  onChange,
  size = 'medium',
  getPopupContainer,
}: Props<T>) {
  function handleItem(item: T) {
    if (typeof onChange !== 'function') {
      return
    }
    if (value && value.includes(item.value)) {
      onChange(value.filter(v => v !== item.value))
    } else {
      onChange([...(value || []), item.value])
    }
  }

  function renderOverlay() {
    return (
      <div className="flex flex-wrap px-2.5 py-2 max-w-[240px] max-h-80 overflow-auto">
        {list.map(item => (
          <span
            onClick={() => handleItem(item)}
            className={classnames('text-sm px-1 rounded-full mr-2 mb-1 cursor-pointer', {
              'bg-brand-default/40': value && value.includes(item.value),
            })}
            key={item.value}
          >
            {typeof renderItem === 'function' ? renderItem(item) : item.label}
          </span>
        ))}
      </div>
    )
  }

  function renderContent() {
    if (!value || !value.length) {
      return '请选择题材'
    }
    return list
      .filter(item => value.includes(item.value))
      .map(item => item.label)
      .join('/')
  }

  return (
    <Dropdown
      clickOverlayClose={false}
      trigger={['click']}
      overlay={renderOverlay()}
      getPopupContainer={getPopupContainer}
    >
      <div
        style={style}
        className={classnames(
          'flex items-center justify-between px-2.5 w-full cursor-pointer',
          [styles[`select-size-${size}`]],
          className
        )}
      >
        <span className="flex-1 w-0 whitespace-nowrap overflow-hidden overflow-ellipsis">
          {renderContent()}
        </span>
        <span className="flex items-center justify-center">
          <IconArrowBottom />
        </span>
      </div>
    </Dropdown>
  )
}
