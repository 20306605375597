// lib
import React from 'react'
import classnames from 'classnames'

// components
import Loading from '../Loading'

// styles
import style from './style.module.scss'

/**
 * 自己写 loading 组件 必须flex: 1
 */
const LoadingContainer = ({
  className,
  children,
  style: styles,
}: React.PropsWithChildren<{
  className?: string
  style?: React.CSSProperties
}>) => {
  return (
    <div className={classnames(style['loading-container'], className)} style={styles}>
      {children || <Loading.Ellipsis />}
    </div>
  )
}

export default LoadingContainer
