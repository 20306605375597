import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'

import ModalContent, { ContentType } from './ModalContent'

type BaseModalType = React.PropsWithChildren<
  {
    title?: React.ReactNode
    visible?: boolean
    onCancel?: () => void
  } & ContentType
>

function BaseModal({
  title,
  visible,
  children,
  width,
  className,
  onCancel,
  maskClosable = true,
  ...props
}: BaseModalType) {
  const elementRef = React.useRef(document.createElement('div'))

  React.useEffect(() => {
    const dom = elementRef.current
    if (visible) {
      document.body.appendChild(dom)
    }

    return () => {
      if (document.body.contains(dom)) {
        document.body.removeChild(dom)
      }
    }
  }, [visible])

  function renderModal() {
    return (
      <ModalContent
        width={width}
        onClickMask={onCancel}
        maskClosable={maskClosable}
        className={classNames(className)}
        {...props}
      >
        {children}
      </ModalContent>
    )
  }

  return ReactDOM.createPortal(renderModal(), elementRef.current)
}

export default BaseModal
