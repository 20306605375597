import React from 'react'

import { getUnReadCount } from '../api/admin'

function useUnReadCount() {
  const [unReadCount, setUnReadCount] = React.useState(0)
  const [refresh, setRefresh] = React.useState(true)

  React.useEffect(() => {
    if (refresh) {
      const getCount = async () => {
        const resp = await getUnReadCount()
        const { data } = resp.data
        setUnReadCount(data)
        setRefresh(false)
      }
      getCount()
    }
  }, [refresh])

  function updateUnReadCount() {
    setRefresh(true)
  }

  return { unReadCount, setUnReadCount, updateUnReadCount }
}

export default useUnReadCount
