import React, { useState } from 'react'

import { XMLConfig } from '../api'
import { goToLogin } from '../utils'
import Code from '../constants/code'

function useCurrentAdmin(): [
  UserVO,
  React.Dispatch<React.SetStateAction<UserVO>>,
  string[],
  boolean
] {
  // eslint-disable-next-line no-underscore-dangle
  const data = (window as any).__INITIAL_MODEL_DATA || {}
  const [user, setUser] = React.useState<UserVO>(data.user)
  const [roles, setRoles] = React.useState<string[]>(data.roles || [])
  const [roleLoaded, setRoleLoaded] = useState(false)

  React.useEffect(() => {
    const { pathname } = window.location
    const userApi = '/api/api-user/user/curUser'
    const userRoleApi = '/api/api-user/user/userRoles'

    async function getCurrentUserRoles() {
      const resp = await window.fetch(userRoleApi, XMLConfig).then(resp => resp.json())
      if (resp.code === 0) {
        setRoles(resp.data)
        setRoleLoaded(true)
      }
    }

    async function getCurrentUser() {
      try {
        const userResp = await window.fetch(userApi, XMLConfig).then(resp => resp.json())
        if (userResp.code === Code.SERVER_UNAUTHORIZED) {
          throw new Error('请登录')
        }
        setUser(userResp.data)
        getCurrentUserRoles()
      } catch (e) {
        goToLogin()
      }
    }

    const isLoginPage = /\/login/.test(pathname)

    if (process.env.NODE_ENV === 'development' && !isLoginPage && !user) {
      getCurrentUser()
    }
  }, [user])

  return [user || {}, setUser, roles, roleLoaded]
}

export default useCurrentAdmin
