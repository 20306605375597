const customerPrefix = '/author'
const customerTopicPrefix = '/author/topic'
const customerTopic2023Prefix = '/author/topic-2023'
const customerPlayletPrefix = '/author/playlet'
const adminPrefix = '/admin'
const adminTopicPrefix = '/admin/topic'
const adminTopic2023Prefix = '/admin/topic-2023'
const adminPlayletPrefix = '/admin/playlet'

const Prefix = {
  customerPrefix,
  customerTopicPrefix,
  customerTopic2023Prefix,
  customerPlayletPrefix,
  adminPrefix,
  adminTopicPrefix,
  adminTopic2023Prefix,
  adminPlayletPrefix,
}

export default Prefix
