// lib
import React from 'react'
import classNames from 'classnames'

// components
import Dropdown from '../Dropdown'
import { IconArrowBottom, IconCloseCircleFilled } from '../Icons'

// styles
import styles from './Select.module.scss'

type SelectProps = {
  value?: React.ReactText
  onChange?: (value?: React.ReactText) => void
  placeholder?: string
  children: React.ReactElement[] | React.ReactElement
  className?: string
  style?: React.CSSProperties
  placeholderClassName?: string
  allowClear?: boolean
  size?: 'small' | 'medium' | 'large'
}

type OptionProps = React.PropsWithChildren<{
  disabled?: boolean
  value: React.ReactText
  onClick?: (value: React.ReactText) => void
  className?: string
}>

export default function Select({
  value,
  onChange,
  className,
  style,
  children,
  placeholder,
  placeholderClassName,
  allowClear,
  size = 'medium',
}: SelectProps) {
  const selected = React.useMemo(() => {
    const active = React.Children.toArray(children).find((item: any) => item.props.value === value)
    if (active) {
      return (active as React.ReactElement).props.children
    }
    return value
  }, [children, value])

  function handleClear(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    e.stopPropagation()
    if (typeof onChange === 'function') {
      onChange(undefined)
    }
  }

  const canClear = selected && allowClear

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <div className="py-1 max-h-80 overflow-auto">
          {React.Children.map(children, (option: React.ReactElement) => {
            const { props } = option
            const checked = value === props.value
            return React.cloneElement(option as React.ReactElement, {
              ...props,
              className: classNames(props.className, {
                [styles.active]: checked,
                [styles.disabled]: props.disabled,
              }),
              onClick() {
                if (!props.disabled && onChange) {
                  onChange(props.value)
                }
              },
            })
          })}
        </div>
      }
    >
      <div
        className={classNames(
          styles['select-selector'],
          'flex items-center justify-between px-2.5 w-full cursor-pointer',
          {
            [styles['allow-clear']]: canClear,
            [styles[`select-size-${size}`]]: size,
          },
          className
        )}
        style={style}
      >
        <span>
          {selected || (
            <span className={classNames(placeholderClassName, styles.placeholder)}>
              {placeholder}
            </span>
          )}
        </span>
        <span className={styles.opts}>
          <span className={classNames(styles['opt-icon'], styles['arrow-icon'])}>
            <IconArrowBottom />
          </span>
          {canClear ? (
            <span
              className={classNames(styles['opt-icon'], styles['close-icon'])}
              onClick={handleClear}
            >
              <IconCloseCircleFilled />
            </span>
          ) : null}
        </span>
      </div>
    </Dropdown>
  )
}

function SelectOption({ value, children, onClick, className, disabled }: OptionProps) {
  return (
    <div
      className={classNames(
        styles['select-selection-item'],
        'flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis min-h-[32px] px-3 py-1 leading-[22px]',
        { 'cursor-pointer': !disabled },
        className
      )}
      onClick={() => {
        if (!disabled && typeof onClick === 'function') {
          onClick(value)
        }
      }}
    >
      {children}
    </div>
  )
}

Select.Option = SelectOption
