import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function CheckOutlined(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M905.19013822 184.72452846a63.82956789 63.82956789 0 0 1 94.63797225 85.40396185l-4.38296325 4.85104717-564.33848667 564.29593406a63.82956789 63.82956789 0 0 1-85.40396185 4.38296322l-4.85104714-4.38296322-312.29679878-312.25424614a63.82956789 63.82956789 0 0 1 85.44651447-94.68052614l4.85104714 4.38296325 267.14801856 267.14801858 519.14715266-519.14715263z"></path>
      </svg>
    </BaseIcon>
  )
}
