// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconEmail.module.less'

export default function IconEmail(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M943.51580062 152.40349948H80.48419938a71.91930012 71.91930012 0 0 0-71.91930011 70.48067998V801.11699109a71.91930012 71.91930012 0 0 0 21.09585983 50.82226974 70.00192033 70.00192033 0 0 0 48.90606048 19.65841025h863.03160126a71.91930012 71.91930012 0 0 0 71.91930011-71.9193001V224.3227996a71.91930012 71.91930012 0 0 0-70.00192033-71.91930012z m0 671.24680097H80.48419938a24.45303025 24.45303025 0 0 1-23.97310005-23.97310005V312.06457982l420.00918084 242.12831035a69.04205988 69.04205988 0 0 0 71.9193001 0l419.0493204-242.12831035v489.05124072a23.97310005 23.97310005 0 0 1-23.97310005 22.53447991z m23.97310005-566.7238507L523.98655 512.95868989a23.97310005 23.97310005 0 0 1-23.9731 0L56.51109933 256.92644975V222.88300891a23.97310005 23.97310005 0 0 1 23.97310005-22.53447991h863.03160124a23.97310005 23.97310005 0 0 1 23.97310005 23.97310002v31.16503006z"></path>
      </svg>
    </BaseIcon>
  )
}
