// lib
import React from 'react'
import classnames from 'classnames'
import { Link, useHistory } from 'react-router-dom'

// components
import { Dropdown, Menu } from '../../components'
import Nav from '../../businessMisc/Nav'
import UploadScriptTitle from '../../assets/upload-script-title.png'
import UploadScriptTitleActive from '../../assets/upload-script-title-active.png'

import ProjectNameIcon from '../../assets/project-name.png'
import ProjectNameBlueIcon from '../../assets/project-name-blue.png'
import ProjectNameWhiteIcon from '../../assets/project-name-white.png'
import profileIcon from '../../assets/icon-profile.svg'
import editIcon from '../../assets/icon-edit.svg'
import registerIcon from '../../assets/icon-register.svg'
import exitIcon from '../../assets/icon-exit.svg'

// styles
import styles from './Header.module.scss'

import { authorLogout } from '../../api/author'

import { useEnvContext, useCustomerEnvContext } from '../../context'

import Prefix from '../../constants/prefix'
import { IconArrowBottom } from '../../components/Icons'
import { AgreementType } from '../../constants'
import { getUseableUploadTypeNavList } from './constants'

export default function Header() {
  const history = useHistory()
  const { projectName } = useEnvContext()
  const { currentUser, submitSwitch, headerTheme } = useCustomerEnvContext()

  async function handleMenuClick({ key }: { key: string }) {
    if (key === 'contact') {
      history.push(`${Prefix.customerPrefix}/contact`)
    } else if (key === 'password') {
      history.push(`${Prefix.customerPrefix}/password`)
    } else if (key === 'register') {
      history.push(`${Prefix.customerPrefix}/agreement?type=${AgreementType.Register}`)
    } else if (key === 'logout') {
      await authorLogout()
      window.location.reload()
    }
  }

  const UPLOAD_TYPE_NAV_LIST = getUseableUploadTypeNavList(submitSwitch)

  function renderUpload(children: React.ReactElement) {
    return (
      <Dropdown
        overlayClassName="!bg-transparent rounded relative after:absolute after:block after:w-full after:h-4 after:left-0 after:-top-4"
        overlay={
          <div className="rounded bg-white border border-black py-1">
            {UPLOAD_TYPE_NAV_LIST.map(item => (
              <Link
                to={item.path}
                key={item.name}
                className="flex items-center px-3 h-8 hover:bg-brand-default/30 text-sm"
              >
                <img className="w-6 h-6 mr-1" src={item.img} alt="" />
                {item.name}
              </Link>
            ))}
          </div>
        }
      >
        {children}
      </Dropdown>
    )
  }

  const routes = [
    {
      path: `${Prefix.customerPrefix}`,
      name: '我的剧本',
      className: classnames({ 'text-white': headerTheme === 'topic' }),
    },
    {
      // path: `${Prefix.customerPrefix}/upload/edit`,
      className: styles['upload-item'],
      name: (active: boolean) => {
        if (active) {
          return renderUpload(
            <span className={styles['upload-active']}>
              <img src={UploadScriptTitleActive} alt="" />
            </span>
          )
        }
        return renderUpload(
          <span className={classnames(styles.upload, { [styles.active]: active })}>
            <img className={styles['upload-image']} src={UploadScriptTitle} alt="" />
          </span>
        )
      },
    },
  ]

  const logo = React.useMemo(() => {
    switch (headerTheme) {
      case 'default':
        return ProjectNameIcon
      case 'basic':
        return ProjectNameBlueIcon
      default:
        return ProjectNameWhiteIcon
    }
  }, [headerTheme])

  return (
    <div
      className={classnames('flex items-center justify-between h-32 px-12 pb-10', {
        'bg-gradient-to-br from-brand-default to-brand-deep': headerTheme === 'default',
        [styles['theme-basic']]: headerTheme === 'basic',
        [styles['theme-topic']]: headerTheme === 'topic',
        [styles['theme-playlet']]: headerTheme === 'playlet',
      })}
    >
      <a href={Prefix.customerPrefix}>
        <img className="h-6" src={logo} alt={projectName} />
      </a>
      <div className="flex items-center">
        <Nav routes={routes} />
        <Dropdown
          placement="bottomRight"
          overlay={
            <Menu onClick={handleMenuClick}>
              <Menu.Item className={styles.menuItem} key="contact">
                <span className={styles.icon}>
                  <img src={profileIcon} alt="" />
                </span>
                个人中心
              </Menu.Item>
              <Menu.Item className={styles.menuItem} key="password">
                <span className={styles.icon}>
                  <img src={editIcon} alt="" />
                </span>
                修改密码
              </Menu.Item>
              <Menu.Item className={styles.menuItem} key="register">
                <span className={styles.icon}>
                  <img src={registerIcon} alt="" />
                </span>
                用户注册协议
              </Menu.Item>
              <Menu.Item className={styles.menuItem} key="logout">
                <span className={styles.icon}>
                  <img src={exitIcon} alt="" />
                </span>
                退出登录
              </Menu.Item>
            </Menu>
          }
        >
          <span className="inline-flex items-center h-8 bg-white ml-4 rounded-full border border-black pr-4 cursor-pointer">
            <span className="align-middle text-lg ml-4 whitespace-nowrap">{currentUser.name}</span>
            <IconArrowBottom className="text-xs ml-2" />
          </span>
        </Dropdown>
      </div>
    </div>
  )
}
