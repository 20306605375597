import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import styles from './AuthorMenu.module.scss'
import Portal, { PortalHolder } from '../../../components/Portal'
import {
  IconAuthorUser,
  IconAuthorUserActive,
  IconPaper,
  IconPaperActive,
} from '../../../components/Icons'
import Prefix from '../../../constants/prefix'
import { Dropdown } from '../../../components'
import { AgreementType } from '../../../constants'
import { authorLogout } from '../../../api/author'
import { useCustomerEnvContext } from '../../../context'
import { getUseableUploadTypeNavList } from '../constants'

import UploadBtn from '../../../assets/wap/mobile-upload-btn.png'
import UploadBtnActive from '../../../assets/wap/mobile-upload-btn-active.png'

interface MenuItem {
  path?: string
  name?: string
  icon?: any
  activeIcon?: any
  active?(): boolean
  children?: { name: string; path?: string; onClick?(): void; img?: string }[]
  render?(active: boolean): React.ReactNode
}

export default function AuthorMenu() {
  const location = useLocation()
  const { currentUser, submitSwitch } = useCustomerEnvContext()

  const UPLOAD_TYPE_NAV_LIST = getUseableUploadTypeNavList(submitSwitch)

  const Menu: MenuItem[] = [
    {
      path: Prefix.customerPrefix,
      name: '剧本列表',
      icon: IconPaper,
      activeIcon: IconPaperActive,
      active: () => {
        return location.pathname === Prefix.customerPrefix
      },
    },
    {
      render(active) {
        if (active) return <img className="h-8" src={UploadBtnActive} alt="" />
        return <img className="h-8" src={UploadBtn} alt="" />
      },
      active() {
        return UPLOAD_TYPE_NAV_LIST.map(item => item.path.split('?')[0]).reduce<boolean>(
          (p, path) => {
            return p || location.pathname === path
          },
          false
        )
      },
      children: UPLOAD_TYPE_NAV_LIST.reverse(),
    },
    {
      name: currentUser.name,
      icon: IconAuthorUser,
      activeIcon: IconAuthorUserActive,
      active() {
        return [
          `${Prefix.customerPrefix}/contact`,
          `${Prefix.customerPrefix}/password`,
          `${Prefix.customerPrefix}/agreement`,
        ].reduce<boolean>((p, path) => p || location.pathname === path, false)
      },
      children: [
        {
          name: '个人中心',
          path: `${Prefix.customerPrefix}/contact`,
        },
        {
          name: '修改密码',
          path: `${Prefix.customerPrefix}/password`,
        },
        {
          name: '用户注册协议',
          path: `${Prefix.customerPrefix}/agreement?type=${AgreementType.Register}`,
        },
        {
          name: '退出登录',
          async onClick() {
            await authorLogout()
            window.location.reload()
          },
        },
      ],
    },
  ]

  return (
    <Portal holder={<PortalHolder height={58} />}>
      <footer className={styles.menu}>
        <nav className="flex items-center">
          {Menu.map(item => {
            const active = typeof item.active === 'function' && item.active()
            const content =
              typeof item.render === 'function' ? (
                item.render(active)
              ) : (
                <>
                  <span>{React.createElement(active ? item.activeIcon : item.icon)}</span>
                  <span>{item.name}</span>
                </>
              )
            if (!item.path) {
              if (item.children) {
                return (
                  <Dropdown
                    key={item.name}
                    overlayClassName="!bg-transparent rounded relative after:absolute after:block after:w-full after:h-4 after:left-0 after:-top-4"
                    trigger={['click']}
                    placement="topRight"
                    overlay={
                      <div className="rounded bg-white border border-black">
                        {item.children.map(child => {
                          const content = child.name
                          const childProps = {
                            className:
                              'flex items-center justify-center px-3 h-8 hover:bg-brand-default/30 text-sm',
                            key: child.name,
                          }
                          if (child.path) {
                            return (
                              <Link to={child.path} {...childProps}>
                                {content}
                              </Link>
                            )
                          }
                          return (
                            <div onClick={child.onClick} {...childProps}>
                              {content}
                            </div>
                          )
                        })}
                      </div>
                    }
                  >
                    <div key={item.name} className={styles.link}>
                      {content}
                    </div>
                  </Dropdown>
                )
              }
              return (
                <div key={item.name} className={styles.link}>
                  {content}
                </div>
              )
            }
            return (
              <Link key={item.name} className={styles.link} to={item.path}>
                {content}
              </Link>
            )
          })}
        </nav>
      </footer>
    </Portal>
  )
}
