export const TOPIC_UPLOAD_TYPE_MAP = {
  documentary: 'documentary',
  animation: 'animation',
  variety: 'variety',
  FATP: 'FATP',
} as const

export const TOPIC_UPLOAD_TYPE_LABEL_MAP = {
  [TOPIC_UPLOAD_TYPE_MAP.documentary]: '网络纪录片',
  [TOPIC_UPLOAD_TYPE_MAP.animation]: '动画',
  [TOPIC_UPLOAD_TYPE_MAP.variety]: '网络综艺',
  [TOPIC_UPLOAD_TYPE_MAP.FATP]: '剧集及电影',
} as const

const TOPIC_UPLOAD_FATP_TYPE_MAP = {
  drama: 'drama',
  film: 'film',
  playlet: 'playlet',
}

const TOPIC_UPLOAD_FATP_TYPE_LABEL_MAP = {
  [TOPIC_UPLOAD_FATP_TYPE_MAP.drama]: '网络剧',
  [TOPIC_UPLOAD_FATP_TYPE_MAP.film]: '网络电影',
  [TOPIC_UPLOAD_FATP_TYPE_MAP.playlet]: '网络微短剧',
}

export const TOPIC_UPLOAD_TYPE_LIST = [
  TOPIC_UPLOAD_TYPE_MAP.documentary,
  TOPIC_UPLOAD_TYPE_MAP.animation,
  TOPIC_UPLOAD_TYPE_MAP.variety,
  TOPIC_UPLOAD_TYPE_MAP.FATP,
] as const

export enum TopicScriptClassify {
  /** 系统 */
  EVENT = 'EVENT',
  /** 网络 */
  EVENT_NET = 'EVENT_NET',
  /** 影视 */
  EVENT_TV = 'EVENT_TV',
  /** 动画 */
  EVENT_CARTOON = 'EVENT_CARTOON',
}

export enum TopicRole {
  /** 管理员 */
  EVENT_ADMIN = 'EVENT_ADMIN',
  /** 预审人 */
  EVENT_PRE_EXAMINER = 'EVENT_PRE_EXAMINER',
  /** 异动处理人 */
  EVENT_ALERT_USER = 'EVENT_ALERT_USER',
  /** 初审人-青工委 */
  EVENT_QGW_ROUND_A_VOTER = 'EVENT_QGW_ROUND_A_VOTER',
  /** 初审人-北京局 */
  EVENT_BJ_ROUND_A_VOTER = 'EVENT_BJ_ROUND_A_VOTER',
  /** 终审人-青工委 */
  EVENT_QGW_ROUND_B_VOTER = 'EVENT_QGW_ROUND_B_VOTER',
  /** 终审人-北京局 */
  EVENT_BJ_ROUND_B_VOTER = 'EVENT_BJ_ROUND_B_VOTER',
}

export enum TopicVoteRound {
  Prev = 'P',
  First = 'A1',
  Second = 'A2',
  Last = 'B',
}

export const TOPIC_VOTE_ROUND_LABEL: Record<TopicVoteRound, string> = {
  [TopicVoteRound.Prev]: '预审',
  [TopicVoteRound.First]: '一轮初评',
  [TopicVoteRound.Second]: '二轮初评',
  [TopicVoteRound.Last]: '复评',
}

export const TOPIC_VOTE_ROUND_LIST = [
  {
    value: TopicVoteRound.Prev,
    label: TOPIC_VOTE_ROUND_LABEL[TopicVoteRound.Prev],
  },
  {
    value: TopicVoteRound.First,
    label: TOPIC_VOTE_ROUND_LABEL[TopicVoteRound.First],
  },
  {
    value: TopicVoteRound.Second,
    label: TOPIC_VOTE_ROUND_LABEL[TopicVoteRound.Second],
  },
  {
    value: TopicVoteRound.Last,
    label: TOPIC_VOTE_ROUND_LABEL[TopicVoteRound.Last],
  },
]

export const TopicClassifyRole = {
  EVENT__EVENT_ADMIN: 'EVENT__EVENT_ADMIN',
  EVENT__EVENT_PRE_EXAMINER: 'EVENT__EVENT_PRE_EXAMINER',
  EVENT__EVENT_ALERT_USER: 'EVENT__EVENT_ALERT_USER',
  EVENT_NET__EVENT_QGW_ROUND_A_VOTER: 'EVENT_NET__EVENT_QGW_ROUND_A_VOTER',
  EVENT_NET__EVENT_BJ_ROUND_A_VOTER: 'EVENT_NET__EVENT_BJ_ROUND_A_VOTER',
  EVENT_NET__EVENT_QGW_ROUND_B_VOTER: 'EVENT_NET__EVENT_QGW_ROUND_B_VOTER',
  EVENT_NET__EVENT_BJ_ROUND_B_VOTER: 'EVENT_NET__EVENT_BJ_ROUND_B_VOTER',
  EVENT_TV__EVENT_QGW_ROUND_A_VOTER: 'EVENT_TV__EVENT_QGW_ROUND_A_VOTER',
  EVENT_TV__EVENT_BJ_ROUND_A_VOTER: 'EVENT_TV__EVENT_BJ_ROUND_A_VOTER',
  EVENT_TV__EVENT_QGW_ROUND_B_VOTER: 'EVENT_TV__EVENT_QGW_ROUND_B_VOTER',
  EVENT_TV__EVENT_BJ_ROUND_B_VOTER: 'EVENT_TV__EVENT_BJ_ROUND_B_VOTER',
  EVENT_CARTOON__EVENT_QGW_ROUND_A_VOTER: 'EVENT_CARTOON__EVENT_QGW_ROUND_A_VOTER',
  EVENT_CARTOON__EVENT_BJ_ROUND_A_VOTER: 'EVENT_CARTOON__EVENT_BJ_ROUND_A_VOTER',
  EVENT_CARTOON__EVENT_QGW_ROUND_B_VOTER: 'EVENT_CARTOON__EVENT_QGW_ROUND_B_VOTER',
  EVENT_CARTOON__EVENT_BJ_ROUND_B_VOTER: 'EVENT_CARTOON__EVENT_BJ_ROUND_B_VOTER',
} as const

const TOPIC_CLASSIFY_ROLE_LABEL = {
  EVENT__EVENT_ADMIN: '管理员',
  EVENT__EVENT_PRE_EXAMINER: '预审',
  EVENT__EVENT_ALERT_USER: '异动处理',
  EVENT_NET__EVENT_QGW_ROUND_A_VOTER: '网络初评-青工委',
  EVENT_NET__EVENT_BJ_ROUND_A_VOTER: '网络初评-北京局',
  EVENT_NET__EVENT_QGW_ROUND_B_VOTER: '网络复评-青工委',
  EVENT_NET__EVENT_BJ_ROUND_B_VOTER: '网络复评-北京局',
  EVENT_TV__EVENT_QGW_ROUND_A_VOTER: '影视初评-青工委',
  EVENT_TV__EVENT_BJ_ROUND_A_VOTER: '影视初评-北京局',
  EVENT_TV__EVENT_QGW_ROUND_B_VOTER: '影视复评-青工委',
  EVENT_TV__EVENT_BJ_ROUND_B_VOTER: '影视复评-北京局',
  EVENT_CARTOON__EVENT_QGW_ROUND_A_VOTER: '动画初评-青工委',
  EVENT_CARTOON__EVENT_BJ_ROUND_A_VOTER: '动画初评-北京局',
  EVENT_CARTOON__EVENT_QGW_ROUND_B_VOTER: '动画复评-青工委',
  EVENT_CARTOON__EVENT_BJ_ROUND_B_VOTER: '动画复评-北京局',
}

export type TopicClassifyRoleType = {
  label: string
  classify: TopicScriptClassify
  value: ValueOf<typeof TopicClassifyRole>
  role: TopicRole
  multiple: boolean
}

export const TOPIC_CLASSIFY_ROLE_LIST: TopicClassifyRoleType[] = [
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT__EVENT_ADMIN],
    classify: TopicScriptClassify.EVENT,
    value: TopicClassifyRole.EVENT__EVENT_ADMIN,
    role: TopicRole.EVENT_ADMIN,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT__EVENT_PRE_EXAMINER],
    classify: TopicScriptClassify.EVENT,
    value: TopicClassifyRole.EVENT__EVENT_PRE_EXAMINER,
    role: TopicRole.EVENT_PRE_EXAMINER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT__EVENT_ALERT_USER],
    classify: TopicScriptClassify.EVENT,
    value: TopicClassifyRole.EVENT__EVENT_ALERT_USER,
    role: TopicRole.EVENT_ALERT_USER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_NET__EVENT_QGW_ROUND_A_VOTER],
    classify: TopicScriptClassify.EVENT_NET,
    value: TopicClassifyRole.EVENT_NET__EVENT_QGW_ROUND_A_VOTER,
    role: TopicRole.EVENT_QGW_ROUND_A_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_NET__EVENT_BJ_ROUND_A_VOTER],
    classify: TopicScriptClassify.EVENT_NET,
    value: TopicClassifyRole.EVENT_NET__EVENT_BJ_ROUND_A_VOTER,
    role: TopicRole.EVENT_BJ_ROUND_A_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_NET__EVENT_QGW_ROUND_B_VOTER],
    classify: TopicScriptClassify.EVENT_NET,
    value: TopicClassifyRole.EVENT_NET__EVENT_QGW_ROUND_B_VOTER,
    role: TopicRole.EVENT_QGW_ROUND_B_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_NET__EVENT_BJ_ROUND_B_VOTER],
    classify: TopicScriptClassify.EVENT_NET,
    value: TopicClassifyRole.EVENT_NET__EVENT_BJ_ROUND_B_VOTER,
    role: TopicRole.EVENT_BJ_ROUND_B_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_TV__EVENT_QGW_ROUND_A_VOTER],
    classify: TopicScriptClassify.EVENT_TV,
    value: TopicClassifyRole.EVENT_TV__EVENT_QGW_ROUND_A_VOTER,
    role: TopicRole.EVENT_QGW_ROUND_A_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_TV__EVENT_BJ_ROUND_A_VOTER],
    classify: TopicScriptClassify.EVENT_TV,
    value: TopicClassifyRole.EVENT_TV__EVENT_BJ_ROUND_A_VOTER,
    role: TopicRole.EVENT_BJ_ROUND_A_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_TV__EVENT_QGW_ROUND_B_VOTER],
    classify: TopicScriptClassify.EVENT_TV,
    value: TopicClassifyRole.EVENT_TV__EVENT_QGW_ROUND_B_VOTER,
    role: TopicRole.EVENT_QGW_ROUND_B_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_TV__EVENT_BJ_ROUND_B_VOTER],
    classify: TopicScriptClassify.EVENT_TV,
    value: TopicClassifyRole.EVENT_TV__EVENT_BJ_ROUND_B_VOTER,
    role: TopicRole.EVENT_BJ_ROUND_B_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_CARTOON__EVENT_QGW_ROUND_A_VOTER],
    classify: TopicScriptClassify.EVENT_CARTOON,
    value: TopicClassifyRole.EVENT_CARTOON__EVENT_QGW_ROUND_A_VOTER,
    role: TopicRole.EVENT_QGW_ROUND_A_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_CARTOON__EVENT_BJ_ROUND_A_VOTER],
    classify: TopicScriptClassify.EVENT_CARTOON,
    value: TopicClassifyRole.EVENT_CARTOON__EVENT_BJ_ROUND_A_VOTER,
    role: TopicRole.EVENT_BJ_ROUND_A_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_CARTOON__EVENT_QGW_ROUND_B_VOTER],
    classify: TopicScriptClassify.EVENT_CARTOON,
    value: TopicClassifyRole.EVENT_CARTOON__EVENT_QGW_ROUND_B_VOTER,
    role: TopicRole.EVENT_QGW_ROUND_B_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.EVENT_CARTOON__EVENT_BJ_ROUND_B_VOTER],
    classify: TopicScriptClassify.EVENT_CARTOON,
    value: TopicClassifyRole.EVENT_CARTOON__EVENT_BJ_ROUND_B_VOTER,
    role: TopicRole.EVENT_BJ_ROUND_B_VOTER,
    multiple: true,
  },
]

export const TOPIC_TYPE_NAME_STYLE: Record<string, React.CSSProperties> = {
  [TOPIC_UPLOAD_TYPE_LABEL_MAP.documentary]: {
    color: '#E0E7FF',
    backgroundColor: '#4E3EE9',
  },
  [TOPIC_UPLOAD_TYPE_LABEL_MAP.animation]: {
    color: '#4D7C0F',
    backgroundColor: '#D9F99D',
  },
  [TOPIC_UPLOAD_TYPE_LABEL_MAP.variety]: {
    color: '#E0E7FF',
    backgroundColor: '#1F5EEF',
  },
  [TOPIC_UPLOAD_TYPE_LABEL_MAP.FATP]: {
    color: '#FCE7F3',
    backgroundColor: '#DD2077',
  },
  [TOPIC_UPLOAD_FATP_TYPE_LABEL_MAP.drama]: {
    color: '#F759AC',
    backgroundColor: '#FEE5F2',
  },
  [TOPIC_UPLOAD_FATP_TYPE_LABEL_MAP.film]: {
    color: '#4078FF',
    backgroundColor: '#E1EAFF',
  },
  [TOPIC_UPLOAD_FATP_TYPE_LABEL_MAP.playlet]: {
    color: '#DD9B05',
    backgroundColor: '#FAF1D1',
  },
}

export enum TopicScriptStep {
  /** 草稿 */
  ROUND_DRAFT = 'ROUND_DRAFT',
  /** 待预审 */
  ROUND_P_WAITING = 'ROUND_P_WAITING',
  /** 预审中 */
  ROUND_P_PROCESS = 'ROUND_P_PROCESS',
  /** 预审淘汰 */
  ROUND_P_FAILED = 'ROUND_P_FAILED',
  /** 待初评 */
  ROUND_A_WAITING = 'ROUND_A_WAITING',
  /** 初评中 */
  ROUND_A_PROCESS = 'ROUND_A_PROCESS',
  /** 初评完成 */
  ROUND_A_END = 'ROUND_A_END',
  /** 管理员操作淘汰||正常评审淘汰 */
  ROUND_A_FAILED = 'ROUND_A_FAILED',
  /** 上传剧本 */
  ROUND_AUTHOR_PROCESS = 'ROUND_AUTHOR_PROCESS',
  /** 待复评 */
  ROUND_B_WAITING = 'ROUND_B_WAITING',
  /** 复评中 */
  ROUND_B_PROCESS = 'ROUND_B_PROCESS',
  /** 复评完成 */
  ROUND_B_END = 'ROUND_B_END',
  /** 复评通过 */
  ROUND_B_SUCCESS = 'ROUND_B_SUCCESS',
  /** 复评淘汰 */
  ROUND_B_FAILED = 'ROUND_B_FAILED',
  /** 终止淘汰 */
  TERMINATE_FAILED = 'TERMINATE_FAILED',
}

export const TopicScriptStepLabel = {
  [TopicScriptStep.ROUND_DRAFT]: '草稿',
  [TopicScriptStep.ROUND_P_WAITING]: '待预审',
  [TopicScriptStep.ROUND_P_PROCESS]: '预审中',
  [TopicScriptStep.ROUND_P_FAILED]: '预审未通过',
  [TopicScriptStep.ROUND_A_WAITING]: '待初评',
  [TopicScriptStep.ROUND_A_PROCESS]: '初评中',
  [TopicScriptStep.ROUND_A_END]: '初评完成',
  [TopicScriptStep.ROUND_A_FAILED]: '初评未通过',
  [TopicScriptStep.ROUND_AUTHOR_PROCESS]: '上传剧本',
  [TopicScriptStep.ROUND_B_WAITING]: '待复评',
  [TopicScriptStep.ROUND_B_PROCESS]: '复评中',
  [TopicScriptStep.ROUND_B_END]: '复评完成',
  [TopicScriptStep.ROUND_B_SUCCESS]: '复评通过',
  [TopicScriptStep.ROUND_B_FAILED]: '复评未通过',
  [TopicScriptStep.TERMINATE_FAILED]: '终止未通过',
}

export const TOPIC_SCRIPT_STEP_LIST = [
  {
    value: TopicScriptStep.ROUND_DRAFT,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_DRAFT],
  },
  {
    value: TopicScriptStep.ROUND_P_WAITING,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_P_WAITING],
  },
  {
    value: TopicScriptStep.ROUND_P_PROCESS,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_P_PROCESS],
  },
  {
    value: TopicScriptStep.ROUND_P_FAILED,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_P_FAILED],
  },
  {
    value: TopicScriptStep.ROUND_A_WAITING,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_A_WAITING],
  },
  {
    value: TopicScriptStep.ROUND_A_PROCESS,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_A_PROCESS],
  },
  {
    value: TopicScriptStep.ROUND_A_END,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_A_END],
  },
  {
    value: TopicScriptStep.ROUND_A_FAILED,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_A_FAILED],
  },
  {
    value: TopicScriptStep.ROUND_AUTHOR_PROCESS,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_AUTHOR_PROCESS],
  },
  {
    value: TopicScriptStep.ROUND_B_WAITING,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_B_WAITING],
  },
  {
    value: TopicScriptStep.ROUND_B_PROCESS,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_B_PROCESS],
  },
  {
    value: TopicScriptStep.ROUND_B_END,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_B_END],
  },
  {
    value: TopicScriptStep.ROUND_B_SUCCESS,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_B_SUCCESS],
  },
  {
    value: TopicScriptStep.ROUND_B_FAILED,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_B_FAILED],
  },
  // {
  //   value: TopicScriptStep.TERMINATE_FAILED,
  //   label: TopicScriptStepLabel[TopicScriptStep.TERMINATE_FAILED],
  // },
]

export enum TopicApplyRound {
  First = 'A',
  Last = 'B',
}

const TOPIC_APPLY_ROUND = {
  [TopicApplyRound.First]: '初评',
  [TopicApplyRound.Last]: '复评',
}

const TOPIC_APPLY_ROUND_LIST = [
  {
    label: TOPIC_APPLY_ROUND[TopicApplyRound.First],
    value: TopicApplyRound.First,
  },
  {
    label: TOPIC_APPLY_ROUND[TopicApplyRound.Last],
    value: TopicApplyRound.Last,
  },
]

export const TOPIC_APPLY_ROUND_FULL_LIST = [{ label: '全部', value: '' }, ...TOPIC_APPLY_ROUND_LIST]

export const TOPIC_REPORT_SORT_LIST = [
  { value: 'DESC_createTime', label: '提交时间倒序' },
  { value: 'ASC_createTime', label: '提交时间正序' },
  { value: 'DESC_roundAEndTime', label: '初审时间倒序' },
  { value: 'ASC_roundAEndTime', label: '初审时间正序' },
  { value: 'DESC_roundBEndTime', label: '终审时间倒序' },
  { value: 'ASC_roundBEndTime', label: '终审时间正序' },
  { value: 'DESC_roundAScore', label: '初评分数倒序' },
  { value: 'ASC_roundAScore', label: '初评分数正序' },
  { value: 'DESC_roundBScore', label: '复评分数倒序' },
  { value: 'ASC_roundBScore', label: '复评分数正序' },
]

export const TOPIC_INVITATION_CODES = ['f1761ace']
