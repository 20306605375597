import useAdminRole from './useAdminRole'

export default function useAdminPageWithRole() {
  const {
    isVoter,
    isPartner,
    isAdmin,
    isReportAdmin,
    isTopicReportAdmin,
    isTopic2023ReportAdmin,
    isPlayletReportAdmin,
  } = useAdminRole()

  const PageRole = {
    /** 待办 */
    UNDO: isVoter,
    /** 已办 */
    DONE: isVoter,
    /** 平台用户-项目列表 */
    PLATFORM_SCRIPTS: isPartner || isAdmin,
    /** 统计 */
    REPORT: isReportAdmin,
    /** 中国梦统计 */
    TOPIC_REPORT: isTopicReportAdmin,
    /** 中国梦统计2023 */
    TOPIC_2023_REPORT: isTopic2023ReportAdmin,
    /** 微短剧统计 */
    PLAYLET_REPORT: isPlayletReportAdmin,
    /** 其他报表 */
    REPORT_OTHER: isAdmin,
    /** 作者列表 */
    AUTHORS: isAdmin,
    /** 协议设置 */
    AGREEMENT: isAdmin,
    /** 设置 */
    SETTING: isAdmin,
  }

  return PageRole
}
