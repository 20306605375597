// libs
import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'

// constants
import Codes from '../constants/code'
import { goToLogin } from '../utils'
import message from '../utils/message'

const { NODE_ENV } = process.env
const isDev = NODE_ENV === 'development'

function catchUnauthorized(response: AxiosResponse<any>) {
  const { pathname } = window.location
  if (/\/login$/.test(pathname)) {
    // 登录页抓取数据不做401跳转
    return Promise.reject(response)
  }
  goToLogin()
  return Promise.reject(response)
}

function requestError(error: any) {
  // eslint-disable-next-line
  console.log(error)
  return Promise.reject(error)
}

function afterReceiveResponse(response: AxiosResponse<any>) {
  const { data } = response
  const { code } = data

  if (code && code > 0) {
    switch (code) {
      case Codes.SERVER_UNAUTHORIZED:
        return catchUnauthorized(response)
      case Codes.SERVER_RUNTIME_ERROR:
        message.warn('系统出错，请重试或联系系统管理员')
        break
      case Codes.SERVER_OPERATE_REJECT:
        message.warn(data.message || '操作被拒绝，请重试或联系系统管理员')
        break
      case Codes.SERVER_FORBIDDEN:
        message.warn(data.message || '您没有权限执行该操作')
        break
      default:
        message.warn(data.message)
        break
    }

    return Promise.reject(response)
  }

  return response
}

export const XMLConfig = {
  headers: {
    'content-type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: isDev,
}

const managerAPI = axios.create({
  baseURL: isDev ? '/api' : '',
  ...XMLConfig,
})

managerAPI.interceptors.response.use(afterReceiveResponse, requestError)

export default {
  get: <T>(url: string, config?: AxiosRequestConfig) => managerAPI.get<T>(url, config),
  post: <T>(url: string, data?: any, config?: AxiosRequestConfig) =>
    managerAPI.post<T>(url, data, config),
}

export async function parseRequest<T>(response: AxiosPromise<R<T>>) {
  const resp = await response
  return resp.data.data
}
