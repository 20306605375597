import classNames from 'classnames'

import { IconArrowLeftFine, IconArrowRightFine } from '../../components/Icons'
import styles from './MobilePagination.module.scss'

type MobilePaginationProps = {
  style?: React.CSSProperties
  className?: string
  pageNo: number
  totalPages: number
  onChange?: (pageNo: number) => void
}

export default function MobilePagination({
  style,
  className,
  pageNo,
  totalPages,
  onChange = () => {},
}: MobilePaginationProps) {
  if (totalPages < 2) {
    return null
  }
  return (
    <div style={style} className={classNames(styles['mobile-pagination'], className)}>
      <span
        className={classNames(styles.arrow, {
          [styles.disabled]: pageNo < 2,
        })}
        onClick={pageNo < 2 ? undefined : () => onChange(pageNo - 1)}
      >
        <IconArrowLeftFine />
      </span>
      <span className={styles.nav}>
        <em>{pageNo}</em> / {totalPages}
      </span>
      <span
        className={classNames(styles.arrow, {
          [styles.disabled]: pageNo >= totalPages,
        })}
        onClick={pageNo >= totalPages ? undefined : () => onChange(pageNo + 1)}
      >
        <IconArrowRightFine />
      </span>
    </div>
  )
}
