// lib
import React from 'react'
import classnames from 'classnames'

// components
import Overlay, { OverlayContext, OverlaCommonType } from '../basic/Overlay'

// styles
import styles from './Dropdown.module.scss'

type Props = {
  disabled?: boolean
  children: React.ReactElement
  overlay: React.ReactElement
  trigger?: Array<'click' | 'hover'>
  clickOverlayClose?: boolean
  overlayClassName?: string
} & OverlaCommonType

type RefType = {
  setActive: React.Dispatch<React.SetStateAction<boolean>>
}

const Dropdown = React.forwardRef<RefType, Props>(
  (
    {
      children,
      overlay,
      trigger = ['hover'],
      disabled,
      placement = 'bottomLeft',
      clickOverlayClose = true,
      getPopupContainer,
      overlayClassName,
    },
    ref
  ) => {
    const [active, setActive] = React.useState(false)
    const childrenRef = React.useRef<HTMLElement>(null)

    React.useImperativeHandle(ref, () => {
      return {
        setActive,
      }
    })

    const updateActive = React.useCallback(
      (status: any) => {
        if (disabled) {
          return
        }
        setActive(status)
      },
      [disabled]
    )

    function onMouseEnter() {
      if (trigger.includes('hover')) {
        updateActive(true)
      }
    }

    function onMouseLeave() {
      if (trigger.includes('hover')) {
        updateActive(false)
      }
    }

    function onClick() {
      if (trigger.includes('click')) {
        updateActive((prevStatus: boolean) => !prevStatus)
      }
    }

    return (
      <OverlayContext.Provider
        value={{
          active,
          setActive,
        }}
      >
        {React.cloneElement(children, {
          ref: childrenRef,
          disabled,
          onMouseEnter,
          onMouseLeave,
          onClick,
        })}
        <Overlay
          className={classnames(styles.overlay, overlayClassName)}
          placement={placement}
          childrenRef={childrenRef}
          getStyle={() => ({
            minWidth: childrenRef.current?.offsetWidth,
          })}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={e => {
            e.stopPropagation()
            // 点击overlay后关闭
            if (clickOverlayClose) {
              updateActive(false)
            }
          }}
          getPopupContainer={getPopupContainer}
        >
          {overlay}
        </Overlay>
      </OverlayContext.Provider>
    )
  }
)

export default Dropdown
