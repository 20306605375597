import Prefix from '../constants/prefix'
import AsyncComponent from './AsyncComponent'

const adminTopicRoutes = [
  {
    path: '/vote/:voteId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-topic-2023-vote" */ '../$$adminTopic2023/Vote')
    ),
  },
  {
    path: '/script/:scriptId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-topic-2023-script" */ '../$$adminTopic2023/Script')
    ),
  },
  {
    path: '/vote/result/:voteId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () =>
        import(/* webpackChunkName: "admin-topic-2023-vote-result" */ '../$$adminTopic2023/Vote')
    ),
  },
  {
    path: '/report',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-topic-2023-report" */ '../$$adminTopic2023/Report')
    ),
  },
  {
    path: '/report/full',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-topic-2023-full-report" */ '../$$adminTopic2023/Report/full-report'
        )
    ),
  },
  {
    path: '/export',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-topic-2023-export" */ '../$$adminTopic2023/Export')
    ),
  },
  {
    path: '/task/report',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-topic-2023-task-report" */ '../$$adminTopic2023/other-report/TaskReport'
        )
    ),
  },
  {
    path: '/audit/report',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-topic-2023-audit-report" */ '../$$adminTopic2023/other-report/AuditReport'
        )
    ),
  },
]

export default [
  ...adminTopicRoutes.map(item => ({
    ...item,
    path: `${Prefix.adminTopic2023Prefix}${item.path}`,
  })),
]
