import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconStat(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
        <path d="M49.526 4.86c24.668 0 44.666 19.998 44.666 44.666a2.913 2.913 0 1 1-5.826 0c0-21.45-17.39-38.84-38.84-38.84-21.45 0-38.84 17.39-38.84 38.84 0 21.45 17.39 38.84 38.84 38.84 11.35 0 21.9-4.892 29.233-13.267a2.913 2.913 0 1 1 4.384 3.837c-8.428 9.626-20.57 15.256-33.617 15.256-24.668 0-44.666-19.998-44.666-44.666C4.86 24.858 24.858 4.86 49.526 4.86zm42.42 50.57a2.913 2.913 0 0 1 2.168 3.502 37.424 37.424 0 0 1-.868 3.086 39.185 39.185 0 0 1-3.33 7.416 2.913 2.913 0 1 1-5.043-2.918 33.361 33.361 0 0 0 2.837-6.313c.282-.86.527-1.73.733-2.606a2.913 2.913 0 0 1 3.503-2.168z" />
        <path d="M48.555 4.86a2.913 2.913 0 0 1 2.9 2.633l.013.28v39.811h39.81a2.913 2.913 0 0 1 2.9 2.632l.014.281a2.913 2.913 0 0 1-2.633 2.9l-.28.013H49.933c-2.42 0-4.142-2.012-4.282-4.32l-.009-.302V7.773a2.913 2.913 0 0 1 2.913-2.913z" />
      </svg>
    </BaseIcon>
  )
}
