// lib
import React from 'react'
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// components

// styles
// import styles from './TrumpetOutlined.module.scss'

export default function TrumpetOutlined(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M608 932.2666667c-10.6496 0-19.2-2.1504-29.8496-4.2496l-6.4-2.1504L345.6 791.4666667c-19.2-4.2496-51.2-10.6496-66.1504-10.6496h-4.2496l-164.2496-19.2c-49.1008 0-89.6-42.7008-89.6-91.7504V371.2170667c0-49.1008 40.4992-89.6 89.6-91.7504L296.5504 253.8666667c21.2992 0 42.6496-4.2496 64-10.6496l211.2-151.5008 4.2496-2.0992A88.8832 88.8832 0 0 1 659.2 100.2666667c23.4496 17.0496 38.4 44.8 38.4 74.6496v663.5008c0 29.8496-14.9504 57.6-38.4 74.6496-12.8 12.8-32 19.2-51.2 19.2z m-6.4-66.1504c8.5504 2.1504 14.9504 0 21.3504-4.2496a26.2656 26.2656 0 0 0 10.6496-21.3504V177.0666667a34.304 34.304 0 0 0-10.6496-23.4496c-6.4-4.3008-14.9504-6.4-21.3504-4.3008L392.5504 300.8170667l-4.3008 2.0992a243.4048 243.4048 0 0 1-87.4496 14.9504l-185.6 25.6a28.2624 28.2624 0 0 0-27.7504 27.7504v296.4992c0 14.9504 12.8 27.7504 27.7504 27.7504h4.2496L283.7504 714.6666667c29.8496 0 78.8992 12.8 83.2 14.9504l4.2496 2.0992 230.4 134.4zM857.6 810.6666667a36.4544 36.4544 0 0 1-27.7504-14.9504 34.1504 34.1504 0 0 1 10.7008-44.8c66.0992-40.4992 110.8992-140.8 110.8992-249.6 0-117.2992-51.2-221.8496-123.6992-256-17.1008-6.4-23.5008-25.6-14.9504-42.6496 8.5504-17.0496 25.6-23.4496 42.6496-14.9504 96 44.8 160 170.7008 160 313.6 0 132.3008-55.4496 251.7504-140.8 302.9504a24.2176 24.2176 0 0 1-17.0496 6.4z"></path>
      </svg>
    </BaseIcon>
  )
}
