import Prefix from '../constants/prefix'
import AsyncComponent from './AsyncComponent'

const adminTopicRoutes = [
  {
    path: '/vote/:voteId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-topic-vote" */ '../$$adminTopic/Vote')
    ),
  },
  {
    path: '/script/:scriptId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-topic-script" */ '../$$adminTopic/Script')
    ),
  },
  {
    path: '/vote/result/:voteId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-topic-vote-result" */ '../$$adminTopic/Vote')
    ),
  },
  {
    path: '/report',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-topic-report" */ '../$$adminTopic/Report')
    ),
  },
  {
    path: '/export',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-topic-export" */ '../$$adminTopic/Export')
    ),
  },
  {
    path: '/task/report',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-topic-task-report" */ '../$$adminTopic/other-report/TaskReport'
        )
    ),
  },
  {
    path: '/audit/report',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-topic-audit-report" */ '../$$adminTopic/other-report/AuditReport'
        )
    ),
  },
  {
    path: '/setting',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-topic-setting" */ '../$$adminTopic/Setting')
    ),
  },
]

export default [
  ...adminTopicRoutes.map(item => ({
    ...item,
    path: `${Prefix.adminTopicPrefix}${item.path}`,
  })),
]
