import { useState, useEffect } from 'react'

/**
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia)
 * @param mediaQuery
 */
export default function useMediaQuery(mediaQuery: string) {
  if (!('matchMedia' in window)) {
    throw new Error('浏览器版本过低，不支持window.matchMedia')
  }

  const [isVerified, setIsVerified] = useState(window.matchMedia(mediaQuery).matches)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    const documentChangeHandler = () => setIsVerified(mediaQueryList.matches)
    mediaQueryList.addListener(documentChangeHandler)
    documentChangeHandler()
    return () => {
      mediaQueryList.removeListener(documentChangeHandler)
    }
  }, [mediaQuery])

  return isVerified
}
