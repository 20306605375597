import qs from 'query-string'
import Prefix from '../constants/prefix'

export { v4 as uuidv4 } from 'uuid'

export function goToLogin() {
  const { pathname, search } = window.location
  const originalUrl = encodeURIComponent(pathname + search)
  window.location.replace(`/login?originalUrl=${originalUrl}`)
}

export function parseFileName(name: string): [string] | [string, string] {
  const matchArr = name.match(/^(.+)\.(.+)$/)
  if (matchArr) {
    const [, filename, suffix] = matchArr
    return [filename, suffix]
  }
  return [name]
}

export function isEmpty(val: any) {
  return val === undefined || val === null || val === ''
}

export function matchScore(score: number) {
  const map = ['D', 'C', 'B', 'A', 'S']

  if (score < 10 || score > 55) {
    return null
  }

  return {
    code: map[Math.floor(score / 10) - 1],
    plus: score % 10 >= 5,
  }
}

export function scroll(element: HTMLElement | Window, top: number, left: number) {
  if (element.scrollTo) {
    element.scrollTo(left, top)
  } else if ('scrollLeft' in element) {
    element.scrollLeft = left
    element.scrollTop = top
  }
}

export function getLocationSearch(extraProps = {}): Record<string, any> {
  const params = qs.parse(window.location.search)

  // url参数覆盖传入的参数
  return {
    ...extraProps,
    ...params,
  }
}

/**
 * 过滤某（个/些）key
 * @param params
 * @param keys
 */
export function restObject(
  params: Record<string, any>,
  keys: string | string[]
): Record<string, any> {
  return Object.keys(params).reduce((prevParams, key) => {
    const arr = typeof keys === 'string' ? [keys] : keys
    if (!arr.includes(key)) {
      return { ...prevParams, [key]: params[key] }
    }
    return prevParams
  }, {})
}

export function removeSearchParams(keys: string | string[]) {
  const query = restObject(qs.parse(window.location.search), keys)
  const str = Object.keys(query).reduce((prevStr, key) => {
    if (!prevStr) {
      return `${key}=${query[key]}`
    }
    return prevStr + `&${key}=${query[key]}`
  }, '')
  if (!str) {
    return window.location.pathname
  }
  return window.location.pathname + `?${str}`
}

export function getImageInfo(url: string) {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const image = new Image()
    image.src = url
    image.onload = () => {
      resolve({ width: image.width, height: image.height })
    }
    image.onerror = reject
  })
}

/**
 * 移动数组
 * @param list 原数组
 * @param fromIndex 初始位置
 * @param toIndex 移动位置
 */
export function arrayMove<T>(list: T[], fromIndex: number, toIndex: number) {
  const fromItem = list[fromIndex]
  const toItem = list[toIndex]
  return list.map((item, index) => {
    if (index === fromIndex) {
      return toItem
    }
    if (index === toIndex) {
      return fromItem
    }
    return item
  })
}

/**
 * 获取列表最大的某个字段值
 * @param list 原数组
 * @param name 字段名
 */
export function getMaxNo<T extends Record<string, any>>(list: T[], name: keyof T = 'sortNo') {
  return list.reduce((maxNo, item) => {
    if (item[name] > maxNo) {
      return item[name]
    }
    return maxNo
  }, 0)
}

export function getBreadcrumbRoute(prev?: string) {
  const routerMap: Record<string, { path: string; breadcrumbName: string }> = {
    author: { path: `${Prefix.adminPrefix}/authors`, breadcrumbName: '作者中心' },
    report: { path: `${Prefix.adminPrefix}/report`, breadcrumbName: '统计' },
    recommend: { path: `${Prefix.adminPrefix}/recommend/report`, breadcrumbName: '已推荐项目' },
    'task-report': { path: `${Prefix.adminPrefix}/task/report`, breadcrumbName: '审核时间' },
    'time-report': { path: `${Prefix.adminPrefix}/time/report`, breadcrumbName: '时间统计' },
  }
  return prev && routerMap[prev]
    ? routerMap[prev]
    : { path: Prefix.adminPrefix, breadcrumbName: '首页' }
}

export function getTopicBreadcrumbRoute(prev?: string) {
  const routerMap: Record<string, { path: string; breadcrumbName: string }> = {
    author: { path: `${Prefix.adminPrefix}/authors`, breadcrumbName: '作者中心' },
    report: { path: `${Prefix.adminTopicPrefix}/report`, breadcrumbName: '统计' },
    'task-report': { path: `${Prefix.adminTopicPrefix}/task/report`, breadcrumbName: '审核时间' },
  }
  return prev && routerMap[prev]
    ? routerMap[prev]
    : { path: Prefix.adminPrefix, breadcrumbName: '首页' }
}

export function getTopic2023BreadcrumbRoute(prev?: string) {
  const routerMap: Record<string, { path: string; breadcrumbName: string }> = {
    author: { path: `${Prefix.adminPrefix}/authors`, breadcrumbName: '作者中心' },
    report: { path: `${Prefix.adminTopic2023Prefix}/report`, breadcrumbName: '统计' },
    'task-report': {
      path: `${Prefix.adminTopic2023Prefix}/task/report`,
      breadcrumbName: '审核时间',
    },
  }
  return prev && routerMap[prev]
    ? routerMap[prev]
    : { path: Prefix.adminPrefix, breadcrumbName: '首页' }
}

export function getPlayletBreadcrumbRoute(prev?: string) {
  const routerMap: Record<string, { path: string; breadcrumbName: string }> = {
    author: { path: `${Prefix.adminPrefix}/authors`, breadcrumbName: '作者中心' },
    report: { path: `${Prefix.adminPlayletPrefix}/report`, breadcrumbName: '统计' },
    recommend: {
      path: `${Prefix.adminPlayletPrefix}/recommend/report`,
      breadcrumbName: '已推荐项目',
    },
    'task-report': { path: `${Prefix.adminPlayletPrefix}/task/report`, breadcrumbName: '审核时间' },
    'time-report': { path: `${Prefix.adminPlayletPrefix}/time/report`, breadcrumbName: '时间统计' },
  }
  return prev && routerMap[prev]
    ? routerMap[prev]
    : { path: Prefix.adminPrefix, breadcrumbName: '首页' }
}

export function leftPad(num: number | string): string {
  return Number(num) > 9 ? `${num}` : `0${num}`
}

export const requestAnimFrame = (() => {
  return (
    window.requestAnimationFrame ||
    // @ts-ignore
    window.webkitRequestAnimationFrame ||
    (window as any).mozRequestAnimationFrame ||
    (window as any).oRequestAnimationFrame ||
    (window as any).msRequestAnimationFrame ||
    ((callback: () => void) => {
      return window.setTimeout(callback, 6000 / 60)
    })
  )
})()

export function splitPageData<T>(data: PageData<T> | undefined): [T[], PageInfo] {
  if (!data) {
    const list: T[] = []
    return [list, { pageNo: 0, pageSize: 0, total: 0, totalPages: 0, sort: undefined }]
  }
  const { list, ...info } = data
  return [list, info]
}

export function queryStringify(data: any) {
  return qs.stringify(data, { skipEmptyString: true, skipNull: true })
}

export const tenPower =
  (power = 2) =>
  (str: string | number = 0) =>
    parseInt(parseFloat(`${str}`).toFixed(power).replace('.', ''), 10)
