import React, { useContext } from 'react'

export type ColumnType<T> = {
  title: string
  dataIndex?: keyof T
  key?: string
  hide?: boolean
  align?: 'left' | 'center' | 'right'
  width?: number
  render?: (item: any, record: T, index: number) => React.ReactNode
}

interface TableContextType<T = any> {
  onChangeCheckbox?: (item: T) => (e: any) => void
  showCheckbox?: boolean
  isChecked?: (item: T) => boolean
  isDisabled?: (item: T) => boolean
  showNo?: boolean
  columns: ColumnType<T>[]
  onRow?: (item: T) => any
  getCount: (index: number) => number
  countRender: (count: number, item: T) => React.ReactNode
}

const TableContext = React.createContext<TableContextType<any>>({
  columns: [],
  getCount: index => index + 1,
  countRender: count => count,
})

export default TableContext

export function useTable() {
  return useContext(TableContext)
}
