import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function CloseOutlined(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M841.18062949 896.88062946c-14.34640241 0-28.73026243-5.46886381-39.70544659-16.48150663L142.32730552 221.17633024a56.1869532 56.1869532 0 0 1 79.41089421-79.41089428l659.14787742 659.26025133a56.1869532 56.1869532 0 0 1-39.70544767 95.89239978z m-659.1478763 0a56.1869532 56.1869532 0 0 1-39.70544767-95.89239977l659.14787738-659.26025131a56.1494956 56.1494956 0 1 1 79.41089425 79.4108942L221.70074215 880.39912283c-10.93772653 11.01264284-25.32158654 16.48150663-39.70544769 16.48150665z"></path>
      </svg>
    </BaseIcon>
  )
}
