// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconTime.module.less'

export default function IconTime(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M717.70724306 567.32641163H524.27692833V300.26530171c0-20.60149906-15.10161104-37.28361348-33.74231867-37.28361348-18.64290584 0-33.75990424 16.68211442-33.75990426 37.28361348v297.28081844c0 0.64846801 0.01538737 1.29144054 0.04396395 1.93331397-0.02747746 0.53306268-0.04396394 1.06942267-0.04396395 1.60798085 0 9.10712876 3.99192513 17.36245629 10.47110979 23.43277635 6.05822997 6.38246398 14.25640442 10.30954231 23.28989356 10.30954233 0.53416179 0 1.06612538-0.01648647 1.59369259-0.04396394 0.63967523 0.02857655 1.28154865 0.04396394 1.92781846 0.04396394H717.70724306c20.60259815 0 37.28361348-15.10161104 37.28361348-33.74121958s-16.68101532-33.76210243-37.28251439-33.76210244zM512 10.19566739c-274.5031047 0-497.01775938 222.51245648-497.01775938 497.01775939S237.4968953 1004.23118618 512 1004.23118618c274.48661823 0 497.01775938-222.51245648 497.01775938-497.0177594S786.48661823 10.19566739 512 10.19566739z m0 920.40362451c-233.82327738 0-423.3858651-189.56148863-423.3858651-423.38586512 0-233.84196206 189.56148863-423.3869642 423.3858651-423.3869642s423.3858651 189.54500214 423.3858651 423.3869642c0 233.82327738-189.56368682 423.3858651-423.3858651 423.38586512z" />
      </svg>
    </BaseIcon>
  )
}
