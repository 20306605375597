import { useEffect, useState } from 'react'
import history from 'history'

import { useHistory } from 'react-router-dom'

import Prefix from '../constants/prefix'

export enum LayoutEnums {
  CUSTOMER,
  ADMIN,
  HOME,
}

function matchLayout(pathname: string) {
  if (new RegExp(`^${Prefix.customerPrefix}`).test(pathname)) {
    return LayoutEnums.CUSTOMER
  }
  if (new RegExp(`^${Prefix.adminPrefix}`).test(pathname)) {
    return LayoutEnums.ADMIN
  }
  return LayoutEnums.HOME
}

function useLayoutState() {
  const history = useHistory()
  const [layout, setLayout] = useState(matchLayout(history.location.pathname))

  useEffect(() => {
    function updateLayoutType(pathname: string) {
      setLayout(matchLayout(pathname))
    }

    updateLayoutType(history.location.pathname)

    return history.listen((location: history.Location) => {
      updateLayoutType(location.pathname)
    })
  }, [history])

  return layout
}

export default useLayoutState
