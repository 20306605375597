// lib
import React from 'react'
import classNames from 'classnames'

// components
import { Dropdown, Menu } from '../../../components'
import { IconBell, IconArrowBottom } from '../../../components/Icons'
import Nav, { RouteItem } from '../../../businessMisc/Nav'

import ProjectNameIcon from '../../../assets/project-name.png'

// styles
import styles from './Header.module.scss'

import { adminLogout, getUserSignDoc } from '../../../api/admin'

import { useEnvContext, useAdminEnvContext } from '../../../context'
import useAdminRole from '../../../hooks/useAdminRole'

import Prefix from '../../../constants/prefix'
import { useLocation } from 'react-router-dom'
import useAdminPageWithRole from '../../../hooks/useAdminPageWithRole'
import { useQuery } from 'react-query'
import { parseRequest } from '../../../api'
import { YesOrNo } from '../../../constants'

export default function Header() {
  const { projectName } = useEnvContext()
  const { currentUser, unReadCount } = useAdminEnvContext()
  const { isAdmin } = useAdminRole()
  const location = useLocation()
  const PageRole = useAdminPageWithRole()
  const [signDoc, setSignDoc] =
    React.useState<{ fileName: string; fileUrl: string; fileId: string }>()

  useQuery(['user-sign-doc', currentUser.id], () => parseRequest(getUserSignDoc(currentUser.id)), {
    enabled: !!(currentUser.id && currentUser.flowId && currentUser.flowStatus === YesOrNo.Yes),
    onSuccess(data) {
      try {
        if (data) {
          const parseData = JSON.parse(data)
          setSignDoc(parseData?.docs?.[0])
        }
      } catch {}
    },
  })

  async function handleLogout() {
    await adminLogout()
    window.location.reload()
  }

  const routes: RouteItem[] = [
    {
      path: [Prefix.adminPrefix, `${Prefix.adminPrefix}/undo`],
      name: '待审核',
      hide: !PageRole.UNDO,
    },
    {
      path: `${Prefix.adminPrefix}/done`,
      name: '已审核',
      hide: !PageRole.DONE,
    },
    {
      path: `${Prefix.adminPrefix}/platform/scripts`,
      name: '项目列表',
      hide: !PageRole.PLATFORM_SCRIPTS,
    },
    {
      name: '项目孵化',
      hide: !PageRole.REPORT_OTHER && !PageRole.REPORT,
      isActive({ children }) {
        const list = children?.map(item => item.path) || []
        return list.reduce<boolean>((p, url) => {
          return p || new RegExp(`^${url}`).test(location.pathname)
        }, false)
      },
      children: [
        {
          path: `${Prefix.adminPrefix}/report`,
          name: '统计',
          hide: !PageRole.REPORT,
        },
        {
          path: `${Prefix.adminPrefix}/recommend/report`,
          name: '已推荐项目',
          hide: !PageRole.REPORT_OTHER,
        },
        {
          path: `${Prefix.adminPrefix}/task/report`,
          name: '审核时间',
          hide: !PageRole.REPORT_OTHER,
        },
        {
          path: `${Prefix.adminPrefix}/time/report`,
          name: '时间统计',
          hide: !PageRole.REPORT_OTHER,
        },
        {
          path: `${Prefix.adminPrefix}/audit/report`,
          name: '评审数据',
          hide: !PageRole.REPORT_OTHER,
        },
      ],
    },
    {
      name: '青春中国梦',
      hide: !PageRole.REPORT_OTHER && !PageRole.TOPIC_REPORT && !PageRole.TOPIC_2023_REPORT,
      isActive({ children }) {
        const list = children?.map(item => item.path) || []
        return list.reduce<boolean>((p, url) => {
          return p || new RegExp(`^${url}`).test(location.pathname)
        }, false)
      },
      children: [
        {
          path: `${Prefix.adminTopic2023Prefix}/report`,
          name: '2023',
          hide: !PageRole.TOPIC_2023_REPORT,
        },
        {
          path: `${Prefix.adminTopicPrefix}/report`,
          name: '2022',
          hide: !PageRole.TOPIC_REPORT,
        },
        {
          path: `${Prefix.adminTopicPrefix}/task/report`,
          name: '审核时间（2022）',
          hide: !PageRole.REPORT_OTHER,
        },
        {
          path: `${Prefix.adminTopicPrefix}/audit/report`,
          name: '评审数据（2022）',
          hide: !PageRole.REPORT_OTHER,
        },
      ],
    },
    {
      name: '微短剧',
      hide: !PageRole.REPORT_OTHER && !PageRole.PLAYLET_REPORT,
      isActive({ children }) {
        const list = children?.map(item => item.path) || []
        return list.reduce<boolean>((p, url) => {
          return p || new RegExp(`^${url}`).test(location.pathname)
        }, false)
      },
      children: [
        {
          path: `${Prefix.adminPlayletPrefix}/report`,
          name: '统计',
          hide: !PageRole.PLAYLET_REPORT,
        },
        {
          path: `${Prefix.adminPlayletPrefix}/recommend/report`,
          name: '已推荐项目',
          hide: !PageRole.REPORT_OTHER,
        },
        {
          path: `${Prefix.adminPlayletPrefix}/task/report`,
          name: '审核时间',
          hide: !PageRole.REPORT_OTHER,
        },
        {
          path: `${Prefix.adminPlayletPrefix}/time/report`,
          name: '时间统计',
          hide: !PageRole.REPORT_OTHER,
        },
        {
          path: `${Prefix.adminPlayletPrefix}/audit/report`,
          name: '评审数据',
          hide: !PageRole.REPORT_OTHER,
        },
      ],
    },
    {
      path: `${Prefix.adminPrefix}/authors`,
      name: '作者中心',
      hide: !PageRole.AUTHORS,
    },
    {
      name: '设置',
      hide: !PageRole.AGREEMENT && !PageRole.SETTING,
      isActive({ children }) {
        const list = children?.map(item => item.path) || []
        return list.reduce<boolean>((p, url) => {
          return p || new RegExp(`^${url}`).test(location.pathname)
        }, false)
      },
      children: [
        {
          path: `${Prefix.adminPrefix}/agreement`,
          name: '协议设置',
          hide: !PageRole.AGREEMENT,
        },
        {
          path: `${Prefix.adminPrefix}/setting`,
          name: '项目孵化设置',
          hide: !PageRole.SETTING,
        },
        {
          path: `${Prefix.adminTopicPrefix}/setting`,
          name: '青春中国梦设置',
          hide: !PageRole.SETTING,
        },
        {
          path: `${Prefix.adminPlayletPrefix}/setting`,
          name: '微短剧设置',
          hide: !PageRole.SETTING,
        },
      ],
    },
    {
      path: `${Prefix.adminPrefix}/message`,
      name: (
        <span
          className={classNames({
            'relative after:absolute after:right-[1px] after:top-[1px] after:w-2 after:h-2 after:bg-red-default after:rounded-full':
              unReadCount > 0,
          })}
        >
          <IconBell />
        </span>
      ),
    },
  ]

  return (
    <div className={styles.header}>
      <a href={Prefix.adminPrefix}>
        <img style={{ height: 22 }} src={ProjectNameIcon} alt={projectName} />
      </a>
      <div className="flex items-center">
        <Nav routes={routes} />
        <Dropdown
          placement="bottomRight"
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key === 'logout') {
                  handleLogout()
                }
              }}
            >
              {signDoc ? (
                <Menu.Item key="1">
                  <a
                    onClick={e => e.stopPropagation()}
                    href={signDoc.fileUrl}
                    className="flex-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    保密协议
                  </a>
                </Menu.Item>
              ) : (
                <></>
              )}
              <Menu.Item key="logout">退出登录</Menu.Item>
            </Menu>
          }
        >
          <span className={styles.info}>
            {isAdmin ? <span className={styles.admin}>管</span> : null}
            <span
              className={classNames(styles['user-name'], {
                [styles['has-admin']]: isAdmin,
              })}
            >
              {currentUser.name}
            </span>
            <IconArrowBottom className={styles['arrow-icon']} />
          </span>
        </Dropdown>
      </div>
    </div>
  )
}
