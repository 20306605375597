import Portal from '../../components/Portal'

import styles from './AgreementDialog.module.scss'
import AgreementPage from '../../components/AgreementPage'
import { Button } from '../../components'
import React from 'react'
import { AgreementType } from '../../constants'

export default function AgreementDialog({
  seconds,
  onAgree,
  type,
  loading,
}: {
  loading?: boolean
  seconds?: number
  onAgree?(): void
  type: AgreementType
}) {
  return (
    <Portal>
      <div className={styles.backdrop} />
      <div className={styles.agreement}>
        <header className={styles.header}>
          <span>{type === AgreementType.Register ? '用户注册协议' : '承诺函'}</span>
        </header>
        <div className={styles.content}>
          <AgreementPage type={type} />
        </div>
        <footer className={styles.footer}>
          {type === AgreementType.Promise ? (
            <div className={styles.tips}>
              <span className={styles.tip}>请仔细阅读承诺函</span>
              <div className={styles.counter}>
                倒计时<em data-counter={`${seconds} 秒`}>{seconds} 秒</em>
                后可点击同意承诺函
              </div>
            </div>
          ) : (
            <div className={styles.tips}>
              <span className={styles.tip}>用户注册协议有更新</span>
              <div className={styles.counter}>请先阅读用户注册协议并同意</div>
            </div>
          )}

          <Button
            disabled={
              (type === AgreementType.Register && loading) ||
              (type === AgreementType.Promise && (seconds as number) > 0)
            }
            loading={loading}
            circle
            type="primary"
            className={styles.btn}
            onClick={onAgree}
          >
            我已阅读并同意
          </Button>
        </footer>
      </div>
    </Portal>
  )
}
