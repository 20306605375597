// lib
import React from 'react'
import classnames from 'classnames'

// components
import { IconLoading } from '../../components/Icons'

// styles
import styles from './Button.module.scss'

interface ButtonProps {
  children: React.ReactNode
  type?: 'primary' | 'ghost' | 'danger' | 'default'
  htmlType?: 'submit' | 'reset' | 'button'
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  style?: React.CSSProperties
  className?: string
  disabled?: boolean
  loading?: boolean
  block?: boolean
  circle?: boolean
  size?: 'small' | 'medium' | 'large'
}

type Props = ButtonProps & Omit<React.RefAttributes<HTMLButtonElement>, 'size'>

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      type = 'default',
      htmlType = 'button',
      loading,
      children,
      disabled,
      onClick,
      style,
      className,
      block,
      circle,
      size = 'medium',
      ...restProps
    },
    ref
  ) => {
    return (
      <button
        style={style}
        disabled={disabled}
        type={htmlType}
        className={classnames(
          styles.btn,
          'inline-flex items-center justify-center outline-none border border-transparent cursor-pointer rounded overflow-hidden whitespace-nowrap',
          {
            [styles[`btn-theme-${type}`]]: type,
            [styles.disabled]: disabled,
            [styles.loading]: loading,
            'flex w-full': block,
            'rounded-full': circle,
          },
          {
            'text-xs px-2 h-7': size === 'small',
            'text-sm px-2.5 h-8 min-w-[70px]': size === 'medium',
            'text-base px-4 h-10': size === 'large',
          },
          className
        )}
        onClick={e => {
          if (loading) return
          onClick?.(e)
        }}
        ref={ref}
        {...restProps}
      >
        {loading ? (
          <IconLoading
            className={classnames(
              styles['loading-icon'],
              'inline-flex h-5 w-5 mr-2 animate-spin',
              'loading-icon'
            )}
          />
        ) : null}
        {children}
      </button>
    )
  }
)

export default Button
