// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconArrowRightBold.module.less'

export default function IconArrowRightBold(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M780.75603125 564.42059226C632.92554687 707.34396725 485.09604687 850.26143601 337.2695 993.0391235c-60.8698125 58.90696875-151.61442187-36.74376563-90.46307812-95.79248437 131.55876562-127.21078125 263.1195-254.28178125 394.6044375-381.49256249C509.22209375 384.19629539 377.03332812 252.49676413 244.77270313 120.93799851c-60.16795312-59.889375 30.5746875-155.54207812 90.53395312-95.7245625C483.83899999 172.9730454 632.29554687 320.80057664 780.75701563 468.62909226c24.54440625 24.54342187 25.17539062 71.53059375 0 95.79051563z"></path>
      </svg>
    </BaseIcon>
  )
}
