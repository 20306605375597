// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconSort.module.less'

export default function IconSort(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M368.0012652 440.00604716a71.99936691 71.99936691 0 1 1 0-143.9987348 71.99936691 71.99936691 0 0 1 0 143.9987348z m0 287.99746861a71.99936691 71.99936691 0 1 1 0-143.9987348 71.99936691 71.99936691 0 0 1 0 143.9987348z m0 287.99648423a71.99936691 71.99936691 0 1 1 0-143.9987348 71.99936691 71.99936691 0 0 1 0 143.9987348z m287.9974696-863.99043806a71.99936691 71.99936691 0 1 1 0-143.99873381 71.99936691 71.99936691 0 0 1 0 143.99873381z m0 287.99648522a71.99936691 71.99936691 0 1 1 0-143.9987348 71.99936691 71.99936691 0 0 1 0 143.9987348z m0 287.99746861a71.99936691 71.99936691 0 1 1 0-143.9987348 71.99936691 71.99936691 0 0 1 0 143.9987348z m0 287.99648423a71.99936691 71.99936691 0 1 1 0-143.9987348 71.99936691 71.99936691 0 0 1 0 143.9987348zM368.0012652 152.00956194a71.99936691 71.99936691 0 1 1 0-143.99873382 71.99936691 71.99936691 0 0 1 0 143.99873382z" />
      </svg>
    </BaseIcon>
  )
}
