import AsyncComponent from './AsyncComponent'

import Prefix from '../constants/prefix'

const customerTopic2023Routes = [
  {
    path: '/',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-topic-2023-home" */ '../$$customerTopic2023/Home')
    ),
  },
  {
    path: '/script/:id(\\d+)',
    exact: true,
    component: AsyncComponent(
      () =>
        import(/* webpackChunkName: "customer-topic-2023-script" */ '../$$customerTopic2023/Script')
    ),
  },
  {
    path: '/upload/:scriptId',
    exact: true,
    component: AsyncComponent(
      () =>
        import(/* webpackChunkName: "customer-topic-2023-upload" */ '../$$customerTopic2023/Upload')
    ),
  },
]

export default [
  ...customerTopic2023Routes.map(item => ({
    ...item,
    path: `${Prefix.customerTopic2023Prefix}${item.path}`,
  })),
]
