import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'

import ModalContent, { ContentType } from './components/ModalContent'
import { IconClose } from '../Icons'
import Button from '../Button'
import { confirm } from './method'

import styles from './Modal.module.scss'

type ModalType = React.PropsWithChildren<
  {
    title?: React.ReactNode
    footer?: React.ReactNode | null
    visible?: boolean
    onCancel?: () => void
    onOk?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    closable?: boolean
    confirmLoading?: boolean
    okText?: React.ReactNode
    cancelText?: React.ReactNode
  } & ContentType
>

function Modal({
  title,
  visible,
  children,
  width = 520,
  onCancel,
  onOk,
  className,
  closable = true,
  maskClosable = true,
  confirmLoading,
  footer,
  okText = '确定',
  cancelText = '取消',
  ...props
}: ModalType) {
  const elementRef = React.useRef(document.createElement('div'))
  const [primaryLoading, setPrimaryLoading] = React.useState(false)

  React.useEffect(() => {
    const dom = elementRef.current
    if (visible) {
      document.body.appendChild(dom)
    }

    return () => {
      if (document.body.contains(dom)) {
        document.body.removeChild(dom)
      }
    }
  }, [visible])

  async function handleOk(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (typeof onOk === 'function') {
      try {
        setPrimaryLoading(true)
        await onOk(event)
      } finally {
        setPrimaryLoading(false)
      }
    }
  }

  function renderModal() {
    return (
      <ModalContent
        width={width}
        onClickMask={onCancel}
        maskClosable={maskClosable}
        className={classNames(className, styles.modal)}
        {...props}
      >
        {closable ? (
          <div className={styles['close-btn']} onClick={onCancel}>
            <span className={styles.close}>
              <IconClose className={styles['close-icon']} />
            </span>
          </div>
        ) : null}
        {title ? (
          <div className={classNames(styles['modal-header'], 'modal-header')}>{title}</div>
        ) : null}
        <div className={classNames(styles['modal-body'], 'modal-body')}>{children}</div>
        {footer === null ? null : (
          <div className={classNames(styles['modal-footer'], 'modal-footer')}>
            {footer || (
              <>
                <Button onClick={onCancel} className={styles['opt-btn']}>
                  {cancelText}
                </Button>
                <Button
                  onClick={handleOk}
                  className={styles['opt-btn']}
                  type="primary"
                  loading={primaryLoading || confirmLoading}
                >
                  {okText}
                </Button>
              </>
            )}
          </div>
        )}
      </ModalContent>
    )
  }

  return ReactDOM.createPortal(renderModal(), elementRef.current)
}

export default Modal

Modal.confirm = confirm
