export enum PlayletScriptClassify {
  /** 微短剧 */
  PLAYLET = 'PLAYLET',
}

export const PlayletRole = {
  /** 预审人 */
  PLAYLET_PRE_EXAMINER: 'PLAYLET_PRE_EXAMINER',
  /** 初审人 */
  PLAYLET_ROUND_A_VOTER: 'PLAYLET_ROUND_A_VOTER',
  /** 终审人 */
  PLAYLET_ROUND_B_VOTER: 'PLAYLET_ROUND_B_VOTER',
} as const

const PLAYLET_ROLE_LABEL = {
  [PlayletRole.PLAYLET_PRE_EXAMINER]: '预审人',
  [PlayletRole.PLAYLET_ROUND_A_VOTER]: '初审人',
  [PlayletRole.PLAYLET_ROUND_B_VOTER]: '终审人',
}

export const PLAYLET_ROLE_LIST = [
  {
    label: PLAYLET_ROLE_LABEL[PlayletRole.PLAYLET_PRE_EXAMINER],
    value: PlayletRole.PLAYLET_PRE_EXAMINER,
    multiple: true,
  },
  {
    label: PLAYLET_ROLE_LABEL[PlayletRole.PLAYLET_ROUND_A_VOTER],
    value: PlayletRole.PLAYLET_ROUND_A_VOTER,
    multiple: true,
  },
  {
    label: PLAYLET_ROLE_LABEL[PlayletRole.PLAYLET_ROUND_B_VOTER],
    value: PlayletRole.PLAYLET_ROUND_B_VOTER,
    multiple: true,
  },
]
