import React from 'react'
import 'braft-editor/dist/output.css'
import useAgreement from '../../hooks/useAgreement'
import { AgreementType } from '../../constants'

export default function AgreementPage({ type }: { type: AgreementType }) {
  const agreement = useAgreement(type)
  return (
    <div style={{ padding: '0 15px' }}>
      <div className="braft-output-content" dangerouslySetInnerHTML={{ __html: agreement }} />
    </div>
  )
}
