import AsyncComponent from './AsyncComponent'

import Prefix from '../constants/prefix'

const customerRoutes = [
  {
    path: '/',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-home" */ '../$$customer/Home')
    ),
  },
  {
    path: '/script/:id(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-script" */ '../$$customer/Script')
    ),
  },
  {
    path: '/upload/:scriptId',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-upload" */ '../$$customer/Upload')
    ),
  },
  {
    path: '/contact',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-contact" */ '../$$customer/Contact')
    ),
  },
  {
    path: '/password',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-password" */ '../$$customer/Password')
    ),
  },
  {
    path: '/agreement',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "customer-agreement" */ '../$$customer/Agreement')
    ),
  },
]

export default [
  ...customerRoutes.map(item => ({
    ...item,
    path: `${Prefix.customerPrefix}${item.path}`,
  })),
]
