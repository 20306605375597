// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconFolder.module.less'

export default function IconFolder(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M957.22869841 155.8038317H471.05031998l-42.93111615-85.86223231a59.24494029 59.24494029 0 0 0-53.16853616-32.89183976H66.70525372a59.37703603 59.37703603 0 0 0-59.31098816 59.17889243v740.19849029c0 81.63516856 59.11284455 148.07932678 140.94615671 148.07932679 5.0196382 0 7.46340942-0.26419148 7.46340943-0.72652659v3.17029782h712.3923366a148.40956614 148.40956614 0 0 0 148.40956614-148.07932679V214.7845805a59.11284455 59.11284455 0 0 0-59.37703603-58.91470093z m0 683.06708188c0 49.00752028-39.89291408 88.70229075-89.03253011 88.70229076H272.57646763c19.8143613-29.72154195 31.6369302-56.73512119 31.6369302-91.14606199V541.72154195h653.01530058v297.2154195zM244.8363618 482.34450593v354.08263642a89.03253011 89.03253011 0 0 1-178.06506021 0V96.42679566h308.11331821l42.93111615 85.8622323c10.17137213 20.14460066 30.5141164 32.89183976 53.16853616 32.89183977h486.2444263V482.27845805H244.8363618z"></path>
      </svg>
    </BaseIcon>
  )
}
