import React, { lazy, Suspense } from 'react'

import Loading from '../components/Loading'

const LoadingComponent = (
  <div className="flex justify-center p-12">
    <Loading.Ellipsis />
  </div>
)

const AsyncComponent = <T extends React.ComponentType<any>>(
  loader: () => Promise<{ default: T }>
) => {
  const TargetComponent = lazy(loader)
  return (props: React.ComponentPropsWithRef<T>) => (
    <Suspense fallback={LoadingComponent}>
      <TargetComponent {...props} />
    </Suspense>
  )
}

export default AsyncComponent
