// lib
import React from 'react'
import classnames from 'classnames'

import EmptyImg from '../../assets/empty.png'
import EmptyTextImg from '../../assets/empty-text.png'

export interface Props {
  className?: string
  style?: React.CSSProperties
  icon?: React.ReactNode
  emptyText?: boolean
  children?: React.ReactNode
}

const Empty = ({ className, emptyText, children, icon, style }: Props) => {
  const renderEmptyTextImage = () => {
    return (
      <div className="h-5 md:h-8">
        <img src={EmptyTextImg} className="h-full max-w-full object-cover" alt="" />
      </div>
    )
  }

  return (
    <div
      className={classnames(
        'h-full flex-1 flex items-center flex-col justify-center py-7',
        className
      )}
      style={style}
    >
      {children || (
        <>
          {icon || (
            <div className={classnames('h-44 md:h-72 mb-6')}>
              <img className="h-full max-w-full object-cover" src={EmptyImg} alt="" />
            </div>
          )}
          {emptyText ? renderEmptyTextImage() : null}
        </>
      )}
    </div>
  )
}

export default Empty
