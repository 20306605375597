import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function CalendarOutlined(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M99.35806309 361.03343771V189.93800045h161.03099977v60.38662492c0 5.53544061 4.52899687 10.06443749 10.06443748 10.06443749h70.4510624c5.53544061 0 10.06443749-4.52899687 10.06443749-10.06443749v-60.38662492h322.06199954v60.38662492c0 5.53544061 4.52899687 10.06443749 10.06443749 10.06443749h70.4510624c5.53544061 0 10.06443749-4.52899687 10.06443748-10.06443749v-60.38662492h161.03099977v171.09543726H99.35806309z m825.28387382 563.6084992H99.35806309V446.58115635h825.28387382v478.06078056z m50.32218743-825.28387382H763.61093714V18.84256319c0-5.53544061-4.52899687-10.06443749-10.06443748-10.06443748h-70.4510624c-5.53544061 0-10.06443749 4.52899687-10.06443749 10.06443748v80.5154999H350.96900023V18.84256319c0-5.53544061-4.52899687-10.06443749-10.06443749-10.06443748h-70.4510624c-5.53544061 0-10.06443749 4.52899687-10.06443748 10.06443748v80.5154999H49.03587566c-22.24240684 0-40.25774994 18.0153431-40.25774995 40.25774994v835.34831131c0 22.24240684 18.0153431 40.25774994 40.25774995 40.25774995h925.92824868c22.24240684 0 40.25774994-18.0153431 40.25774995-40.25774995V139.61581303c0-22.24240684-18.0153431-40.25774994-40.25774995-40.25774994z"></path>
      </svg>
    </BaseIcon>
  )
}
