import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconAudited(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
        <path d="M26.82 27.613h45.096V21.96H26.82v5.653zm0 5.653h45.096v5.653h-45.23l.134-5.653zm0 11.262h28.862v5.698H26.685l.135-5.698zm0 11.261h11.95v5.608H26.686l.135-5.608zM82.92 5H15.591C12.504 5 10 7.51 10 10.608v78.784C10 92.49 12.504 95 15.592 95h26.29v-5.653h-26.29V10.608h67.327v36.18h5.592v-36.18a5.616 5.616 0 0 0-1.638-3.966A5.585 5.585 0 0 0 82.919 5z" />
        <path d="M89.095 59.33a3.335 3.335 0 0 0-4.734.627L65.692 84.709 53.881 74.235a3.343 3.343 0 0 0-4.756.336 3.438 3.438 0 0 0 .332 4.812l14.51 12.846a3.337 3.337 0 0 0 4.91-.492l20.881-27.662a3.429 3.429 0 0 0-.663-4.745z" />
      </svg>
    </BaseIcon>
  )
}
