// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconRefresh.module.less'

export default function IconRefresh(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M512 93.76517199a418.23482801 418.23482801 0 1 0 372.64723175 608.28073384 41.82348279 41.82348279 0 0 1 74.44579937 38.14301631 501.88179362 501.88179362 0 1 1-80.13379301-570.63959931l63.57169383-63.57169385A41.82348279 41.82348279 0 0 1 1013.88179362 135.58865479l1e-8 167.29393122a41.65618887 41.65618887 0 0 1-41.82348284 41.82348278L804.76437961 344.70606879a41.65618887 41.65618887 0 0 1-29.61102582-71.43450861l44.58383266-44.58383267A417.73294619 417.73294619 0 0 0 512 93.76517199z"></path>
      </svg>
    </BaseIcon>
  )
}
