import AsyncComponent from './AsyncComponent'

import home from './home'
import customer from './customer'
import customerTopic from './customerTopic'
import customerTopic2023 from './customerTopic2023'
import customerPlaylet from './customerPlaylet'
import common from './common'
import admin from './admin'
import adminTopic from './adminTopic'
import adminTopic2023 from './adminTopic2023'
import adminPlaylet from './adminPlaylet'

const isDev = process.env.NODE_ENV === 'development'

const routes = isDev
  ? [
      {
        path: '/icons',
        exact: true,
        component: AsyncComponent(() => import(/* webpackChunkName: "icons" */ '../$icons')),
      },
    ]
  : []

export default [
  ...routes,
  ...home,
  ...customer,
  ...customerTopic,
  ...customerTopic2023,
  ...customerPlaylet,
  ...admin,
  ...adminTopic,
  ...adminTopic2023,
  ...adminPlaylet,
  ...common,
]
