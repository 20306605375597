import React from 'react'
import ReactDOM from 'react-dom'

import Button from '../../Button'
import { IconExclamationCircleFilled } from '../../Icons'

import styles from './styles.module.scss'

import ModalContent from '../components/ModalContent'

interface Props {
  title?: React.ReactNode
  content?: React.ReactNode
  onOk?: () => void
  onCancel?: () => void
}

function Modal({
  title,
  content,
  onCancel,
  onOk,
  removeContainer,
}: Props & { removeContainer: () => void }) {
  const [loading, setLoading] = React.useState(false)

  async function handleOk() {
    if (loading) return
    if (typeof onOk === 'function') {
      try {
        setLoading(true)
        await onOk()
      } finally {
        setLoading(false)
      }
    }
    removeContainer()
  }

  function handleCancel() {
    if (typeof onCancel === 'function') {
      onCancel()
    }
    removeContainer()
  }

  return (
    <ModalContent width={416}>
      <div className={styles['modal-confirm']}>
        <div className={styles['modal-confirm-body']}>
          <div className={styles['modal-confirm-body-header']}>
            <IconExclamationCircleFilled className={styles['exclamation-icon']} />
            <span className={styles['modal-confirm-body-title']}>{title}</span>
          </div>
          <div className={styles['modal-confirm-body-content']}>{content}</div>
        </div>
        <div className={styles['modal-confirm-btns']}>
          <Button className={styles.btn} onClick={handleCancel}>
            取消
          </Button>
          <Button loading={loading} className={styles.btn} type="primary" onClick={handleOk}>
            确定
          </Button>
        </div>
      </div>
    </ModalContent>
  )
}

export function confirm(props: Props) {
  const container = document.createElement('div')

  function removeContainer() {
    document.body.removeChild(container)
  }

  ReactDOM.render(<Modal {...props} removeContainer={removeContainer} />, container)

  document.body.appendChild(container)
}
