import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function LikeTwoTone(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path
          d="M596.90489511 393.00538297l83.2207351-172.59736544-83.2207351-139.95950563H512l-54.18349326 101.39804846L361.00634247 366.17497153 230.22260525 455.49352739H34.62774427v491.77472767h808.39681263l143.73434705-380.73631491V455.49352739l-79.56204259-62.48814442z"
          fill="#F7CA0C"
        />
        <path
          d="M996.39926826 424.07523173C974.50518792 388.88209464 936.52447559 366.81379083 897.61457154 365.65230116l-241.70600102-1.16148965a439.33346892 439.33346892 0 0 0 17.53849406-220.39266553L668.80110589 124.64319397a121.66604327 121.66604327 0 0 0-119.11076599-94.83563184 122.53716053 122.53716053 0 0 0-121.84026673 133.39708896C424.59790208 282.89616194 280.51510812 406.4205887 231.5002439 406.4205887h-0.05807449l-179.50822902 0.11614897a42.6847455 42.6847455 0 0 0-42.5685965 42.62667101l-0.46459588 502.34428371c0 23.8105383 20.61644171 41.11673444 46.45958693 41.81362823l155.63961624-0.23229794 5.9816718 1.0453407 5.22670352-0.69689379 592.3597334 0.23229794a124.62784194 124.62784194 0 0 0 58.36485607-19.04843066c18.87420721-12.13756709 34.0897219-29.1533908 41.00058547-44.19468206l7.25931048-12.02141813c40.82636202-97.15861117 90.59619451-394.73226548 92.62880143-407.27635395l0.81304276-12.0214181a123.11790536 123.11790536 0 0 0-18.23538785-75.0322329zM81.95844846 479.47828915l96.81016427-0.11614897v441.5402993l-97.15861119 0.29037244 0.34844692-441.71452277z m774.53938862 406.40523668l-4.5298097 7.25931047a51.1636201 51.1636201 0 0 1-18.17731338 20.32606927 49.53753456 49.53753456 0 0 1-21.6617824 7.54968288l-560.70913981-0.17422345V475.47114978C347.0684664 456.82924051 496.31988941 310.48154168 500.38510328 159.60403311l-0.40652141-7.37545943c0-27.46923079 22.24252724-49.82790699 49.71175803-49.82790697a49.36331112 49.36331112 0 0 1 48.43411939 38.56145715l4.06521384 17.30619613a365.28850226 365.28850226 0 0 1-15.27358922 182.93462354l-33.39282809 95.70674909 330.61803552 0.11614897 7.84005528 0.81304278c17.53849406 0.58074483 33.50897707 9.87266223 42.74281997 24.68165555 5.74937388 9.17576842 8.36272566 19.91954791 7.4916084 36.70307366-0.52267036 2.90372418-50.4667263 301.63886815-85.71793791 386.65991225z"
          fill="#000000"
        />
      </svg>
    </BaseIcon>
  )
}
