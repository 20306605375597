// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconBellFilled.module.less'

export default function IconBellFilled(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M884.84044147 609.96052486V388.00613017A372.78676447 372.78676447 0 0 0 512 15.1120117a372.78676447 372.78676447 0 0 0-372.84044147 372.84044148v222.00807168a93.18327186 93.18327186 0 0 0 39.98936494 177.45616176H844.79739953a93.18327186 93.18327186 0 0 0 39.98936494-177.45616176z m-237.78910966 212.2388577l-270.21001762 0.268385c-23.93994196-0.268385-24.63774296 26.62379196-24.63774296 26.62379196a159.79642877 159.79642877 0 1 0 319.59285754 0s0-26.62379196-24.69141997-26.89217696z"></path>
      </svg>
    </BaseIcon>
  )
}
