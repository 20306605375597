// lib
import React from 'react'
import classNames from 'classnames'

// components

// styles
import styles from './Checkbox.module.scss'

type CheckboxType = React.PropsWithChildren<{
  disabled?: boolean
  defaultChecked?: boolean
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  value?: React.ReactText
}>

type CheckboxGroupType = React.PropsWithChildren<{
  disabled?: boolean
  defaultValue?: React.ReactText[]
  value?: React.ReactText[]
  onChange?: (checkedValue: React.ReactText[]) => void
  children: React.ReactElement[] | React.ReactElement
  className?: string
}>

function Checkbox({
  disabled,
  defaultChecked,
  checked,
  onChange,
  children,
  className,
}: CheckboxType) {
  function onChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    if (typeof onChange === 'function') {
      onChange(e)
    }
  }

  return (
    <label
      className={classNames(styles['checkbox-wrapper'], className, {
        [styles.disabled]: disabled,
      })}
    >
      <span className={styles.checkbox}>
        <input
          disabled={disabled}
          className={styles['checkbox-input']}
          type="checkbox"
          onChange={onChangeCheckbox}
          defaultChecked={defaultChecked}
          checked={checked}
        />
        <span className={styles['checkbox-inner']}></span>
      </span>
      {children ? <span className={styles['checkbox-content']}>{children}</span> : null}
    </label>
  )
}

function CheckboxGroup({
  children,
  disabled,
  defaultValue,
  value,
  onChange,
  className,
}: CheckboxGroupType) {
  const onChangeCheckbox = (v: React.ReactText) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === 'function') {
      if (event.target.checked) {
        onChange([...(value || []), v])
      } else {
        onChange((value || []).filter(item => item !== v))
      }
    }
  }

  return (
    <div className={classNames(className)}>
      {React.Children.map(children, (option: React.ReactElement) => {
        const { props } = option
        return React.cloneElement(option as React.ReactElement, {
          ...props,
          disabled: disabled || props.disabled,
          defaultChecked: defaultValue && defaultValue.includes(props.value),
          checked: value && value.includes(props.value),
          onChange: onChangeCheckbox(props.value),
        })
      })}
    </div>
  )
}

Checkbox.Group = CheckboxGroup

export default Checkbox
