// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconPlus.module.less'

export default function IconPlus(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M512.10040625 8c21.31959375 0 38.6071875 17.28759375 38.6071875 38.6071875v426.73640625h426.78660937a38.6071875 38.6071875 0 1 1 0 77.26359375H550.70759375v426.785625a38.6071875 38.6071875 0 1 1-77.21339062 0l-0.05020313-426.785625H46.70759375a38.6071875 38.6071875 0 0 1 0-77.214375l426.73640625-0.04921875V46.6071875C473.49420313 25.28660937 490.832 8 512.10040625 8z"></path>
      </svg>
    </BaseIcon>
  )
}
