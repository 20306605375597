// lib
import React from 'react'
// import PropTypes from 'prop-types'

// components
import Empty, { Props as EmptyProps } from '../Empty'
import LoadingContainer from './LoadingContainer'
import Pagination, { PaginationType } from '../Pagination'

// styles
import style from './style.module.scss'

type Props = React.PropsWithChildren<{
  loading?: boolean // 加载中
  empty?: boolean // 是否为空
  customEmpty?: React.ReactNode // 自定义空展示
  customLoading?: React.ReactNode // 自定义加载中展示
  emptyText?: boolean // 空展示文案
  pagination?: PaginationType
  emptyClassName?: string
  emptyProps?: EmptyProps
}>

const Spin = ({
  loading,
  empty,
  children,
  customEmpty,
  customLoading = <LoadingContainer />,
  emptyText = true,
  pagination,
  emptyClassName,
  emptyProps,
}: Props) => {
  const renderPagination = () => {
    if (empty || !pagination) return null

    return <Pagination {...pagination} />
  }

  if (loading) {
    if (empty) return <>{customLoading}</>

    return (
      <>
        <div className={style['spin-loading']}>
          <div className={style.mask}>{customLoading}</div>
          {children}
        </div>
        {renderPagination()}
      </>
    )
  }

  if (empty) {
    if (customEmpty) return <>{customEmpty}</>

    return (
      <Empty
        className={emptyClassName}
        style={{ backgroundColor: '#fff' }}
        emptyText={emptyText}
        {...emptyProps}
      />
    )
  }

  return (
    <>
      {children}
      {renderPagination()}
    </>
  )
}

export default Spin
