import React from 'react'
import ReactDOM from 'react-dom'
import './styles/output.css'
import './styles/index.scss'

// import mediaQuery from './mediaQuery'

import App from './App'

// mediaQuery()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
