import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconDownload(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
        <path d="M50.5 61.535L66.213 46 71 50.733l-19.592 19.37L50.5 71 30 50.733 34.787 46 50.5 61.535zM46 0h7v64h-7V0zM7.143 46v43.202c0 1.986 1.597 3.598 3.559 3.598h78.596c1.947 0 3.56-1.624 3.56-3.598V46H100v43.202C100 95.166 95.177 100 89.298 100H10.702C4.792 100 0 95.16 0 89.202V46H7.143z" />
      </svg>
    </BaseIcon>
  )
}
