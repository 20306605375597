import React from 'react'
import { HEADER_THEME_TYPE } from '../constants'

interface EnvProps {
  projectName: string
  isMobile: boolean
}

export const EnvContext = React.createContext<EnvProps>({
  projectName: '',
  isMobile: true,
})

export function useEnvContext() {
  return React.useContext(EnvContext)
}

interface CustomerEnvProps {
  headerTheme: ValueOf<typeof HEADER_THEME_TYPE>
  setHeaderTheme: React.Dispatch<React.SetStateAction<ValueOf<typeof HEADER_THEME_TYPE>>>
  currentUser: AuthorVO
  updateCurrentUser: React.Dispatch<React.SetStateAction<AuthorVO>>
  submitSwitch: IAUTHOR.AuthorSubmitVO | undefined
}

export const CustomerEnvContext = React.createContext<CustomerEnvProps>({
  headerTheme: 'default',
  setHeaderTheme() {},
  submitSwitch: undefined,
  currentUser: {} as AuthorVO,
  updateCurrentUser: () => {},
})

export function useCustomerEnvContext() {
  return React.useContext(CustomerEnvContext)
}

interface AdminEnvProps {
  currentUser: UserVO
  currentRoles: string[]
  updateCurrentUser: React.Dispatch<React.SetStateAction<UserVO>>
  unReadCount: number
  setUnReadCount: React.Dispatch<React.SetStateAction<number>>
  updateUnReadCount: () => void
  roleLoaded: boolean
}

export const AdminEnvContext = React.createContext<AdminEnvProps>({
  currentUser: {} as UserVO,
  currentRoles: [],
  updateCurrentUser: () => {},
  unReadCount: 0,
  setUnReadCount: () => {},
  updateUnReadCount: () => {},
  roleLoaded: false,
})

export function useAdminEnvContext() {
  return React.useContext(AdminEnvContext)
}

interface HomeEnvProps {
  admin: UserVO | undefined
  author: AuthorVO | undefined
  submitSwitch: IAUTHOR.AuthorSubmitVO | undefined
}

export const HomeEnvContext = React.createContext<HomeEnvProps>({
  submitSwitch: undefined,
  admin: undefined,
  author: undefined,
})

export function useHomeEnvContext() {
  return React.useContext(HomeEnvContext)
}
