// lib
import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'

// components

// styles
import styles from './Overlay.module.scss'

import { useOverlayContext } from './context'
import useClickAway from '../../../hooks/useClickAway'

import { PlacementType as PlacementT, OverlaCommonType as OverlaCommonT } from './type'
import useOverlayStyle from './useOverlayStyle'

export { default as OverlayContext } from './context'

// overlay 和 children的距离
const UNBEAUTIFUL_HEIGHT = 5

export type PlacementType = PlacementT

export type OverlaCommonType = OverlaCommonT

type OverlayProps = React.PropsWithChildren<
  {
    className?: string
    style?: React.CSSProperties
    getStyle?: () => React.CSSProperties
    childrenRef: React.RefObject<HTMLElement>
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    unbeautifulHeight?: number
  } & OverlaCommonType
>

const Overlay = ({
  children,
  placement = 'bottomLeft',
  style,
  getStyle,
  getPopupContainer = () => document.body,
  childrenRef,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  unbeautifulHeight = UNBEAUTIFUL_HEIGHT,
}: OverlayProps) => {
  const { active, setActive } = useOverlayContext()
  const [showOverlay, setShowOverlay] = React.useState(false)
  const overlayRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (active) {
      setShowOverlay(true)
    } else {
      const node = overlayRef.current
      node?.classList.add(styles['move-leave'])
    }
  }, [active])

  useClickAway([childrenRef, overlayRef], () => {
    setActive(false)
  })

  React.useEffect(() => {
    const node = overlayRef.current
    node?.addEventListener('animationend', () => {
      if (showOverlay) {
        node.classList.remove(styles['move-appear'])
      }
      if (!active) {
        node.classList.remove(styles['move-leave'])
        setShowOverlay(false)
      }
    })
  }, [showOverlay, active])

  const container = React.useMemo(() => {
    const node = getPopupContainer()
    if (node) {
      if (
        !['relative', 'absolute', 'fixed'].includes(window.getComputedStyle(node, null).position)
      ) {
        node.style.position = 'relative'
      }
    }
    return node
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPopupContainer, active])

  const overlayStyle = useOverlayStyle({
    showOverlay,
    getStyle,
    placement,
    childrenRef,
    container,
    style,
    unbeautifulHeight,
  })

  if (!showOverlay) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      className={classNames(styles.overlay, styles[placement], styles['move-appear'], className)}
      style={overlayStyle}
      ref={overlayRef}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className={styles.unbeautiful} style={{ height: unbeautifulHeight }} />
      {children}
    </div>,
    container
  )
}

export default Overlay
