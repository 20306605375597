export const TOPIC_UPLOAD_TYPE_MAP = {
  DRAMA: 'drama',
  VARIETY: 'variety',
} as const

export const TOPIC_UPLOAD_TYPE_LABEL_MAP = {
  [TOPIC_UPLOAD_TYPE_MAP.DRAMA]: '剧集、电影及动画',
  [TOPIC_UPLOAD_TYPE_MAP.VARIETY]: '综艺、纪实、短视频及音频',
} as const

export const TOPIC_UPLOAD_TYPE_LIST = [
  TOPIC_UPLOAD_TYPE_MAP.DRAMA,
  TOPIC_UPLOAD_TYPE_MAP.VARIETY,
] as const

export const TopicScriptClassify = {
  /** 活动（用于关联VoteItemDO） */
  DREAM: 'DREAM',
  /** A组 */
  A: 'A',
  /** B组 */
  B: 'B',
} as const

export const TopicRole = {
  /** 活动-管理员 */
  DREAM_ADMIN: 'DREAM_ADMIN',
  /** 预审人 */
  DREAM_PRE_EXAMINER: 'DREAM_PRE_EXAMINER',
  /** 异动处理人 */
  DREAM_ALERT_USER: 'DREAM_ALERT_USER',
  /** 初审人-青工委 */
  DREAM_QGW_ROUND_A_VOTER: 'DREAM_QGW_ROUND_A_VOTER',
  /** 初审人-北京局 */
  DREAM_BJ_ROUND_A_VOTER: 'DREAM_BJ_ROUND_A_VOTER',
  /** 终审人-青工委 */
  DREAM_QGW_ROUND_B_VOTER: 'DREAM_QGW_ROUND_B_VOTER',
  /** 终审人-北京局 */
  DREAM_BJ_ROUND_B_VOTER: 'DREAM_BJ_ROUND_B_VOTER',
} as const

export const TopicVoteRound = {
  Prev: 'P',
  First: 'A',
  Last: 'B',
} as const

export const TOPIC_VOTE_ROUND_LABEL = {
  [TopicVoteRound.Prev]: '预审',
  [TopicVoteRound.First]: '初评',
  [TopicVoteRound.Last]: '终评',
} as const

export const TOPIC_VOTE_ROUND_LIST = [
  {
    value: TopicVoteRound.Prev,
    label: TOPIC_VOTE_ROUND_LABEL[TopicVoteRound.Prev],
  },
  {
    value: TopicVoteRound.First,
    label: TOPIC_VOTE_ROUND_LABEL[TopicVoteRound.First],
  },
  {
    value: TopicVoteRound.Last,
    label: TOPIC_VOTE_ROUND_LABEL[TopicVoteRound.Last],
  },
]

export const TopicClassifyRole = {
  DREAM__DREAM_ADMIN: 'DREAM__DREAM_ADMIN',
  DREAM__DREAM_PRE_EXAMINER: 'DREAM__DREAM_PRE_EXAMINER',
  DREAM__DREAM_ALERT_USER: 'DREAM__DREAM_ALERT_USER',
  A__DREAM_QGW_ROUND_A_VOTER: 'A__DREAM_QGW_ROUND_A_VOTER',
  A__DREAM_BJ_ROUND_A_VOTER: 'A__DREAM_BJ_ROUND_A_VOTER',
  A__DREAM_QGW_ROUND_B_VOTER: 'A__DREAM_QGW_ROUND_B_VOTER',
  A__DREAM_BJ_ROUND_B_VOTER: 'A__DREAM_BJ_ROUND_B_VOTER',
  B__DREAM_QGW_ROUND_A_VOTER: 'B__DREAM_QGW_ROUND_A_VOTER',
  B__DREAM_BJ_ROUND_A_VOTER: 'B__DREAM_BJ_ROUND_A_VOTER',
  B__DREAM_QGW_ROUND_B_VOTER: 'B__DREAM_QGW_ROUND_B_VOTER',
  B__DREAM_BJ_ROUND_B_VOTER: 'B__DREAM_BJ_ROUND_B_VOTER',
} as const

export const TOPIC_CLASSIFY_ROLE_LABEL = {
  DREAM__DREAM_ADMIN: '管理员',
  DREAM__DREAM_PRE_EXAMINER: '预审',
  DREAM__DREAM_ALERT_USER: '异动处理',
  A__DREAM_QGW_ROUND_A_VOTER: 'A组初评-青工委',
  A__DREAM_BJ_ROUND_A_VOTER: 'A组初评-北京局',
  A__DREAM_QGW_ROUND_B_VOTER: 'A组终评-青工委',
  A__DREAM_BJ_ROUND_B_VOTER: 'A组终评-北京局',
  B__DREAM_QGW_ROUND_A_VOTER: 'B组初评-青工委',
  B__DREAM_BJ_ROUND_A_VOTER: 'B组初评-北京局',
  B__DREAM_QGW_ROUND_B_VOTER: 'B组终评-青工委',
  B__DREAM_BJ_ROUND_B_VOTER: 'B组终评-北京局',
}

export type TopicClassifyRoleType = {
  label: string
  classify: ValueOf<typeof TopicScriptClassify>
  value: ValueOf<typeof TopicClassifyRole>
  role: ValueOf<typeof TopicRole>
  multiple: boolean
}

export const TOPIC_CLASSIFY_ROLE_LIST: TopicClassifyRoleType[] = [
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.DREAM__DREAM_ADMIN],
    classify: TopicScriptClassify.DREAM,
    value: TopicClassifyRole.DREAM__DREAM_ADMIN,
    role: TopicRole.DREAM_ADMIN,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.DREAM__DREAM_PRE_EXAMINER],
    classify: TopicScriptClassify.DREAM,
    value: TopicClassifyRole.DREAM__DREAM_PRE_EXAMINER,
    role: TopicRole.DREAM_PRE_EXAMINER,
    multiple: true,
  },
  // {
  //   label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.DREAM__DREAM_ALERT_USER],
  //   classify: TopicScriptClassify.DREAM,
  //   value: TopicClassifyRole.DREAM__DREAM_ALERT_USER,
  //   role: TopicRole.DREAM_ALERT_USER,
  //   multiple: true,
  // },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.A__DREAM_QGW_ROUND_A_VOTER],
    classify: TopicScriptClassify.A,
    value: TopicClassifyRole.A__DREAM_QGW_ROUND_A_VOTER,
    role: TopicRole.DREAM_QGW_ROUND_A_VOTER,
    multiple: true,
  },
  {
    label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.A__DREAM_BJ_ROUND_A_VOTER],
    classify: TopicScriptClassify.A,
    value: TopicClassifyRole.A__DREAM_BJ_ROUND_A_VOTER,
    role: TopicRole.DREAM_BJ_ROUND_A_VOTER,
    multiple: true,
  },
  // {
  //   label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.A__DREAM_QGW_ROUND_B_VOTER],
  //   classify: TopicScriptClassify.A,
  //   value: TopicClassifyRole.A__DREAM_QGW_ROUND_B_VOTER,
  //   role: TopicRole.DREAM_QGW_ROUND_B_VOTER,
  //   multiple: true,
  // },
  // {
  //   label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.A__DREAM_BJ_ROUND_B_VOTER],
  //   classify: TopicScriptClassify.A,
  //   value: TopicClassifyRole.A__DREAM_BJ_ROUND_B_VOTER,
  //   role: TopicRole.DREAM_BJ_ROUND_B_VOTER,
  //   multiple: true,
  // },
  // {
  //   label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.B__DREAM_QGW_ROUND_A_VOTER],
  //   classify: TopicScriptClassify.B,
  //   value: TopicClassifyRole.B__DREAM_QGW_ROUND_A_VOTER,
  //   role: TopicRole.DREAM_QGW_ROUND_A_VOTER,
  //   multiple: true,
  // },
  // {
  //   label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.B__DREAM_BJ_ROUND_A_VOTER],
  //   classify: TopicScriptClassify.B,
  //   value: TopicClassifyRole.B__DREAM_BJ_ROUND_A_VOTER,
  //   role: TopicRole.DREAM_BJ_ROUND_A_VOTER,
  //   multiple: true,
  // },
  // {
  //   label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.B__DREAM_QGW_ROUND_B_VOTER],
  //   classify: TopicScriptClassify.B,
  //   value: TopicClassifyRole.B__DREAM_QGW_ROUND_B_VOTER,
  //   role: TopicRole.DREAM_QGW_ROUND_B_VOTER,
  //   multiple: true,
  // },
  // {
  //   label: TOPIC_CLASSIFY_ROLE_LABEL[TopicClassifyRole.B__DREAM_BJ_ROUND_B_VOTER],
  //   classify: TopicScriptClassify.B,
  //   value: TopicClassifyRole.B__DREAM_BJ_ROUND_B_VOTER,
  //   role: TopicRole.DREAM_BJ_ROUND_B_VOTER,
  //   multiple: true,
  // },
]

export const TopicDramaStep = {
  /** 选题 */
  TOPIC_SELECTION: 'TOPIC_SELECTION',
  /** 剧本 */
  PLAYWRITING: 'PLAYWRITING',
  /** 拍摄 */
  SHOOTING: 'SHOOTING',
  /** 成片 */
  FILM: 'FILM',
} as const

export const TOPIC_DRAMA_STEP_LABEL = {
  [TopicDramaStep.TOPIC_SELECTION]: '选题阶段',
  [TopicDramaStep.PLAYWRITING]: '剧本阶段',
  [TopicDramaStep.SHOOTING]: '拍摄阶段',
  [TopicDramaStep.FILM]: '成片项目',
} as const

export const TOPIC_DRAMA_STEP_LIST = [
  {
    value: TopicDramaStep.TOPIC_SELECTION,
    label: TOPIC_DRAMA_STEP_LABEL[TopicDramaStep.TOPIC_SELECTION],
  },
  {
    value: TopicDramaStep.PLAYWRITING,
    label: TOPIC_DRAMA_STEP_LABEL[TopicDramaStep.PLAYWRITING],
  },
  {
    value: TopicDramaStep.SHOOTING,
    label: TOPIC_DRAMA_STEP_LABEL[TopicDramaStep.SHOOTING],
  },
  {
    value: TopicDramaStep.FILM,
    label: TOPIC_DRAMA_STEP_LABEL[TopicDramaStep.FILM],
  },
]

export const TopicVarietyStep = {
  /** 创意阶段 */
  CREATIVE: 'CREATIVE',
  /** 拍摄阶段/成片项目 */
  FILM: 'SHOOTING_OR_FILM',
} as const

export const TOPIC_VARIETY_STEP_LABEL = {
  [TopicVarietyStep.CREATIVE]: '创意阶段',
  [TopicVarietyStep.FILM]: '拍摄阶段/成片项目',
}

export const TOPIC_VARIETY_STEP_LIST = [
  {
    value: TopicVarietyStep.CREATIVE,
    label: TOPIC_VARIETY_STEP_LABEL[TopicVarietyStep.CREATIVE],
  },
  {
    value: TopicVarietyStep.FILM,
    label: TOPIC_VARIETY_STEP_LABEL[TopicVarietyStep.FILM],
  },
]

export const TOPIC_TYPE_NAME_STYLE: Record<string, React.CSSProperties> = {
  1: {
    color: '#E0E7FF',
    backgroundColor: '#4E3EE9',
  },
  2: {
    color: '#4D7C0F',
    backgroundColor: '#D9F99D',
  },
  3: {
    color: '#E0E7FF',
    backgroundColor: '#1F5EEF',
  },
  4: {
    color: '#FCE7F3',
    backgroundColor: '#DD2077',
  },
  5: {
    color: '#F759AC',
    backgroundColor: '#FEE5F2',
  },
  6: {
    color: '#4078FF',
    backgroundColor: '#E1EAFF',
  },
  7: {
    color: '#DD9B05',
    backgroundColor: '#FAF1D1',
  },
  8: {
    color: '#DD9B05',
    backgroundColor: '#FAF1D1',
  },
}

export const TopicScriptStep = {
  /** 草稿 */
  ROUND_DRAFT: 'ROUND_DRAFT',
  /** 待预审 */
  ROUND_P_WAITING: 'ROUND_P_WAITING',
  /** 预审中 */
  ROUND_P_PROCESS: 'ROUND_P_PROCESS',
  /** 预审淘汰 */
  ROUND_P_FAILED: 'ROUND_P_FAILED',
  /** 待初评 */
  ROUND_A_WAITING: 'ROUND_A_WAITING',
  /** 初评中 */
  ROUND_A_PROCESS: 'ROUND_A_PROCESS',
  /** 初评完成 */
  ROUND_A_END: 'ROUND_A_END',
  /** 管理员操作淘汰||正常评审淘汰 */
  ROUND_A_FAILED: 'ROUND_A_FAILED',
  /** 上传剧本 */
  ROUND_AUTHOR_PROCESS: 'ROUND_AUTHOR_PROCESS',
  /** 待终评 */
  ROUND_B_WAITING: 'ROUND_B_WAITING',
  /** 终评中 */
  ROUND_B_PROCESS: 'ROUND_B_PROCESS',
  /** 终评完成 */
  ROUND_B_END: 'ROUND_B_END',
  /** 终评通过 */
  ROUND_B_SUCCESS: 'ROUND_B_SUCCESS',
  /** 终评淘汰 */
  ROUND_B_FAILED: 'ROUND_B_FAILED',
  /** 终止淘汰 */
  TERMINATE_FAILED: 'TERMINATE_FAILED',
} as const

export const TopicScriptStepLabel = {
  [TopicScriptStep.ROUND_DRAFT]: '草稿',
  [TopicScriptStep.ROUND_P_WAITING]: '待预审',
  [TopicScriptStep.ROUND_P_PROCESS]: '预审中',
  [TopicScriptStep.ROUND_P_FAILED]: '预审未通过',
  [TopicScriptStep.ROUND_A_WAITING]: '待初评',
  [TopicScriptStep.ROUND_A_PROCESS]: '初评中',
  [TopicScriptStep.ROUND_A_END]: '初评完成',
  [TopicScriptStep.ROUND_A_FAILED]: '初评未通过',
  [TopicScriptStep.ROUND_AUTHOR_PROCESS]: '上传剧本',
  [TopicScriptStep.ROUND_B_WAITING]: '待终评',
  [TopicScriptStep.ROUND_B_PROCESS]: '终评中',
  [TopicScriptStep.ROUND_B_END]: '终评完成',
  [TopicScriptStep.ROUND_B_SUCCESS]: '终评通过',
  [TopicScriptStep.ROUND_B_FAILED]: '终评未通过',
  [TopicScriptStep.TERMINATE_FAILED]: '终止未通过',
}

export const TOPIC_SCRIPT_STEP_LIST = [
  {
    value: TopicScriptStep.ROUND_DRAFT,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_DRAFT],
  },
  {
    value: TopicScriptStep.ROUND_P_WAITING,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_P_WAITING],
  },
  {
    value: TopicScriptStep.ROUND_P_PROCESS,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_P_PROCESS],
  },
  {
    value: TopicScriptStep.ROUND_P_FAILED,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_P_FAILED],
  },
  {
    value: TopicScriptStep.ROUND_A_WAITING,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_A_WAITING],
  },
  {
    value: TopicScriptStep.ROUND_A_PROCESS,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_A_PROCESS],
  },
  {
    value: TopicScriptStep.ROUND_A_END,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_A_END],
  },
  {
    value: TopicScriptStep.ROUND_A_FAILED,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_A_FAILED],
  },
  {
    value: TopicScriptStep.ROUND_AUTHOR_PROCESS,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_AUTHOR_PROCESS],
  },
  {
    value: TopicScriptStep.ROUND_B_WAITING,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_B_WAITING],
  },
  {
    value: TopicScriptStep.ROUND_B_PROCESS,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_B_PROCESS],
  },
  {
    value: TopicScriptStep.ROUND_B_END,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_B_END],
  },
  {
    value: TopicScriptStep.ROUND_B_SUCCESS,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_B_SUCCESS],
  },
  {
    value: TopicScriptStep.ROUND_B_FAILED,
    label: TopicScriptStepLabel[TopicScriptStep.ROUND_B_FAILED],
  },
  // {
  //   value: TopicScriptStep.TERMINATE_FAILED,
  //   label: TopicScriptStepLabel[TopicScriptStep.TERMINATE_FAILED],
  // },
]

export const TOPIC_REPORT_SORT_LIST = [
  { value: 'DESC_createTime', label: '提交时间倒序' },
  { value: 'ASC_createTime', label: '提交时间正序' },
  { value: 'DESC_roundAEndTime', label: '初评时间倒序' },
  { value: 'ASC_roundAEndTime', label: '初评时间正序' },
  { value: 'DESC_roundBEndTime', label: '终评时间倒序' },
  { value: 'ASC_roundBEndTime', label: '终评时间正序' },
  { value: 'DESC_roundAScore', label: '初评分数倒序' },
  { value: 'ASC_roundAScore', label: '初评分数正序' },
  { value: 'DESC_roundBScore', label: '终评分数倒序' },
  { value: 'ASC_roundBScore', label: '终评分数正序' },
]
