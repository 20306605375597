import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconMessage(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
        <path d="M50.36 17.058c19.397 0 35.21 14.999 35.575 33.694l.007.662v29.481a3.14 3.14 0 0 1-6.265.303l-.014-.303V51.414c0-15.479-13.093-28.077-29.303-28.077-15.987 0-28.943 12.255-29.295 27.442l-.007.635v29.481a3.14 3.14 0 0 1-6.265.303l-.014-.303V51.414c0-19.002 15.957-34.356 35.581-34.356z" />
        <path d="M89.081 78.802a3.14 3.14 0 0 1 .303 6.265l-.303.014H11.64a3.14 3.14 0 0 1-.303-6.264l.303-.015H89.08zM50.36 4.5a3.14 3.14 0 0 1 3.126 2.837l.014.303v12.558a3.14 3.14 0 0 1-6.265.302l-.014-.302V7.64a3.14 3.14 0 0 1 3.14-3.14zM57.686 87.174a3.14 3.14 0 0 1 .302 6.265l-.302.014H45.128a3.14 3.14 0 0 1-.302-6.264l.302-.015h12.558z" />
      </svg>
    </BaseIcon>
  )
}
