import React from 'react'
import classnames from 'classnames'

import styles from './Radio.module.scss'

import { isEmpty } from '../../utils'

type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  children?: React.ReactNode
}

interface RadioGroupProps {
  disabled?: boolean
  defaultValue?: React.ReactText
  value?: React.ReactText
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  children: React.ReactElement[] | React.ReactElement
  className?: string
  prefixCls?: string
}

const Radio = ({ children, className, disabled, onChange, value, ...restProps }: RadioProps) => {
  function onChangeRadio(event: React.ChangeEvent<HTMLInputElement>) {
    if (typeof onChange === 'function') {
      onChange({
        ...event,
        target: {
          ...event.target,
          // event.target.value 是 string 类型，不符合我们要求
          // @ts-ignore
          value: isEmpty(value) ? event.target.value : value,
        },
      })
    }
  }

  return (
    <label
      className={classnames(styles['radio-wrapper'], className, {
        [styles['radio-wrapper-disabled']]: disabled,
      })}
    >
      <span className={styles.radio}>
        <input
          disabled={disabled}
          className={styles['radio-input']}
          type="radio"
          value={value}
          onChange={onChangeRadio}
          {...restProps}
        />
        <span className={styles['radio-inner']} />
      </span>
      {children ? <span className={styles['radio-content']}>{children}</span> : null}
    </label>
  )
}

function RadioGroup({
  disabled,
  defaultValue,
  value,
  onChange,
  children,
  className,
}: RadioGroupProps) {
  const [innerValue, setInnerValue] = React.useState<React.ReactText>()

  React.useEffect(() => {
    setInnerValue(value)
  }, [value])

  const onChangeCheckbox =
    (value: React.ReactText) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === 'function') {
        onChange(event)
      }
      setInnerValue(value)
    }

  return (
    <div className={classnames(styles['radio-group'], className)}>
      {React.Children.map(children, option => {
        const { props } = option
        // react报受控 非受控组件的错误
        // 不能同时设置defaultChecked和checked 合并 defaultValue 和 value
        const checked = isEmpty(innerValue)
          ? defaultValue === props.value
          : innerValue === props.value
        return React.cloneElement(option, {
          ...props,
          disabled: disabled || props.disabled,
          checked,
          onChange: onChangeCheckbox(props.value),
        })
      })}
    </div>
  )
}

Radio.Group = RadioGroup

export default Radio
