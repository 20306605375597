import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconPaper(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg
        width="52"
        height="54"
        viewBox="0 0 52 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_609_2)">
          <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="52" height="55">
            <path d="M.005.07h51.944V54H.005V.07z" fill="#fff" />
          </mask>
          <g mask="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#424242">
            <path d="M10.653 44.512c-.84 0-1.521-.697-1.521-1.557V11.758c-.077-1.645-1.436-2.919-3.044-2.854-1.609-.065-2.967 1.21-3.044 2.854v6.02c0 .86-.681 1.556-1.522 1.556-.84 0-1.522-.697-1.522-1.557v-6.02c.078-3.363 2.799-6.03 6.088-5.967 3.289-.063 6.01 2.604 6.088 5.968v31.197c0 .86-.682 1.557-1.523 1.557zm35.219 9.403H16.265c-.84 0-1.522-.697-1.522-1.557s.681-1.557 1.522-1.557h29.607a2.78 2.78 0 0 0 2.666-1.33 2.92 2.92 0 0 0 0-3.036 2.78 2.78 0 0 0-2.666-1.332h-6.26c-.841 0-1.523-.696-1.523-1.556 0-.86.682-1.557 1.522-1.557h6.26c2.141-.091 4.158 1.025 5.255 2.907a6.084 6.084 0 0 1 0 6.11c-1.096 1.883-3.114 3-5.254 2.908z" />
            <path d="M46.125 45.072h-26.31c-.84 0-1.521-.696-1.521-1.556 0-.86.681-1.557 1.522-1.557h26.31c.84 0 1.521.697 1.521 1.557s-.681 1.556-1.522 1.556zM15.24 53.998c-3.29.064-6.01-2.604-6.088-5.968V29.204c0-.86.681-1.557 1.522-1.557.84 0 1.522.697 1.522 1.557v18.785c.077 1.645 1.435 2.918 3.044 2.854.84 0 1.522.697 1.522 1.556 0 .86-.682 1.557-1.522 1.557v.042zm28.054-13.834c-.84 0-1.521-.698-1.521-1.557v-26.85c-.077-1.644-1.436-2.918-3.045-2.853-.84 0-1.521-.697-1.521-1.557s.681-1.557 1.521-1.557c3.29-.063 6.01 2.604 6.088 5.968v26.849c0 .86-.68 1.556-1.522 1.556z" />
            <path d="M38.78 8.914H6.077c-.841 0-1.522-.697-1.522-1.557s.68-1.556 1.522-1.556h32.701c.84 0 1.522.697 1.522 1.556 0 .86-.681 1.557-1.522 1.557zM15.827 53.998c-.84 0-1.522-.697-1.522-1.557s.682-1.557 1.522-1.557c1.852.077 3.418-1.386 3.511-3.28a3.144 3.144 0 0 0-1.076-2.366 1.587 1.587 0 0 1-.126-2.205 1.503 1.503 0 0 1 2.156-.13 6.341 6.341 0 0 1 2.11 4.702 6.628 6.628 0 0 1-1.992 4.59 6.335 6.335 0 0 1-4.583 1.803zM3.653 21.69C1.673 21.724.039 20.114 0 18.088c0-.859.682-1.556 1.522-1.556s1.522.697 1.522 1.556c.054.301.31.52.609.52a.623.623 0 0 0 .609-.52c0-.859.68-1.556 1.521-1.556.84 0 1.522.697 1.522 1.556-.039 2.026-1.672 3.636-3.652 3.602zm29.708-4.712H17.624c-.84 0-1.522-.697-1.522-1.557s.681-1.556 1.522-1.556h15.737c.84 0 1.522.697 1.522 1.556 0 .86-.681 1.557-1.522 1.557zm-6.067 7.016h-9.67c-.84 0-1.522-.697-1.522-1.557s.681-1.556 1.522-1.556h9.67c.84 0 1.522.697 1.522 1.556 0 .86-.682 1.557-1.522 1.557zM37.55 31.01H17.624c-.84 0-1.522-.697-1.522-1.557s.681-1.557 1.522-1.557h19.927c.841 0 1.523.697 1.523 1.557s-.682 1.557-1.523 1.557zm0 7.005H17.624c-.84 0-1.522-.697-1.522-1.556 0-.86.681-1.558 1.522-1.558h19.927c.841 0 1.523.698 1.523 1.557 0 .86-.682 1.557-1.523 1.557zm12.166-6.476c-.84 0-1.522-.697-1.522-1.557V6.04c-.077-1.644-1.435-2.918-3.044-2.854-.84 0-1.522-.697-1.522-1.557S44.311.072 45.151.072c3.29-.064 6.01 2.603 6.088 5.967v23.943c0 .86-.681 1.557-1.522 1.557z" />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.485 3h-26.97A1.507 1.507 0 0 1 17 1.5c0-.828.678-1.5 1.515-1.5h26.97C46.322 0 47 .672 47 1.5c0 .829-.678 1.5-1.515 1.5z"
            fill="#424242"
          />
        </g>
        <defs>
          <clipPath id="clip0_609_2">
            <path fill="#fff" d="M0 0h52v54H0z" />
          </clipPath>
        </defs>
      </svg>
    </BaseIcon>
  )
}
