import Prefix from '../constants/prefix'
import AsyncComponent from './AsyncComponent'

const adminPlayletRoutes = [
  {
    path: '/vote/:voteId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-playlet-vote" */ '../$$adminPlaylet/Vote')
    ),
  },
  {
    path: '/script/:scriptId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-playlet-script" */ '../$$adminPlaylet/Script')
    ),
  },
  {
    path: '/vote/result/:voteId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-playlet-vote-result" */ '../$$adminPlaylet/Vote')
    ),
  },
  {
    path: '/report',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-playlet-report" */ '../$$adminPlaylet/Report')
    ),
  },
  {
    path: '/recommend/report',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-playlet-recommend-report" */ '../$$adminPlaylet/other-report/RecommendReport'
        )
    ),
  },
  {
    path: '/task/report',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-playlet-task-report" */ '../$$adminPlaylet/other-report/TaskReport'
        )
    ),
  },
  {
    path: '/time/report',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-playlet-time-report" */ '../$$adminPlaylet/other-report/TimeReport'
        )
    ),
  },
  {
    path: '/audit/report',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-playlet-audit-report" */ '../$$adminPlaylet/other-report/AuditReport'
        )
    ),
  },
  {
    path: '/setting',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-playlet-setting" */ '../$$adminPlaylet/Setting')
    ),
  },
  {
    path: '/platform/script/:scriptId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-playlet-platform-script" */ '../$$admin/platform/PlayletScript'
        )
    ),
  },
]

export default [
  ...adminPlayletRoutes.map(item => ({
    ...item,
    path: `${Prefix.adminPlayletPrefix}${item.path}`,
  })),
]
