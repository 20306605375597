import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconStatActive(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
        <defs>
          <linearGradient id="a-stat" x1="1.959%" y1="0%" y2="92.675%">
            <stop offset="0%" stopColor="#F7CA0C" />
            <stop offset="100%" stopColor="#F7BB0C" />
          </linearGradient>
        </defs>
        <circle cx="63.954" cy="57.272" r="34.855" fill="url(#a-stat)" />
        <path d="M47.495 5.957c24.597 0 44.537 19.94 44.537 44.538a2.905 2.905 0 1 1-5.809 0c0-21.39-17.34-38.728-38.728-38.728-21.39 0-38.728 17.339-38.728 38.728 0 21.389 17.339 38.728 38.728 38.728 11.317 0 21.838-4.878 29.15-13.229a2.905 2.905 0 0 1 4.37 3.827c-8.403 9.598-20.51 15.211-33.52 15.211-24.597 0-44.538-19.94-44.538-44.537 0-24.597 19.94-44.538 44.538-44.538zm42.298 50.424a2.905 2.905 0 0 1 2.162 3.493 37.316 37.316 0 0 1-.865 3.077 39.072 39.072 0 0 1-3.321 7.394 2.905 2.905 0 0 1-5.028-2.909 33.265 33.265 0 0 0 2.829-6.295c.28-.858.525-1.724.73-2.598a2.905 2.905 0 0 1 3.493-2.162z" />
        <path d="M46.527 5.957c1.51 0 2.75 1.152 2.891 2.625l.013.28v39.696h39.697c1.51 0 2.75 1.152 2.891 2.625l.013.28c0 1.51-1.152 2.75-2.625 2.891l-.28.014H47.902c-2.413 0-4.13-2.006-4.27-4.307l-.009-.302V8.862c0-1.604 1.3-2.905 2.905-2.905z" />
      </svg>
    </BaseIcon>
  )
}
