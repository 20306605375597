import AdminFont from '../assets/font/admin.svg'
import PreExaminerFont from '../assets/font/pre-examiner.png'
import terminatorFont from '../assets/font/terminator.png'
import DramaRoundAVoter from '../assets/font/drama-round-a-voter.svg'
import DramaRoundBVoter from '../assets/font/drama-round-b-voter.svg'
import MovieRoundAVoter from '../assets/font/movie-round-a-voter.svg'
import MovieRoundBVoter from '../assets/font/movie-round-b-voter.svg'

export const HEADER_THEME_TYPE = {
  default: 'default',
  basic: 'basic',
  topic: 'topic',
  playlet: 'playlet',
} as const

export enum SettingCode {
  /** 初审随机人数 */
  ROUND_A_VOTER_NUM = 'ROUND_A_VOTER_NUM',
  /** 每月推送终审剧本数量 */
  ROUND_B_PUSH_NUM = 'ROUND_B_PUSH_NUM',
  /** 每月推送终审日期 */
  ROUND_B_PUSH_DAY = 'ROUND_B_PUSH_DAY',
  /** 活动提交开关 */
  EVENT_SUBMIT_SWITCH = 'EVENT_SUBMIT_SWITCH',
  /** 短剧提交开关 */
  PLAYLET_SUBMIT_SWITCH = 'PLAYLET_SUBMIT_SWITCH',
  /** 2023活动提交开关 */
  DREAM_SUBMIT_SWITCH = 'DREAM_SUBMIT_SWITCH',
}

export enum Role {
  /** 管理员 */
  ADMIN = 'ADMIN',
  /** 初审人 */
  ROUND_A_VOTER = 'ROUND_A_VOTER',
  /** 终审人 */
  ROUND_B_VOTER = 'ROUND_B_VOTER',
  /** 预审人（V2.5新增） */
  PRE_EXAMINER = 'PRE_EXAMINER',
  /** 终止人（V2.5新增） */
  TERMINATOR = 'TERMINATOR',
  /** 一键通过 */
  PASS = 'PASS',
  /** 外部合伙人（20220110新增） */
  PARTNER = 'PARTNER',
}

// const ROLE_LABEL = {
//   [Role.ADMIN]: '管理员',
//   [Role.ROUND_A_VOTER]: '初审人',
//   [Role.ROUND_B_VOTER]: '终审人',
//   [Role.PRE_EXAMINER]: '预审人',
//   [Role.TERMINATOR]: '终止人',
// }

// export const ROLE_LIST = [
//   {
//     value: Role.ADMIN,
//     label: ROLE_LABEL[Role.ADMIN],
//   },
//   {
//     value: Role.ROUND_A_VOTER,
//     label: ROLE_LABEL[Role.ROUND_A_VOTER],
//   },
//   {
//     value: Role.ROUND_B_VOTER,
//     label: ROLE_LABEL[Role.ROUND_B_VOTER],
//   },
// ]

export enum ClassifyRole {
  /** 管理员 */
  SYS_ADMIN = 'SYS_ADMIN',
  /** 终止人 */
  SYS_TERMINATOR = 'SYS_TERMINATOR',
  /** 一键通过 */
  SYS_PASS = 'SYS_PASS',
  /** 预审人 */
  SYS_PRE_EXAMINER = 'SYS_PRE_EXAMINER',
  /** 剧初审人 */
  DRAMA_ROUND_A_VOTER = 'DRAMA_ROUND_A_VOTER',
  /** 剧终审人 */
  DRAMA_ROUND_B_VOTER = 'DRAMA_ROUND_B_VOTER',
  /** 网大初审人 */
  MOVIE_ROUND_A_VOTER = 'MOVIE_ROUND_A_VOTER',
  /** 网大终审人 */
  MOVIE_ROUND_B_VOTER = 'MOVIE_ROUND_B_VOTER',
}

export const CLASSIFY_ROLE_LABEL = {
  [ClassifyRole.SYS_ADMIN]: '管理员',
  [ClassifyRole.SYS_TERMINATOR]: '终止人',
  [ClassifyRole.SYS_PASS]: '一键通过',
  [ClassifyRole.SYS_PRE_EXAMINER]: '预审人',
  [ClassifyRole.DRAMA_ROUND_A_VOTER]: '剧初审人',
  [ClassifyRole.DRAMA_ROUND_B_VOTER]: '剧终审人',
  [ClassifyRole.MOVIE_ROUND_A_VOTER]: '网大初审人',
  [ClassifyRole.MOVIE_ROUND_B_VOTER]: '网大终审人',
}

export enum ScriptClassify {
  /** 系统 */
  SYS = 'SYS',
  /** 剧 */
  DRAMA = 'DRAMA',
  /** 网大 */
  MOVIE = 'MOVIE',
}

export type ClassifyRoleType = {
  label: string
  classify: ScriptClassify
  value: ClassifyRole
  role: Role
  multiple: boolean
}

export const CLASSIFY_ROLE_LIST: ClassifyRoleType[] = [
  {
    label: CLASSIFY_ROLE_LABEL[ClassifyRole.SYS_ADMIN],
    classify: ScriptClassify.SYS,
    value: ClassifyRole.SYS_ADMIN,
    role: Role.ADMIN,
    multiple: true,
  },
  {
    label: CLASSIFY_ROLE_LABEL[ClassifyRole.SYS_TERMINATOR],
    classify: ScriptClassify.SYS,
    value: ClassifyRole.SYS_TERMINATOR,
    role: Role.TERMINATOR,
    multiple: true,
  },
  {
    label: CLASSIFY_ROLE_LABEL[ClassifyRole.SYS_PASS],
    classify: ScriptClassify.SYS,
    value: ClassifyRole.SYS_PASS,
    role: Role.PASS,
    multiple: true,
  },
  {
    label: CLASSIFY_ROLE_LABEL[ClassifyRole.SYS_PRE_EXAMINER],
    classify: ScriptClassify.SYS,
    value: ClassifyRole.SYS_PRE_EXAMINER,
    role: Role.PRE_EXAMINER,
    multiple: true,
  },
  {
    label: CLASSIFY_ROLE_LABEL[ClassifyRole.DRAMA_ROUND_A_VOTER],
    classify: ScriptClassify.DRAMA,
    value: ClassifyRole.DRAMA_ROUND_A_VOTER,
    role: Role.ROUND_A_VOTER,
    multiple: true,
  },
  {
    label: CLASSIFY_ROLE_LABEL[ClassifyRole.DRAMA_ROUND_B_VOTER],
    classify: ScriptClassify.DRAMA,
    value: ClassifyRole.DRAMA_ROUND_B_VOTER,
    role: Role.ROUND_B_VOTER,
    multiple: true,
  },
  {
    label: CLASSIFY_ROLE_LABEL[ClassifyRole.MOVIE_ROUND_A_VOTER],
    classify: ScriptClassify.MOVIE,
    value: ClassifyRole.MOVIE_ROUND_A_VOTER,
    role: Role.ROUND_A_VOTER,
    multiple: true,
  },
  {
    label: CLASSIFY_ROLE_LABEL[ClassifyRole.MOVIE_ROUND_B_VOTER],
    classify: ScriptClassify.MOVIE,
    value: ClassifyRole.MOVIE_ROUND_B_VOTER,
    role: Role.ROUND_B_VOTER,
    multiple: true,
  },
]

export const CLASSIFY_ROLE_FONT: Record<string, string> = {
  [ClassifyRole.SYS_ADMIN]: AdminFont,
  [ClassifyRole.SYS_TERMINATOR]: terminatorFont,
  [ClassifyRole.SYS_PRE_EXAMINER]: PreExaminerFont,
  [ClassifyRole.DRAMA_ROUND_A_VOTER]: DramaRoundAVoter,
  [ClassifyRole.DRAMA_ROUND_B_VOTER]: DramaRoundBVoter,
  [ClassifyRole.MOVIE_ROUND_A_VOTER]: MovieRoundAVoter,
  [ClassifyRole.MOVIE_ROUND_B_VOTER]: MovieRoundBVoter,
}

export enum VoteRound {
  Prev = 'P0',
  First = 'A1',
  Second = 'A2',
  Last = 'B',
}

export const VOTE_ROUND_LABEL: Record<VoteRound, string> = {
  [VoteRound.Prev]: '预审',
  [VoteRound.First]: '一轮初审',
  [VoteRound.Second]: '二轮初审',
  [VoteRound.Last]: '终审',
}

export const VOTE_ROUND_LIST = [
  {
    value: VoteRound.Prev,
    label: VOTE_ROUND_LABEL[VoteRound.Prev],
  },
  {
    value: VoteRound.First,
    label: VOTE_ROUND_LABEL[VoteRound.First],
  },
  {
    value: VoteRound.Second,
    label: VOTE_ROUND_LABEL[VoteRound.Second],
  },
  {
    value: VoteRound.Last,
    label: VOTE_ROUND_LABEL[VoteRound.Last],
  },
]

export enum ApplyRound {
  First = 'A',
  Last = 'B',
}

const APPLY_ROUND = {
  [ApplyRound.First]: '初审',
  [ApplyRound.Last]: '终审',
}

const APPLY_ROUND_LIST = [
  {
    label: APPLY_ROUND[ApplyRound.First],
    value: ApplyRound.First,
  },
  {
    label: APPLY_ROUND[ApplyRound.Last],
    value: ApplyRound.Last,
  },
]

export const APPLY_ROUND_FULL_LIST = [{ label: '全部', value: '' }, ...APPLY_ROUND_LIST]

export const ReportSorts = [
  { value: 'DESC_createTime', label: '提交时间倒序' },
  { value: 'ASC_createTime', label: '提交时间正序' },
  { value: 'DESC_roundAEndTime', label: '初审时间倒序' },
  { value: 'ASC_roundAEndTime', label: '初审时间正序' },
  { value: 'DESC_roundBEndTime', label: '终审时间倒序' },
  { value: 'ASC_roundBEndTime', label: '终审时间正序' },
]

export const RecommendReportSorts = [
  { value: 'DESC_roundAGoodTime', label: '推荐时间倒序' },
  { value: 'ASC_roundAGoodTime', label: '推荐时间正序' },
  ...ReportSorts,
]

export enum TaskCreateSort {
  DESC = 'DESC_createTime',
  ASC = 'ASC_createTime',
}

const TASK_CREATE_SORT_LABEL = {
  [TaskCreateSort.DESC]: '按任务创建时间倒序',
  [TaskCreateSort.ASC]: '按任务创建时间正序',
}

export const TASK_CREATE_SORT_LIST = [
  {
    value: TaskCreateSort.DESC,
    label: TASK_CREATE_SORT_LABEL[TaskCreateSort.DESC],
  },
  {
    value: TaskCreateSort.ASC,
    label: TASK_CREATE_SORT_LABEL[TaskCreateSort.ASC],
  },
]

export enum TaskFinishSort {
  DESC = 'DESC_voteTime',
  ASC = 'ASC_voteTime',
}

const TASK_FINISH_SORT_LABEL = {
  [TaskFinishSort.DESC]: '按任务完成时间倒序',
  [TaskFinishSort.ASC]: '按任务完成时间正序',
}

export const TASK_FINISH_SORT_LIST = [
  {
    value: TaskFinishSort.DESC,
    label: TASK_FINISH_SORT_LABEL[TaskFinishSort.DESC],
  },
  {
    value: TaskFinishSort.ASC,
    label: TASK_FINISH_SORT_LABEL[TaskFinishSort.ASC],
  },
]

export enum ScriptStep {
  /** 草稿 */
  ROUND_DRAFT = 'ROUND_DRAFT',
  /** 待预审 */
  ROUND_P_WAITING = 'ROUND_P_WAITING',
  /** 预审中 */
  ROUND_P_PROCESS = 'ROUND_P_PROCESS',
  /** 预审通过 */
  ROUND_P_SUCCESS = 'ROUND_P_SUCCESS',
  /** 预审未通过 */
  ROUND_P_FAILED = 'ROUND_P_FAILED',
  /** 待初审 */
  ROUND_A_WAITING = 'ROUND_A_WAITING',
  /** 初审中 */
  ROUND_A_PROCESS = 'ROUND_A_PROCESS',
  /** 初审通过 */
  ROUND_A_SUCCESS = 'ROUND_A_SUCCESS',
  /** 初审未通过 */
  ROUND_A_FAILED = 'ROUND_A_FAILED',
  /** 终审中 */
  ROUND_B_PROCESS = 'ROUND_B_PROCESS',
  /** 终审完成 */
  ROUND_B_END = 'ROUND_B_END',
  /** 终审通过 */
  FINAL_SUCCESS = 'FINAL_SUCCESS',
  /** 终审未通过 */
  FINAL_FAILED = 'FINAL_FAILED',
  /** 终止未通过 */
  TERMINATE_FAILED = 'TERMINATE_FAILED',
}

export const ScriptStepLabel = {
  [ScriptStep.ROUND_DRAFT]: '草稿',
  [ScriptStep.ROUND_P_WAITING]: '待预审',
  [ScriptStep.ROUND_P_PROCESS]: '预审中',
  [ScriptStep.ROUND_P_SUCCESS]: '预审通过',
  [ScriptStep.ROUND_P_FAILED]: '预审未通过',
  [ScriptStep.ROUND_A_WAITING]: '待初审',
  [ScriptStep.ROUND_A_PROCESS]: '初审中',
  [ScriptStep.ROUND_A_SUCCESS]: '初审通过',
  [ScriptStep.ROUND_A_FAILED]: '初审未通过',
  [ScriptStep.ROUND_B_PROCESS]: '终审中',
  [ScriptStep.ROUND_B_END]: '终审完成',
  [ScriptStep.FINAL_SUCCESS]: '终审通过',
  [ScriptStep.FINAL_FAILED]: '终审未通过',
  [ScriptStep.TERMINATE_FAILED]: '终止未通过',
}

export const SCRIPT_STEP_LIST = [
  // {
  //   value: ScriptStep.ROUND_P_WAITING,
  //   label: ScriptStepLabel[ScriptStep.ROUND_P_WAITING],
  // },
  {
    value: ScriptStep.ROUND_DRAFT,
    label: ScriptStepLabel[ScriptStep.ROUND_DRAFT],
  },
  {
    value: ScriptStep.ROUND_P_PROCESS,
    label: ScriptStepLabel[ScriptStep.ROUND_P_PROCESS],
  },
  {
    value: ScriptStep.ROUND_P_SUCCESS,
    label: ScriptStepLabel[ScriptStep.ROUND_P_SUCCESS],
  },
  {
    value: ScriptStep.ROUND_P_FAILED,
    label: ScriptStepLabel[ScriptStep.ROUND_P_FAILED],
  },
  // {
  //   value: ScriptStep.ROUND_A_WAITING,
  //   label: ScriptStepLabel[ScriptStep.ROUND_A_WAITING],
  // },
  {
    value: ScriptStep.ROUND_A_PROCESS,
    label: ScriptStepLabel[ScriptStep.ROUND_A_PROCESS],
  },
  {
    value: ScriptStep.ROUND_A_SUCCESS,
    label: ScriptStepLabel[ScriptStep.ROUND_A_SUCCESS],
  },
  {
    value: ScriptStep.ROUND_A_FAILED,
    label: ScriptStepLabel[ScriptStep.ROUND_A_FAILED],
  },
  {
    value: ScriptStep.ROUND_B_PROCESS,
    label: ScriptStepLabel[ScriptStep.ROUND_B_PROCESS],
  },
  {
    value: ScriptStep.ROUND_B_END,
    label: ScriptStepLabel[ScriptStep.ROUND_B_END],
  },
  {
    value: ScriptStep.FINAL_SUCCESS,
    label: ScriptStepLabel[ScriptStep.FINAL_SUCCESS],
  },
  {
    value: ScriptStep.FINAL_FAILED,
    label: ScriptStepLabel[ScriptStep.FINAL_FAILED],
  },
  {
    value: ScriptStep.TERMINATE_FAILED,
    label: ScriptStepLabel[ScriptStep.TERMINATE_FAILED],
  },
]

export const SCRIPT_TIME_STEP_LIST = [
  {
    value: ScriptStep.ROUND_A_SUCCESS,
    label: ScriptStepLabel[ScriptStep.ROUND_A_SUCCESS],
  },
  {
    value: ScriptStep.ROUND_A_FAILED,
    label: ScriptStepLabel[ScriptStep.ROUND_A_FAILED],
  },
  {
    value: ScriptStep.ROUND_B_END,
    label: ScriptStepLabel[ScriptStep.ROUND_B_END],
  },
  {
    value: ScriptStep.FINAL_SUCCESS,
    label: ScriptStepLabel[ScriptStep.FINAL_SUCCESS],
  },
  {
    value: ScriptStep.FINAL_FAILED,
    label: ScriptStepLabel[ScriptStep.FINAL_FAILED],
  },
  {
    value: ScriptStep.TERMINATE_FAILED,
    label: ScriptStepLabel[ScriptStep.TERMINATE_FAILED],
  },
]

export enum AlertType {
  RoundA1Alert = 'roundA1Alert',
  RoundA2Alert = 'roundA2Alert',
}

const ALERT_TYPE_LABEL = {
  [AlertType.RoundA1Alert]: '一轮异动',
  [AlertType.RoundA2Alert]: '二轮异动',
}

export const ALERT_TYPE_LIST = [
  {
    value: AlertType.RoundA1Alert,
    label: ALERT_TYPE_LABEL[AlertType.RoundA1Alert],
  },
  {
    value: AlertType.RoundA2Alert,
    label: ALERT_TYPE_LABEL[AlertType.RoundA2Alert],
  },
]

export enum AgreementType {
  Register = 1,
  Promise,
}

export const YesOrNo = {
  No: 0,
  Yes: 1,
} as const

export type YesOrNoType = ValueOf<typeof YesOrNo>

export const TYPE_NAME_STYLE: Record<string, React.CSSProperties> = {
  剧: {
    color: '#F759AC',
    backgroundColor: '#FEE5F2',
  },
  短剧集: {
    color: '#DD9B05',
    backgroundColor: '#FAF1D1',
  },
  '电影/网络电影': {
    color: '#4078FF',
    backgroundColor: '#E1EAFF',
  },
}

export const TYPE_NAME_UNKNOWN: React.CSSProperties = {
  backgroundColor: 'rgba(#f7ca0c, 0.6)',
}

export const ADMIN_USER_TYPE = {
  INSIDE: 0,
  EXTERNAL: 1,
  TOPIC: 2,
} as const

export const IDCARD_TYPE = {
  CRED_PSN_CH_IDCARD: 'CRED_PSN_CH_IDCARD',
  CRED_PSN_CH_TWCARD: 'CRED_PSN_CH_TWCARD',
  CRED_PSN_CH_MACAO: 'CRED_PSN_CH_MACAO',
  CRED_PSN_CH_HONGKONG: 'CRED_PSN_CH_HONGKONG',
} as const

export const IDCARD_TYPE_LABEL = {
  [IDCARD_TYPE.CRED_PSN_CH_IDCARD]: '大陆身份证',
  [IDCARD_TYPE.CRED_PSN_CH_TWCARD]: '台湾来往大陆通行证',
  [IDCARD_TYPE.CRED_PSN_CH_MACAO]: '澳门来往大陆通行证',
  [IDCARD_TYPE.CRED_PSN_CH_HONGKONG]: '香港来往大陆通行证',
} as const

export const IDCARD_TYPE_LIST = [
  {
    value: IDCARD_TYPE.CRED_PSN_CH_IDCARD,
    label: IDCARD_TYPE_LABEL[IDCARD_TYPE.CRED_PSN_CH_IDCARD],
  },
  {
    value: IDCARD_TYPE.CRED_PSN_CH_TWCARD,
    label: IDCARD_TYPE_LABEL[IDCARD_TYPE.CRED_PSN_CH_TWCARD],
  },
  {
    value: IDCARD_TYPE.CRED_PSN_CH_MACAO,
    label: IDCARD_TYPE_LABEL[IDCARD_TYPE.CRED_PSN_CH_MACAO],
  },
  {
    value: IDCARD_TYPE.CRED_PSN_CH_HONGKONG,
    label: IDCARD_TYPE_LABEL[IDCARD_TYPE.CRED_PSN_CH_HONGKONG],
  },
]
