import { Role } from '../constants'
import { PlayletRole } from '../constants/playlet'
import { TopicRole } from '../constants/topic'
import { TopicRole as TopicRole2023 } from '../constants/topic-2023'

import { useAdminEnvContext } from '../context'

function getBasicRole(currentRoles: string[]) {
  return {
    isAdmin: currentRoles.includes(Role.ADMIN),
    isRoundAVoter: currentRoles.includes(Role.ROUND_A_VOTER),
    isRoundBVoter: currentRoles.includes(Role.ROUND_B_VOTER),
    isPreExaminer: currentRoles.includes(Role.PRE_EXAMINER),
    isTerminator: currentRoles.includes(Role.TERMINATOR),
    isPass: currentRoles.includes(Role.PASS),
  }
}

function getTopicRole(currentRoles: string[]) {
  return {
    isTopicAdmin: currentRoles.includes(TopicRole.EVENT_ADMIN),
    isTopicPreExaminer: currentRoles.includes(TopicRole.EVENT_PRE_EXAMINER),
    isTopicQgwAVoter: currentRoles.includes(TopicRole.EVENT_QGW_ROUND_A_VOTER),
    isTopicBjAVoter: currentRoles.includes(TopicRole.EVENT_BJ_ROUND_A_VOTER),
    isTopicQgwBVoter: currentRoles.includes(TopicRole.EVENT_QGW_ROUND_B_VOTER),
    isTopicBjBVoter: currentRoles.includes(TopicRole.EVENT_BJ_ROUND_B_VOTER),
    isTopicAlertUser: currentRoles.includes(TopicRole.EVENT_ALERT_USER),
  }
}

function getTopic2023Role(currentRoles: string[]) {
  return {
    isTopic2023Admin: currentRoles.includes(TopicRole2023.DREAM_ADMIN),
    isTopic2023PreExaminer: currentRoles.includes(TopicRole2023.DREAM_PRE_EXAMINER),
    isTopic2023QgwAVoter: currentRoles.includes(TopicRole2023.DREAM_QGW_ROUND_A_VOTER),
    isTopic2023BjAVoter: currentRoles.includes(TopicRole2023.DREAM_BJ_ROUND_A_VOTER),
    isTopic2023QgwBVoter: currentRoles.includes(TopicRole2023.DREAM_QGW_ROUND_B_VOTER),
    isTopic2023BjBVoter: currentRoles.includes(TopicRole2023.DREAM_BJ_ROUND_B_VOTER),
  }
}

function getPlayletRole(currentRoles: string[]) {
  return {
    isPlayletPreExaminer: currentRoles.includes(PlayletRole.PLAYLET_PRE_EXAMINER),
    isPlayletAVoter: currentRoles.includes(PlayletRole.PLAYLET_ROUND_A_VOTER),
    isPlayletBVoter: currentRoles.includes(PlayletRole.PLAYLET_ROUND_B_VOTER),
  }
}

function useAdminRole() {
  const { currentRoles } = useAdminEnvContext()
  const { isAdmin, isRoundAVoter, isRoundBVoter, isPreExaminer, isTerminator, isPass } =
    getBasicRole(currentRoles)

  /** 青春中国梦活动 start */
  const {
    isTopicAdmin,
    isTopicPreExaminer,
    isTopicQgwAVoter,
    isTopicBjAVoter,
    isTopicQgwBVoter,
    isTopicBjBVoter,
    isTopicAlertUser,
  } = getTopicRole(currentRoles)
  const isTopicVoter =
    isTopicPreExaminer || isTopicQgwAVoter || isTopicBjAVoter || isTopicQgwBVoter || isTopicBjBVoter
  /** 青春中国梦活动 end */

  /** 青春中国梦2023活动 start */
  const {
    isTopic2023Admin,
    isTopic2023PreExaminer,
    isTopic2023QgwAVoter,
    isTopic2023BjAVoter,
    isTopic2023QgwBVoter,
    isTopic2023BjBVoter,
  } = getTopic2023Role(currentRoles)
  const isTopic2023Voter =
    isTopic2023PreExaminer ||
    isTopic2023QgwAVoter ||
    isTopic2023BjAVoter ||
    isTopic2023QgwBVoter ||
    isTopic2023BjBVoter
  /** 青春中国梦2023活动 end */

  /** 微短剧 start */
  const { isPlayletPreExaminer, isPlayletAVoter, isPlayletBVoter } = getPlayletRole(currentRoles)
  const isPlayletVoter = isPlayletPreExaminer || isPlayletAVoter || isPlayletBVoter
  /** 微短剧 end */

  // 可以进统计页面（包括剧本详情、作者详情）
  const isReportAdmin = isAdmin || isRoundBVoter || isTerminator || isPass || isPreExaminer

  // 青春中国梦 统计页面
  const isTopicReportAdmin = isAdmin || isTopicAdmin || isTopicPreExaminer || isTopicAlertUser

  // 青春中国梦2023 统计页面
  const isTopic2023ReportAdmin = isAdmin || isTopic2023Admin || isTopic2023PreExaminer

  // 微短剧统计页面
  const isPlayletReportAdmin = isAdmin || isPlayletPreExaminer

  const isBasicVoter = isRoundAVoter || isRoundBVoter || isPreExaminer

  const isPartner = currentRoles.includes(Role.PARTNER)

  const isVoter = isBasicVoter || isTopicVoter || isTopic2023Voter || isPlayletVoter

  return {
    isAdmin,
    isRoundAVoter,
    isRoundBVoter,
    isTerminator,
    isReportAdmin,
    isBasicVoter,
    isPass,
    isPartner,

    isVoter,

    isTopicVoter,
    isTopicReportAdmin,
    isTopic2023Voter,
    isTopic2023ReportAdmin,
    isTopicAlertUser,

    isPlayletVoter,
    isPlayletReportAdmin,
  }
}

export default useAdminRole
