// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconPhone.module.less'

export default function IconPhone(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M510.76956815 761.19148082c-34.70331443 0-62.8726114 27.96943554-62.8726114 62.45167418s28.16929699 62.45167419 62.8726114 62.45167419c34.73234456 0 62.87149486-27.96943554 62.87149486-62.45167419s-28.13915029-62.45167419-62.87149486-62.45167418zM762.25331448 11.68318357h-502.96972577c-52.0834435 0-94.30556747 41.94298787-94.30556747 93.69258463v811.9588549c0 51.7227997 42.22212396 93.66578757 94.30668403 93.66578755h502.96972575c52.08567662 0 94.30556747-41.94298787 94.30556748-93.66578755V105.37688474c0-51.74959676-42.21989085-93.69258462-94.30668402-93.69258462z m31.43630572 874.41052909c0 34.48223864-28.1369172 62.45390727-62.87149487 62.45390726h-440.09711435c-34.70443096 0-62.8726114-27.97166862-62.87261143-62.45279073V136.58764849c0-34.48112209 28.16818044-62.45279074 62.87261143-62.45279073h440.09711435c34.73346112 0 62.8726114 27.96943554 62.87261139 62.45279073v749.5071807z m-220.04855719-749.50606417h-125.74522282c-17.35109893 0-31.43295606 14.0003494-31.43295605 31.2397939 0 17.24056106 14.08185713 31.23979389 31.43407261 31.23979389h125.74410626c17.35109893 0 31.4363057-13.99811631 31.43630569-31.24091044 0-17.23944451-14.08409021-31.23867735-31.43630569-31.23867735z"></path>
      </svg>
    </BaseIcon>
  )
}
