// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconSmiling.module.less'

export default function IconSmiling(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M512 8a504 504 0 1 1 0 1008A504 504 0 0 1 512 8z m0 100.8a403.2 403.2 0 1 0 0 806.4 403.2 403.2 0 0 0 0-806.4z" />
        <path d="M612.8 562.4a50.4 50.4 0 0 1 50.4 50.4 151.2 151.2 0 0 1-302.4 0 50.4 50.4 0 0 1 99.9936-9.072L461.6 612.8a50.4 50.4 0 0 0 99.9936 9.072L562.4 612.8a50.4 50.4 0 0 1 50.4-50.4z" />
      </svg>
    </BaseIcon>
  )
}
