import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function DislikeOutlined(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M988.83052969 598.55094377c-21.55198533 34.70041141-58.93924902 56.36673087-97.29835291 57.51007228l-237.92934474 1.14334137a432.46888347 432.46888347 0 0 1 17.26445509 216.94903013l-4.45903144 19.15096839a119.76501134 119.76501134 0 0 1-117.24966028 93.29665801 120.6225174 120.6225174 0 0 1-119.93651256-131.25559237c-3.25852298-117.82133098-145.0900233-239.41568856-193.33903026-239.41568858h-0.05716708l-176.70341294-0.11433414a42.01779635 42.01779635 0 0 1-41.90346218-41.90346221l-0.45733657-494.49515428c0-23.43849864 20.29430981-40.5886196 45.73365589-41.27462441l153.20774723 0.28583534 5.88820818-1.02900725 5.14503628 0.68600482 583.10411256-0.22866828c20.52297808 1.42917676 40.30278424 7.83188857 57.4529052 18.75079893 18.57929772 11.9479176 33.55707 28.69786907 40.35995132 43.50414015l7.14588375 11.89075053c40.18845011 95.5833408 89.18062897 388.50740675 91.18147641 400.85549386l0.80033897 11.89075053a121.19418809 121.19418809 0 0 1-17.95045992 73.74552011zM88.6778477 544.01355912l95.29750546 0.11433414V109.5438282L88.39201234 109.20082577l0.34300243 434.81273335z m762.43721068-400.05515486l-4.45903143-7.08871666a50.36418854 50.36418854 0 0 0-17.89329286-20.00847445 48.76351058 48.76351058 0 0 0-21.32331705-7.54605323l-551.9480595 0.22866828v438.41425874C349.64552161 566.30871637 496.56489114 710.36973241 500.56658604 858.88977991l-0.40016951 7.26021787c0 27.04002406 21.89498775 49.04934594 48.93501181 49.04934593a48.59200938 48.59200938 0 0 0 47.67733627-37.95893438l4.00169488-17.03578682a359.58086941 359.58086941 0 0 0-15.03493939-180.07627005l-32.87106515-94.21133113 325.45212871-0.11433415 7.71755442-0.74317191a51.67903115 51.67903115 0 0 0 42.07496341-24.29600469c5.65953991-9.14673118 8.23205807-19.66547203 7.37455202-36.1867552-0.51450364-2.85835349-49.6781837-296.92576084-84.37859513-380.56118406z"></path>
      </svg>
    </BaseIcon>
  )
}
