import React from 'react'

import { PlacementType } from './type'

interface Props {
  showOverlay: boolean
  getStyle?: () => React.CSSProperties
  placement: PlacementType
  childrenRef: React.RefObject<HTMLElement>
  container: HTMLElement
  style?: React.CSSProperties
  unbeautifulHeight: number
}

function useOverlayStyle({
  showOverlay,
  childrenRef,
  container,
  placement,
  style,
  getStyle,
  unbeautifulHeight,
}: Props) {
  const overlayStyle = React.useMemo(() => {
    // 计算overlay 定位的位置
    if (!showOverlay || !childrenRef.current) {
      return {}
    }
    const childrenDOMRect = childrenRef.current.getBoundingClientRect()
    const containerDOMRect = container.getBoundingClientRect()
    /**
     * left/top 元素与视口区域距离 不包括滚动距离
     * https://developer.mozilla.org/zh-CN/docs/Web/API/Element/getBoundingClientRect
     */
    const {
      left: childrenLeft,
      top: childrenTop,
      width: childrenWidth,
      height: childrenHeight,
    } = childrenDOMRect
    const { left: containerLeft, top: containerTop, width: containerWidth } = containerDOMRect
    const { scrollTop: containerScrollTop, scrollLeft: containerScrollLeft } = container
    const innerStyle: React.CSSProperties = {}
    if (['bottomLeft', 'topLeft'].includes(placement)) {
      /**
       * 上/下靠左浮层
       * 设置left属性
       * 包裹元素与左侧距离 - 渲染父节点与左侧距离 + 渲染父节点左侧滚动距离
       */
      innerStyle.left = Math.round(childrenLeft - containerLeft + containerScrollLeft)
    }
    if (['bottomCenter', 'topCenter'].includes(placement)) {
      /**
       * 上/下居中浮层
       * 设置left属性
       * 包裹元素与左侧距离 - 渲染父节点与左侧距离 + 渲染父节点左侧滚动距离 + 包裹元素宽度一半
       * 为了使left值在包裹元素中心点 css设置 translateX(-50%)，刚好居中
       */
      innerStyle.left = Math.round(
        childrenLeft - containerLeft + containerScrollLeft + childrenWidth / 2
      )
    }
    if (['bottomRight', 'topRight'].includes(placement)) {
      /**
       * 上/下靠右浮层
       * 设置right属性
       * 渲染父节点宽度 + 渲染父节点与左侧距离 - 包裹元素与左侧距离 - 包裹元素宽度 + 渲染父节点左侧滚动距离
       *
       * 比较难理解，修改的话，先画图
       */
      innerStyle.right = Math.round(
        containerWidth + containerLeft - childrenLeft - childrenWidth + containerScrollLeft
      )
    }
    if (['bottomLeft', 'bottomCenter', 'bottomRight'].includes(placement)) {
      /**
       * 下方所有浮层
       * 设置top属性
       * 包裹元素与顶部距离 - 渲染父节点与顶部距离 + 包裹元素高度 + 渲染父节点顶部滚动距离 + overlay与children的距离
       *
       * 比较难理解，修改的话，先画图
       */
      innerStyle.top = Math.round(
        childrenTop - containerTop + childrenHeight + containerScrollTop + unbeautifulHeight
      )
    }
    if (['topLeft', 'topCenter', 'topRight'].includes(placement)) {
      /**
       * 上方所有浮层
       * 设置top属性
       * 包裹元素与顶部距离 - 渲染父节点与顶部距离 + 渲染父节点顶部滚动距离 - overlay与children的距离
       * topCenter 另外设置translateY(-100%)
       *
       * 比较难理解，修改的话，先画图
       */
      innerStyle.top = Math.round(
        childrenTop - containerTop + containerScrollTop - unbeautifulHeight
      )
    }
    return {
      ...(typeof getStyle === 'function' ? getStyle() : {}),
      ...style,
      ...innerStyle,
    }
  }, [childrenRef, container, getStyle, placement, showOverlay, style, unbeautifulHeight])

  return overlayStyle
}

export default useOverlayStyle
