import React from 'react'
import { createPortal } from 'react-dom'

type PortalProps = React.PropsWithChildren<{
  holder?: React.ReactNode
}>

type HolderProps = {
  height: number
}

export function PortalHolder({ height }: HolderProps) {
  return <div style={{ height, minHeight: height }} />
}

export default function Portal({ children, holder }: PortalProps) {
  const rootRef = React.useRef(document.createElement('div'))

  React.useEffect(() => {
    const root = rootRef.current

    document.body.appendChild(root)

    return () => {
      document.body.removeChild(root)
    }
  }, [])

  return createPortal(
    <>
      {children}
      {holder}
    </>,
    rootRef.current
  )
}
