// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconPlusCircleFilled.module.less'

export default function IconPlusCircleFilled(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M512 8c278.334 0 504 225.666 504 504s-225.666 504-504 504S8 790.334 8 512 233.666 8 512 8z m0 168.00000033a42.00000033 42.00000033 0 0 0-42.00000033 41.99999934v252H217.99999967a42.00000033 42.00000033 0 0 0-41.706 37.08600033L176.00000033 512a42.00000033 42.00000033 0 0 0 41.99999934 42.00000033h252v252a42.00000033 42.00000033 0 0 0 37.08600033 41.706L512 847.99999967a42.00000033 42.00000033 0 0 0 42.00000033-41.99999934l-0.04200033-252H806.00000033a42.00000033 42.00000033 0 0 0 41.706-37.08600033L847.99999967 512a42.00000033 42.00000033 0 0 0-41.99999934-42.00000033h-252V217.99999967a42.00000033 42.00000033 0 0 0-37.08600033-41.706z"></path>
      </svg>
    </BaseIcon>
  )
}
