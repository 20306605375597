import React from 'react'

import Input from '../Input'

import { isEmpty } from '../../utils'

const getReg = (precision?: number) => {
  if (precision === 0) {
    return /^-?\d*$/
  }
  return /^-?[\d\\.]*$/
}

interface Props {
  value?: React.ReactText
  onChange?: (value: React.ReactText) => void
  precision?: number
  width?: number
  placeholder?: string
  min?: number
  max?: number

  [name: string]: any
}

const InputNumber = React.forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, precision, width = 171, placeholder, min, max, ...inputProps }, ref) => {
    const [innerValue, setInnerValue] = React.useState<React.ReactText>('')

    const reg = getReg(precision)

    React.useEffect(() => {
      if (isEmpty(value)) {
        setInnerValue('')
      } else {
        setInnerValue(value as React.ReactText)
      }
    }, [value])

    const changeValue = (num: React.ReactText) => {
      if (typeof onChange === 'function') {
        onChange(num)
      }
      setInnerValue(num)
    }

    const onChangeInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = target.value
      // 非正负、小数
      if (!reg.test(inputValue)) {
        return
      }
      // 小数点
      const decimalArr = inputValue.match(/\./g)
      // 小数点数量多
      if (decimalArr && decimalArr.length > 1) {
        return
      }
      // 小数点后位数大于precision
      if (precision && precision > 0) {
        const arr = inputValue.split('.')
        if (arr[1] && arr[1].length > precision) {
          return
        }
      }
      if (
        !isEmpty(inputValue) &&
        ((min && Number(inputValue) < min) || (max && Number(inputValue) > max))
      ) {
        return
      }
      changeValue(inputValue)
    }

    function onBlurInput({ target }: React.FocusEvent<HTMLInputElement>) {
      const errorList = ['-', '.', '-.']
      if (!isEmpty(target.value) && errorList.includes(target.value)) {
        changeValue(0)
      }
    }

    return (
      <Input
        ref={ref}
        value={innerValue}
        onChange={onChangeInput}
        style={{ width }}
        placeholder={placeholder}
        onBlur={onBlurInput}
        {...inputProps}
      />
    )
  }
)

export default InputNumber
