import BaseIcon, { BaseIconProps } from '../BaseIcon'

export default function IconAuditedActive(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100">
        <defs>
          <linearGradient id="a-audited" x1="1.959%" y1="0%" y2="92.675%">
            <stop offset="0%" stopColor="#F7CA0C" />
            <stop offset="100%" stopColor="#F7BB0C" />
          </linearGradient>
        </defs>
        <circle cx="62.498" cy="60.579" r="34.547" fill="url(#a-audited)" />
        <path d="M19.859 25.665h45.199v-5.666h-45.2v5.666zm0 5.666h45.199v5.666H19.723l.136-5.666zm0 11.287h28.927v5.712H19.723l.136-5.712zm0 11.288h11.978v5.62H19.723l.136-5.62zM76.086 3H8.605C5.509 3 3 5.517 3 8.621v78.965c0 3.104 2.51 5.62 5.605 5.62h26.35V87.54H8.606V8.621h67.481v36.264h5.605V8.62c0-1.49-.59-2.92-1.642-3.975A5.597 5.597 0 0 0 76.086 3z" />
        <path d="M82.276 57.455a3.343 3.343 0 0 0-4.745.628l-18.71 24.809-11.84-10.498a3.35 3.35 0 0 0-4.766.337 3.446 3.446 0 0 0 .332 4.823l14.544 12.875a3.344 3.344 0 0 0 4.922-.493L82.94 62.21a3.437 3.437 0 0 0-.665-4.755z" />
      </svg>
    </BaseIcon>
  )
}
