// lib
import React from 'react'
import classNames from 'classnames'

// components

// styles
import styles from './ModalContent.module.scss'

export type ContentType = React.PropsWithChildren<{
  maskClosable?: boolean
  onClickMask?: () => void
  width?: number
  className?: string
  contentClassName?: string
}>

export default function ModalContent({
  children,
  onClickMask,
  maskClosable,
  width = 416,
  className,
  contentClassName,
}: ContentType) {
  return (
    <div className={styles['modal-root']}>
      <div className={styles['modal-mask']} />
      <div
        className={styles['modal-wrap']}
        onClick={maskClosable && typeof onClickMask === 'function' ? onClickMask : undefined}
      >
        <div
          className={classNames(styles.modal, className, styles['move-appear'])}
          style={{ width }}
          onClick={e => e.stopPropagation()}
        >
          <div className={classNames(styles['modal-content'], 'modal-content', contentClassName)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
