import React from 'react'
import { LazyHomeLayout } from '../$$home/layout'

import useLayoutState, { LayoutEnums } from './useLayoutState'
import { EnvContext } from '../context'
import { adminLogin } from '../api/admin'
import AdminLayout from './AdminLayout'
import AuthorLayout from './AuthorLayout'
import useMediaQuery from '../hooks/useMediaQuery'
import { useHistory } from 'react-router-dom'

const { REACT_APP_PROJECT_NAME } = process.env

export default function Layout({ children }: React.PropsWithChildren<any>) {
  const layout = useLayoutState()
  const history = useHistory()

  const isMobile = useMediaQuery('(max-width: 767px)')

  React.useEffect(() => {
    // 页面切换回到首页
    history.listen(() => {
      window.scroll(0, 0)
    })
  }, [history])

  React.useEffect(() => {
    ;(window as any).adminLogin = adminLogin
  }, [])

  function renderChildren() {
    switch (layout) {
      case LayoutEnums.CUSTOMER:
        return <AuthorLayout>{children}</AuthorLayout>
      case LayoutEnums.ADMIN:
        return <AdminLayout>{children}</AdminLayout>
      default:
        return <LazyHomeLayout>{children}</LazyHomeLayout>
    }
  }

  return (
    <EnvContext.Provider value={{ projectName: REACT_APP_PROJECT_NAME as string, isMobile }}>
      {renderChildren()}
    </EnvContext.Provider>
  )
}
