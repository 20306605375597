import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import styles from './AdminMenu.module.scss'
import Portal, { PortalHolder } from '../../../components/Portal'
import {
  IconAudited,
  IconAuditedActive,
  IconAuditing,
  IconAuditingActive,
  IconMessage,
  IconMessageActive,
  IconSetting,
  IconSettingActive,
  IconStat,
  IconStatActive,
} from '../../../components/Icons'
import { useAdminEnvContext } from '../../../context'
import useAdminPageWithRole from '../../../hooks/useAdminPageWithRole'
import Prefix from '../../../constants/prefix'
import { Dropdown } from '../../../components'

export default function AdminMenu() {
  const { unReadCount } = useAdminEnvContext()
  const PageRole = useAdminPageWithRole()
  const location = useLocation()

  const Menu = [
    {
      path: `${Prefix.adminPrefix}`,
      name: '待审核',
      icon: IconAuditing,
      activeIcon: IconAuditingActive,
      filter: () => PageRole.UNDO,
      active() {
        const list = [this.path, `${Prefix.adminPrefix}/undo`]
        return list.findIndex(path => path === location.pathname) > -1
      },
    },
    {
      path: `${Prefix.adminPrefix}/done`,
      name: '已审核',
      icon: IconAudited,
      activeIcon: IconAuditedActive,
      filter: () => PageRole.DONE,
      active() {
        const list = [this.path]
        return list.findIndex(path => path === location.pathname) > -1
      },
    },
    {
      path: `${Prefix.adminPrefix}/platform/scripts`,
      name: '项目列表',
      icon: IconAuditing,
      activeIcon: IconAuditingActive,
      filter: () => PageRole.PLATFORM_SCRIPTS,
      active() {
        const list = [this.path]
        return list.findIndex(path => path === location.pathname) > -1
      },
    },
    {
      path: `${Prefix.adminPrefix}/report`,
      name: '项目统计',
      icon: IconStat,
      activeIcon: IconStatActive,
      filter: () => PageRole.REPORT,
      active() {
        const list = [this.path]
        return list.findIndex(path => path === location.pathname) > -1
      },
    },
    {
      name: '青春中国梦',
      icon: IconStat,
      activeIcon: IconStatActive,
      filter: () => PageRole.TOPIC_REPORT,
      active() {
        const list = [`${Prefix.adminTopicPrefix}/report`, `${Prefix.adminTopic2023Prefix}/report`]
        return list.findIndex(path => path === location.pathname) > -1
      },
      children: [
        {
          name: '2022',
          path: `${Prefix.adminTopicPrefix}/report`,
          filter: () => PageRole.TOPIC_REPORT,
        },
        {
          name: '2023',
          path: `${Prefix.adminTopic2023Prefix}/report`,
          filter: () => PageRole.TOPIC_2023_REPORT,
        },
      ].filter(item => !item.filter || item.filter()),
    },
    {
      path: `${Prefix.adminPrefix}/setting`,
      name: '设置',
      icon: IconSetting,
      activeIcon: IconSettingActive,
      filter: () => PageRole.SETTING,
      active() {
        const list = [this.path]
        return list.findIndex(path => path === location.pathname) > -1
      },
    },
    {
      path: `${Prefix.adminPrefix}/message`,
      name: '消息',
      icon: IconMessage,
      activeIcon: IconMessageActive,
      extra: unReadCount > 0 ? <span className={styles.dot} /> : null,
      active() {
        const list = [this.path]
        return list.findIndex(path => path === location.pathname) > -1
      },
    },
  ].filter(item => !item.filter || item.filter())

  return (
    <Portal holder={<PortalHolder height={58} />}>
      <footer className={styles.menu}>
        <nav className={styles.nav}>
          {Menu.map(item => {
            const active = item.active?.()
            if (item.children?.length) {
              return (
                <Dropdown
                  trigger={['click']}
                  placement="topCenter"
                  overlay={
                    <div className="py-1 divide-y">
                      {item.children.map(child => (
                        <Link
                          key={child.name}
                          to={child.path}
                          className="block text-center px-8 py-1"
                        >
                          {child.name}
                        </Link>
                      ))}
                    </div>
                  }
                >
                  <div className={styles.link} key={item.path}>
                    <span className={styles['icon-box']}>
                      {active
                        ? React.createElement(item.activeIcon)
                        : React.createElement(item.icon)}
                      {item.extra || null}
                    </span>
                    <span>{item.name}</span>
                  </div>
                </Dropdown>
              )
            }
            return (
              <Link className={styles.link} to={item.path} key={item.path}>
                <span className={styles['icon-box']}>
                  {active ? React.createElement(item.activeIcon) : React.createElement(item.icon)}
                  {item.extra || null}
                </span>
                <span>{item.name}</span>
              </Link>
            )
          })}
        </nav>
      </footer>
    </Portal>
  )
}
