// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconTrophy.module.less'

export default function IconTrophy(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M514.52704737 606.22940198c-191.0914929 0-346.53543689-155.44394394-346.53543822-346.53543691V100.97384788a29.69467278 29.69467278 0 0 1 29.69467279-29.69467282H831.35386985a29.69467278 29.69467278 0 0 1 29.69467276 29.69467282V259.69396507c0 191.07755132-155.44394394 346.53543689-346.53543684 346.53543691zM227.39489635 130.66852065v129.02544442c0 158.3297643 128.80238544 287.14609131 287.14609128 287.14609133s287.14609131-128.816327 287.14609263-287.14609133v-129.02544442H227.39489635z"></path>
        <path d="M776.91363685 467.02691095a29.69467278 29.69467278 0 0 1 0-59.38934559c69.95674514 0 140.94513702-36.30278306 169.41298722-86.4351987 15.94868855-35.61966577 9.14540113-67.80980721-2.63487943-86.17031482-9.13146088-14.24786603-22.2082694-22.43132839-35.91243012-22.43132838h-76.23026753a29.69467278 29.69467278 0 0 1 0-59.38934555h76.23026753c34.32313781 0 65.63498611 18.13744996 85.91937525 49.76994453 25.97238281 40.52695483 28.32843942 95.70607024 6.13411042 144.04007474a29.03943866 29.03943866 0 0 1-1.0037636 2.02146878c-38.53336925 69.45486335-129.77826711 117.98404497-221.91539974 117.98404499z m198.94036607-128.88603373h1.25470449-1.25470449z m-729.70824471 129.05332764c-90.89636974 0-178.30744878-47.48359459-217.48211054-118.17922211a48.30612266 48.30612266 0 0 1-1.03164679-2.02146745c-22.19432777-48.32006421-19.83827244-103.49917832 6.13411169-144.04007477 20.29833076-31.61855302 51.61017777-49.76994454 85.93331553-49.76994453h76.23026759a29.69467278 29.69467278 0 0 1 0 59.38934557h-76.23026759c-13.69022049 0-26.78097057 8.18346107-35.91243011 22.44526867-11.79422216 18.34656732-18.59750827 50.56459071-2.6348794 86.15637459 28.90002662 51.02464902 96.54253991 86.6024913 164.99363962 86.60249127a29.69467278 29.69467278 0 0 1 0 59.41722876z m495.71980004 479.58987727H287.20247672a29.69467278 29.69467278 0 0 1-29.69467281-29.69467279v-53.95229283c0-97.76936119 79.52038241-177.28974359 177.28974363-177.28974353h159.4868815c97.7554209 0 177.28974359 79.53432267 177.2897436 177.27580196v53.9662344c-0.01394162 16.39480524-13.31380912 29.69467278-29.70861439 29.69467279z m-424.96840873-59.38934558H712.15694386v-24.28550322c0-65.00763385-52.89276412-117.88645641-117.90039802-117.88645641H434.79754754c-65.00763385 0-117.88645641 52.87882383-117.88645642 117.88645641v24.27156164h-0.0139416z"></path>
        <path d="M514.52704737 745.23671851a29.69467278 29.69467278 0 0 1-29.69467282-29.6946728v-139.0073165a29.69467278 29.69467278 0 0 1 59.38934559 0v139.0073165c0 16.40874686-13.28592593 29.69467278-29.69467277 29.6946728z"></path>
      </svg>
    </BaseIcon>
  )
}
