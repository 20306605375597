import Prefix from '../constants/prefix'
import AsyncComponent from './AsyncComponent'

const adminRoutes = [
  {
    path: '/',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: "admin-home" */ '../$$admin/Home')),
  },
  // java发送转办消息会指向/admin/m
  {
    path: '/m',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: "admin-home" */ '../$$admin/Home')),
  },
  {
    path: '/undo',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: "admin-undo" */ '../$$admin/Undo')),
  },
  {
    path: '/vote/:voteId(\\d+)',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: "admin-vote" */ '../$$admin/Vote')),
  },
  {
    path: '/script/:scriptId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-script" */ '../$$admin/Script')
    ),
  },
  {
    path: '/vote/result/:voteId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-vote-result" */ '../$$admin/Vote')
    ),
  },
  {
    path: '/done',
    exact: true,
    component: AsyncComponent(() => import(/* webpackChunkName: "admin-done" */ '../$$admin/Done')),
  },
  {
    path: '/report',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-report" */ '../$$admin/Report')
    ),
  },
  {
    path: '/recommend/report',
    exact: true,
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "admin-recommend-report" */ '../$$admin/other-report/RecommendReport'
        )
    ),
  },
  {
    path: '/task/report',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-task-report" */ '../$$admin/other-report/TaskReport')
    ),
  },
  {
    path: '/time/report',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-time-report" */ '../$$admin/other-report/TimeReport')
    ),
  },
  {
    path: '/audit/report',
    exact: true,
    component: AsyncComponent(
      () =>
        import(/* webpackChunkName: "admin-audit-report" */ '../$$admin/other-report/AuditReport')
    ),
  },
  {
    path: '/setting',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-setting" */ '../$$admin/Setting')
    ),
  },
  {
    path: '/agreement',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-agreement" */ '../$$admin/Agreement')
    ),
  },
  {
    path: '/authors',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-authors" */ '../$$admin/Authors')
    ),
  },
  {
    path: '/author/:authorId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-author" */ '../$$admin/Author')
    ),
  },
  {
    path: '/message',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-message" */ '../$$admin/Message')
    ),
  },
  {
    path: '/script/list/step',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-script-list-step" */ '../$$admin/ScriptListStep')
    ),
  },
  {
    path: '/platform/scripts',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-platform-scripts" */ '../$$admin/platform/Scripts')
    ),
  },
  {
    path: '/platform/script/:scriptId(\\d+)',
    exact: true,
    component: AsyncComponent(
      () => import(/* webpackChunkName: "admin-platform-script" */ '../$$admin/platform/Script')
    ),
  },
]

export default [
  ...adminRoutes.map(item => ({
    ...item,
    path: `${Prefix.adminPrefix}${item.path}`,
  })),
]
