import React from 'react'

interface Props {
  active: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean>>
}

const OverlayContext = React.createContext<Props>({
  active: false,
  setActive: () => {},
})

export default OverlayContext

export function useOverlayContext() {
  return React.useContext(OverlayContext)
}
