import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'

import { uuidv4 } from '..'
import { MessageType, DURATION, MESSAGE_TYPE_CLASSNAME, MESSAGE_TYPE_ICON } from './constants'

import styles from './Message.module.scss'

interface OptionProps {
  className?: string
  content: React.ReactNode
  icon?: React.ReactNode
  duration?: number
  onClose?: () => void
}

type MessageProps = { option: OptionProps; type: MessageType }

function Message({ option, type, onClose }: MessageProps & { onClose(): void }) {
  const thisRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setTimeout(() => {
      const notice = thisRef.current
      notice?.classList.add(styles['move-leave'])
    }, option.duration)
  }, [option.duration])

  React.useEffect(() => {
    const notice = thisRef.current
    notice?.addEventListener('animationend', () => {
      if (notice.classList.contains(styles['move-appear'])) {
        notice.classList.remove(styles['move-appear'])
      } else if (notice.classList.contains(styles['move-leave'])) {
        notice.classList.remove(styles['move-leave'])
        onClose()
        if (typeof option.onClose === 'function') {
          option.onClose()
        }
      }
    })
  }, [onClose, option, option.onClose])

  return (
    <div className={classNames(styles['message-notice'], styles['move-appear'])} ref={thisRef}>
      <div
        className={classNames(
          styles['message-notice-content'],
          MESSAGE_TYPE_CLASSNAME[type],
          option.className
        )}
      >
        {option.icon
          ? option.icon
          : React.createElement(MESSAGE_TYPE_ICON[type], {
              className: classNames(styles.icon),
            })}
        <span className={styles.content}>{option.content}</span>
      </div>
    </div>
  )
}

type MessageListItem = MessageProps & { id: string }

const message = (() => {
  const container = document.createElement('div')
  let queue: MessageListItem[] = []

  function renderMessage() {
    ReactDOM.render(
      <>
        {queue.map(message => (
          <Message
            {...message}
            key={message.id}
            onClose={() => {
              queue = queue.filter(item => item.id !== message.id)
              renderMessage()
            }}
          />
        ))}
      </>,
      container
    )
  }

  function appendMessage(option: OptionProps, type: MessageType) {
    queue.push({ option, type, id: uuidv4() })

    if (!document.body.contains(container)) {
      container.className = styles.container
      document.body.appendChild(container)
    }

    renderMessage()
  }

  const toast =
    (type: MessageType) =>
    (content: string, duration: number = DURATION, onClose?: () => void) => {
      appendMessage({ content, duration, onClose }, type)
    }

  return {
    success: toast(MessageType.Success),
    warn: toast(MessageType.Warn),
    error: toast(MessageType.Error),
  }
})()

export default message
