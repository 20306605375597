// lib
import React from 'react'
import { AdminEnvContext, useAdminEnvContext, useEnvContext } from '../../context'
import useCurrentAdmin from '../../hooks/useCurrentAdmin'
import useUnReadCount from '../../hooks/useUnReadCount'
import AdminMenu from './AdminMenu'
import Container from '../Container'
import Header from './Header'
import NDAModal from '../../business/NDAModal'
import { YesOrNo } from '../../constants'
import { Empty } from '../../components'
import { getUserSignStatus } from '../../api/admin'
import useAdminRole from '../../hooks/useAdminRole'

// components

// styles
// import styles from './AdminLayout.module.scss'

interface Props {
  children: React.ReactNode
}

function App({ children }: { children: React.ReactNode }) {
  const { isMobile } = useEnvContext()
  const { currentUser, updateCurrentUser } = useAdminEnvContext()
  const [showNDAModal, setShowNDAModal] = React.useState(false)
  const {
    isRoundAVoter,
    // isPartner
  } = useAdminRole()

  React.useEffect(() => {
    if (
      currentUser.id &&
      // (isRoundAVoter || isPartner) &&
      isRoundAVoter &&
      (!currentUser.flowId || currentUser.flowStatus !== YesOrNo.Yes)
    ) {
      setShowNDAModal(true)
      if (currentUser.flowId) {
        const getStatus = async () => {
          const resp = await getUserSignStatus(currentUser.id)
          const { data } = resp.data
          if (data) {
            setShowNDAModal(false)
            updateCurrentUser(p => ({ ...p, flowStatus: YesOrNo.Yes }))
          }
        }
        getStatus()
      }
    }
  }, [
    currentUser.flowId,
    currentUser.flowStatus,
    currentUser.id,
    // isPartner,
    isRoundAVoter,
    updateCurrentUser,
  ])

  function onSuccess() {
    setShowNDAModal(false)
  }
  return (
    <>
      <>
        {isMobile ? null : <Header />}
        {showNDAModal ? <Empty /> : <>{isMobile ? children : <Container>{children}</Container>}</>}
        {isMobile ? <AdminMenu /> : null}
      </>
      <NDAModal visible={showNDAModal} onSuccess={onSuccess} />
    </>
  )
}

export default function AdminLayout({ children }: Props) {
  const [currentUser, updateCurrentUser, currentRoles, roleLoaded] = useCurrentAdmin()
  const { unReadCount, setUnReadCount, updateUnReadCount } = useUnReadCount()

  return (
    <AdminEnvContext.Provider
      value={{
        currentRoles,
        currentUser,
        updateCurrentUser,
        unReadCount,
        setUnReadCount,
        updateUnReadCount,
        roleLoaded,
      }}
    >
      <App>{children}</App>
    </AdminEnvContext.Provider>
  )
}
