import React from 'react'

import { XMLConfig } from '../api'
import { goToLogin } from '../utils'
import Code from '../constants/code'

function useCurrentAuthor(): [AuthorVO, React.Dispatch<React.SetStateAction<AuthorVO>>] {
  // eslint-disable-next-line no-underscore-dangle
  const data = (window as any).__INITIAL_MODEL_DATA || {}
  const [user, setUser] = React.useState<AuthorVO>(data.user)

  React.useEffect(() => {
    const { pathname } = window.location
    const userApi = '/api/api-author/curAuthor'

    async function getCurrentUser() {
      try {
        const userResp = await window.fetch(userApi, XMLConfig).then(resp => resp.json())
        if (userResp.code === Code.SERVER_UNAUTHORIZED) {
          throw new Error('请登录')
        }
        setUser(userResp.data)
      } catch (e) {
        goToLogin()
      }
    }

    const isLoginPage = /\/login/.test(pathname)

    if (process.env.NODE_ENV === 'development' && !isLoginPage && !user) {
      getCurrentUser()
    }
  }, [user])

  return [user || {}, setUser]
}

export default useCurrentAuthor
