import request from '.'

/** 获取图形验证码 */
export function getSliderCaptcha() {
  return request.get<R<IAUTHOR.CaptchaToken>>('/api-author/captcha/get')
}

/** 投稿人注册 */
export function authorRegister(data: IAUTHOR.AuthorRegisterParams) {
  return request.post<R<AuthorVO>>('/api-author/register', data)
}

/** 投稿人登录 */
export function authorLogin(data: IAUTHOR.AuthorLoginParams) {
  return request.post<R<AuthorVO>>('/api-author/login', data)
}

/** 投稿人找回密码 */
export function authorForget(data: IAUTHOR.AuthorForgetPwdParams) {
  return request.post<R<string>>('/api-author/pwd/forget', data)
}

/** 投稿人退出登录 */
export function authorLogout() {
  return request.get<R<boolean>>('/api-author/logout')
}

export function getAuthorDetail() {
  return request.get<R<AuthorDetailVO>>('/api-author/get')
}

export function getCurrentAuthor() {
  return request.get<R<AuthorVO>>('/api-author/curAuthor')
}

type AuthorData = {
  name: string
  email: string
  realName: string
  idCard: string
  resume: string
}

/** 修改投稿人资料 */
export function modifyAuthorData(data: AuthorData) {
  return request.post<R<number>>('/api-author/modify', data)
}

interface ModifyPwdParams {
  oldPwd: string
  pwd: string
  confirmPwd: string
}

/** 修改投稿人密码 */
export function modifyAuthorPassword(data: ModifyPwdParams) {
  return request.post<R<string>>('/api-author/pwd/modify', data)
}

/** 获取剧本类型列表 */
export function getScriptTypeList() {
  return request.get<R<ICommon.ScriptType[]>>('/api-author/script/type/list')
}

/** 获取剧本题材类型 */
export function getScriptTopicList() {
  return request.get<R<ICommon.Topic[]>>('/api-author/script/topic/list')
}

/** 投稿人投稿 */
export function saveScript(data: IAUTHOR.ScriptCreateParams) {
  return request.post<R<ICommon.ScriptDetailVO>>('/api-author/script/save', data)
}

export function submitScript(scriptId: number) {
  return request.post<R<number>>('/api-author/script/submit', { id: scriptId })
}

export function getMyScripts() {
  return request.get<R<ICommon.ScriptBaseVO[]>>('/api-author/script/list')
}

export function getMyScriptDetail(scriptId: number) {
  return request.get<R<ICommon.ScriptDetailVO>>(`/api-author/script/detail?id=${scriptId}`)
}

export function getAgreement(type: number) {
  return request.get<R<AgreementVO>>(`/api-author/agreement/get?type=${type}`)
}

export function submitAgree() {
  return request.post<R<string>>('/api-author/agree')
}

export function getSignStatusByScriptId(scriptId: number) {
  return request.get<R<boolean>>(`/api-author/script/sign/status?id=${scriptId}`)
}

export function getSignUrlByScriptId(scriptId: number) {
  return request.get<R<string>>(`/api-author/script/sign/url?id=${scriptId}`)
}

export function authorSign(scriptId: number) {
  return request.post<string>('/api-author/script/sign', { id: scriptId })
}

export function getSignDocByScriptId(scriptId: number) {
  return request.get<R<string>>(`/api-author/script/sign/doc?id=${scriptId}`)
}

export function getSettingSubmitSwitch() {
  return request.get<R<IAUTHOR.AuthorSubmitVO>>('/api-author/submit/switch')
}
