// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconDustbin.module.less'

export default function IconDustbin(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M256.76997446 169.81222552v-100.66895232c0-31.79461026 25.75447377-57.54908404 57.54908405-57.54908404h412.07157832c31.71071971 0 57.46519349 25.75447377 57.46519348 57.54908404v100.66895232h188.41872195a44.96546566 44.96546566 0 1 1 0 89.93093133h-46.89495359v689.0789775a57.54908404 57.54908404 0 0 1-57.54908403 57.54908403H154.67487858a57.54908404 57.54908404 0 0 1-57.54908404-57.54908403V259.74315685H52.49589214a44.96546566 44.96546566 0 1 1 0-89.93093133h204.27408232z m89.93093026 0h347.30788588V101.52511944h-347.30788588v68.28710608z m488.83165424 89.93093133H186.97283425v656.69713128h648.47583416V259.74315685h0.08389055z m-527.16974639 140.93653239h89.84703971v396.21621794H308.36281257v-396.30010848 0.08389054z m154.86240486 4.78177508h89.84703971v391.43444286h-89.84703971V405.46146432z m154.86240487 4.86566667h89.84703971v386.56877619H618.17151284v-386.56877619h-0.08389054z"></path>
      </svg>
    </BaseIcon>
  )
}
