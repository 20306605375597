// lib
import React from 'react'
import classnames from 'classnames'

// components

// styles
import styles from './BaseIcon.module.scss'

export type BaseIconProps = React.PropsWithChildren<{
  className?: string
  defaultFill?: boolean
}> &
  React.HTMLAttributes<HTMLSpanElement>

export default function BaseIcon({
  children,
  className,
  defaultFill = true,
  ...restProps
}: BaseIconProps) {
  return (
    <span
      className={classnames('icon', styles.icon, className, {
        [styles['default-fill']]: defaultFill,
      })}
      {...restProps}
    >
      {children}
    </span>
  )
}
