// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconStar.module.less'

export default function IconStar(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M337.99705893 686.00294107L8.77812571 500.81729134l321.34630586-170.69285977L500.81729134 8.77812571l185.18564973 329.21893322 329.21893322 185.18564973-321.34630586 170.69285977-170.69285977 321.34630586z"></path>
      </svg>
    </BaseIcon>
  )
}
