// lib
import React from 'react'

// components
import BaseIcon, { BaseIconProps } from '../BaseIcon'

// styles
// import styles from './IconArrowBottom.module.less'

export default function IconArrowBottom(props: BaseIconProps) {
  return (
    <BaseIcon {...props}>
      <svg viewBox="0 0 1024 1024">
        <path d="M20.15552197 346.29365552l422.46464626 498.28917191a90.94811865 90.94811865 0 0 0 138.75966354 0L1003.84447803 346.29365552a57.16706985 57.16706985 0 0 0-43.60217276-94.14366855h-896.48461054a57.16706985 57.16706985 0 0 0-43.60217276 94.14366855z"></path>
      </svg>
    </BaseIcon>
  )
}
